import { api } from "@services/api";

interface ResponseGetTesteAnalise {
  id: number;
  nome: string;
}

export const getTestesAnalise = async () => {
  const { data } = await api.get<ResponseGetTesteAnalise[]>("/testes-analise");
  return data;
};
