import { IEstabelecimento } from "@interfaces/IEstabelecimento";
import { api } from "@services/api";

export const getEstabelecimentos = {
  byCnpj: async (cnpj: string) => {
    const params = new URLSearchParams();
    params.append("cnpj", cnpj);

    const { data } = await api.get<IEstabelecimento[]>(
      `/estabelecimentos?${params}`
    );

    return data;
  },
  byMunicipio: async (idMunicipio: number) => {
    const params = new URLSearchParams();
    params.append("municipioId", `${idMunicipio}`);
    const { data } = await api.get<IEstabelecimento[]>(
      `estabelecimentos?${params}`
    );

    return data;
  },
};
