const cpfCnpjMask = (v: string, onlyFormat?: "cpf" | "cnpj") => {
  v = v.replace(/\D/g, "");

  if (onlyFormat === "cpf") {
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return v;
  }

  if (onlyFormat === "cnpj") {
    v = v.replace(/^(\d{2})(\d)/, "$1.$2");
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
    return v;
  }

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    v = v.replace(/^(\d{2})(\d)/, "$1.$2");
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return v;
};

const renasemMask = (value: string) => {
  value = value.replace(/[^a-zA-Z0-9]/g, "");

  if (parseInt(value[0]) >= 0 || parseInt(value[1]) >= 0) {
    return "";
  }

  //VERIFICANDO SE FOI DIGITADO LETRA NOS ULTIMOS CARACTERES
  if (value.length > 2) {
    if (!(parseInt(value[value.length - 1]) >= 0)) {
      return value.slice(0, value.length - 1);
    }

    //VERIFICANDO SE FOI DIGITADO NUMERO NOS DOIS PRIMEIROS CARACTERES
  } else {
    if (parseInt(value[value.length - 1]) >= 0) {
      return value.slice(0, value.length - 1);
    }
  }

  value = value.replace(/(\w{2})(\d)/, "$1-$2");
  value = value.replace(/(\d{5})(\d)/, "$1/$2");

  return value;
};

const cepMask = (value: string) => {
  let v = value.replace(/\D/g, "");
  v = v.replace(/^(\d{5})(\d)/, "$1-$2");

  return v;
};

const telefoneMask = (value: string) => {
  if (!value) return "";
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})(\d)/, "($1) $2");
  value = value.replace(/(\d)(\d{4})$/, "$1-$2");
  return value;
};

const rgMask = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{1,2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");

  return value;
};

const coordMask = (value: string) => {
  if (!value) return "";
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})(\d)/, "$1,$2");
  return value;
};

const mesAnoMesk = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})(\d)/, "$1/$2");
  return value;
};

const semDigitoMask = (value: string) => {
  value = value.replace(/[0-9]/g, "");
  return value;
};

const tifMask = (value: string) => {
  value = value.replace(/[^a-zA-Z0-9/]/g, "");
  value = value.toUpperCase();

  if (value.length === 6) {
    value = value + "/";
  }

  if (value.length === 10) {
    value = value + "/";
  }

  return value;
};

export const masks = {
  cpfCnpjMask,
  tifMask,
  cepMask,
  coordMask,
  semDigitoMask,
  renasemMask,
  rgMask,
  mesAnoMesk,
  telefoneMask,
};
