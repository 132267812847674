import { IServidor } from "@interfaces/IServidor";
import { api } from "@services/api";

export const getServidores = {
  todos: async () => {
    const { data } = await api.get<IServidor[]>(`servidores`);
    return data;
  },

  byMatricula: async (matricula: string) => {
    const params = new URLSearchParams();
    params.append("matricula", matricula);

    const { data } = await api.get<IServidor>(
      `/servidores?${params.toString()}`
    );
    return data;
  },
};
