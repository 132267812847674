import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MenuOutlined,
  MinusOutlined,
  PaperClipOutlined,
  PrinterOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { ModalCancelarTermo } from "@components/ModalCancelarTermo";
import { ModalVisualizarAnexos } from "@components/ModalVisualizarAnexos";
import { openNotification } from "@components/Notifications";
import { converterBase64ToFile } from "@functions/converterBase64ToFile";
import { usePermission } from "@hooks/usePermission";
import { ITermoApreensaoLiberacao } from "@interfaces/ITermoApreensaoLiberacao";
import { apiService } from "@services/api";
import { Button, Dropdown, List, Menu, Modal, Tooltip } from "antd";
import { AxiosError } from "axios";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { converterJsonToFormDataTermoApreensaoLiberacao } from "../../../functions/converterJsonToFormDataTermoApreensaoLiberacao";
import { useActionsTAL } from "../../../hooks/useActionsTAL";
import styles from "../../style.module.css";

const CODIGO_TERMO = "TermoApreensaoLiberacao";

export function ItemListaTermoApreensaoLiberacao({
  termo,
  pageControl,
}: {
  termo: ITermoApreensaoLiberacao;
  pageControl: number;
}) {
  const tipoTermoFormatado =
    termo.tipoTermo === "APREENSAO" ? "Apreensão" : "Liberação";
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isOpenModalAnexos, setIsOpenModalAnexos] = useState(false);
  const { isAllowed } = usePermission();

  const { atualizarTermo, removerTermo, listaTermos } = useActionsTAL();

  // ENVIAR TERMO CASO NAO POSSUA ID
  const handlePostTermo = async () => {
    setIsLoading(true);

    const anexos = termo.anexos.map((anexo) => {
      return converterBase64ToFile(anexo.arquivo, anexo.nomeArquivo);
    });

    let termoSubmit = termo;

    if (termo.tif && !termo.tif?.id) {
      const params = new URLSearchParams();
      params.append("numero", termo.tif.numero);

      try {
        const { content } = await apiService.tif.listar(params.toString());

        if (content.length > 0) {
          termoSubmit = {
            ...termoSubmit,
            tif: { ...termo.tif, id: content[0].id },
          };
        } else {
          openNotification({
            type: "info",
            message: `Não foi possivel enviar Termo de ${tipoTermoFormatado}.`,
            description:
              "Favor sincronizar o TIF antes de salvar esse termo. Caso já tenha sincronizado, procure o administrador do sistema.",
          });
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);

        if (error instanceof AxiosError) {
          openNotification({
            type: "error",
            message: "Não foi possivel salvar o termo.",
            description: `Erro ${error.response?.status}: ${error.response?.data.title}.`,
          });
          return;
        }

        openNotification({
          type: "error",
          message: "Não foi possivel salvar o termo.",
          description: `Erro desconhecido.`,
        });
      }
    }

    if (termoSubmit.tif && !termoSubmit.tif.id) {
      setIsLoading(false);
      return;
    }

    if (termo.termoApreensao && !termo.termoApreensao?.id) {
      const params = new URLSearchParams();
      params.append("numero", termo.termoApreensao.numero);
      try {
        const { content } = await apiService.termoApreensaoLiberacao.listar(
          params.toString()
        );
        if (content.length > 0) {
          termoSubmit = {
            ...termoSubmit,
            termoApreensao: {
              ...termo.termoApreensao,
              id: content[0].id,
            },
          };
        } else {
          openNotification({
            type: "info",
            message: `Não foi possivel enviar Termo de ${tipoTermoFormatado}.`,
            description:
              "Favor sincronizar o Termo de Apreensão antes de salvar esse termo. Caso já tenha sincronizado, procure o administrador do sistema.",
          });
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);

        if (error instanceof AxiosError) {
          openNotification({
            type: "error",
            message: "Não foi possivel salvar o termo.",
            description: `Erro ${error.response?.status}: ${error.response?.data.title}.`,
          });
          return;
        }

        openNotification({
          type: "error",
          message: "Não foi possivel salvar o termo.",
          description: `Erro desconhecido.`,
        });
      }
    }

    if (termoSubmit.termoApreensao && !termoSubmit.termoApreensao.id) {
      setIsLoading(false);
      return;
    }

    await apiService.termoApreensaoLiberacao
      .enviar(
        converterJsonToFormDataTermoApreensaoLiberacao(termoSubmit, anexos)
      )
      .then((res) => {
        atualizarTermo({ ...res.data, anexos: termoSubmit.anexos });
        openNotification({
          type: "success",
          message: "Termo salvo com sucesso!",
          description: "Termo salvo na base de dados.",
        });
      })
      .catch((erro) => {
        console.log(erro);

        openNotification({
          type: "error",
          message: "Não foi possivel salvar o termo.",
          description: `Erro: ${erro.response.data.detail}`,
        });
      });
    setIsLoading(false);
  };

  // VISUALIZAR TERMO
  const handleViewTermo = () => {
    if (!termo.id) return;
    navigate(`/termo-apreensao-liberacao/listagem/visualizar/${termo.id}`);
    localStorage.setItem(
      "termoApreensaoLiberacao:pageSelect",
      pageControl.toString()
    );
  };

  //CANCELAR TERMO
  const handleCancelTermo = async () => {
    if (!termo.id) return;
    setIsCancel(true);
  };

  //REMOVER TERMO DO BASE DE DADOS OFFLINE
  const handleRemoveTermoInApp = () => {
    if (!termo.id) return;

    const termoApreensaoLiberacao = listaTermos.find(
      (auxTermo) => auxTermo.id === termo.id
    );

    if (!termoApreensaoLiberacao) return;

    removerTermo(termoApreensaoLiberacao.numero);
    openNotification({
      type: "success",
      message: `Termo de ${tipoTermoFormatado} ${termoApreensaoLiberacao.numero} removido  com sucesso!`,
    });
  };

  //APAGAR TERMO CASO NAO TENHA ID
  const handleDeleteTermo = () => {
    setIsDelete(true);
    modal.confirm({
      title: `Tem certeza que deseja Excluir o Termo de ${tipoTermoFormatado} ${termo.numero}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sim",
      cancelText: "Não",
      onOk: () => {
        removerTermo(termo.numero);
        openNotification({
          type: "success",
          message: `Termo de ${tipoTermoFormatado} excluído com sucesso!`,
        });
        setIsDelete(false);
      },
      onCancel: () => setIsDelete(false),
    });
  };

  //EDITAR TERMO
  const handleEditTermo = () => {
    navigate(
      `/termo-apreensao-liberacao/edit/${termo.numero.replaceAll("/", "_")}`
    );
    localStorage.setItem(
      "termoApreensaoLiberacao:pageSelect",
      pageControl.toString()
    );
  };

  const handleShowAnexos = () => {
    setIsOpenModalAnexos(true);
  };

  const handleEditPosEnvio = () => {
    if (!termo.id) return;
    localStorage.setItem(
      "termoApreensaoLiberacao:pageSelect",
      pageControl.toString()
    );
    navigate(
      `/termo-apreensao-liberacao/listagem/edicao-assinatura/${termo.id}`
    );
  };

  const handlePrintedTermo = async () => {
    localStorage.setItem(
      "termoApreensaoLiberacao:pageSelect",
      pageControl.toString()
    );
    navigate(
      `/termo-apreensao-liberacao/listagem/impressao/${termo.numero.replaceAll(
        "/",
        "_"
      )}`
    );
  };

  return (
    <>
      {contextHolder}
      <List.Item
        className="lista-item"
        style={{
          border: isCancel || isDelete ? "1px solid#ff4d4f" : "",
        }}
      >
        <List.Item.Meta
          title={
            <span
              className="titulo-card"
              style={{
                opacity: termo.id ? 1 : 0.7,
                color: isCancel || isDelete ? "#ff4d4f" : "#0D0D0D",
              }}
            >
              Termo de {tipoTermoFormatado} {termo.numero}{" "}
              {termo.id ? "" : "(Pendente)"}
            </span>
          }
          description={
            <span className="subtitulo-card">
              Data Fiscalização:
              {termo.dataFiscalizacao?.includes("/")
                ? termo.dataFiscalizacao
                : moment(termo.dataFiscalizacao || "").format(
                    "DD/MM/YYYY"
                  )}{" "}
              ID:
              {termo.id ? termo.id : ""}
              <br />
              Propriedade: {termo.tif?.nomePropriedade}
            </span>
          }
        />

        <div className={styles["pc-menu-lista-termos"]}>
          {!termo.id && navigator.onLine && (
            <Button disabled={isLoading} onClick={() => handlePostTermo()}>
              {isLoading ? <SyncOutlined spin={isLoading} /> : "Enviar"}
            </Button>
          )}

          {termo.anexos && termo.anexos.length > 0 && (
            <Tooltip title="Anexos" destroyTooltipOnHide>
              <Button
                onClick={(e) => {
                  handleShowAnexos();
                }}
              >
                <PaperClipOutlined />
              </Button>
            </Tooltip>
          )}

          <Tooltip title="Imprimir">
            <Button onClick={() => handlePrintedTermo()}>
              <PrinterOutlined />
            </Button>
          </Tooltip>

          {termo.id && (
            <>
              <Tooltip title="Visualizar" destroyTooltipOnHide>
                <Button
                  disabled={
                    !navigator.onLine || !isAllowed("visualizar", CODIGO_TERMO)
                  }
                  onClick={(e) => handleViewTermo()}
                >
                  <EyeOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Editar">
                <Button
                  disabled={
                    (termo.statusAssinaturaFiscalizado !== "AUSENTE" &&
                      termo.statusAssinaturaDepositario !== "AUSENTE") ||
                    !isAllowed("editar", CODIGO_TERMO)
                  }
                  onClick={() => handleEditPosEnvio()}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Cancelar" destroyTooltipOnHide>
                <Button
                  disabled={
                    !navigator.onLine || !isAllowed("excluir", CODIGO_TERMO)
                  }
                  danger
                  onClick={() => handleCancelTermo()}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Remover do uso offline">
                <Button danger onClick={() => handleRemoveTermoInApp()}>
                  <MinusOutlined />
                </Button>
              </Tooltip>
            </>
          )}

          {!termo.id && (
            <>
              <Tooltip title="Editar">
                <Button onClick={() => handleEditTermo()}>
                  <EditOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="Excluir">
                <Button danger onClick={() => handleDeleteTermo()}>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </>
          )}
        </div>

        <div className={styles["mobile-menu-lista-termos"]}>
          {!termo.id && navigator.onLine && (
            <Button disabled={isLoading} onClick={() => handlePostTermo()}>
              {isLoading ? <SyncOutlined spin={isLoading} /> : "Enviar"}
            </Button>
          )}

          <Dropdown
            placement="bottom"
            trigger={["click"]}
            overlay={() => {
              if (termo.id) {
                return (
                  <Menu
                    items={[
                      {
                        key: 0,
                        label: "Anexos",
                        icon: <PaperClipOutlined />,
                        disabled: !(termo.anexos && termo.anexos.length > 0),
                        onClick: () => {
                          handleShowAnexos();
                        },
                      },
                      {
                        key: 1,
                        label: "Imprimir",
                        icon: <PrinterOutlined />,
                        onClick: (e) => handlePrintedTermo(),
                      },
                      {
                        key: 2,
                        label: "Visualizar",
                        icon: <EyeOutlined />,
                        disabled:
                          !navigator.onLine ||
                          !isAllowed("visualizar", CODIGO_TERMO),
                        onClick: (e) => handleViewTermo(),
                      },
                      {
                        key: 3,
                        label: "Editar",
                        icon: <EditOutlined />,
                        onClick: (e) => handleEditPosEnvio(),
                        disabled:
                          (termo.statusAssinaturaFiscalizado !== "AUSENTE" &&
                            termo.statusAssinaturaDepositario !== "AUSENTE") ||
                          !isAllowed("editar", CODIGO_TERMO),
                      },
                      {
                        key: 4,
                        label: "Cancelar",
                        danger: true,
                        icon: <CloseOutlined />,
                        disabled:
                          !navigator.onLine ||
                          !isAllowed("excluir", CODIGO_TERMO),
                        onClick: () => {
                          handleCancelTermo();
                        },
                      },
                      {
                        key: 5,
                        label: "Remover do uso Offline",
                        danger: true,
                        icon: <MinusOutlined />,
                        disabled: !termo.id,
                        onClick: (e) => handleRemoveTermoInApp(),
                      },
                    ]}
                  />
                );
              } else {
                return (
                  <Menu
                    items={[
                      {
                        key: 0,
                        label: "Anexos",
                        icon: <PaperClipOutlined />,
                        disabled: !(termo.anexos && termo.anexos.length > 0),
                        onClick: () => {
                          handleShowAnexos();
                        },
                      },
                      {
                        key: 1,
                        label: "Imprimir",
                        icon: <PrinterOutlined />,
                        onClick: (e) => handlePrintedTermo(),
                      },
                      {
                        key: 2,
                        label: "Editar",
                        icon: <EditOutlined />,
                        onClick: (e) => handleEditTermo(),
                      },
                      {
                        key: 3,
                        label: "Excluir",
                        danger: true,
                        icon: <DeleteOutlined />,
                        onClick: () => handleDeleteTermo(),
                      },
                    ]}
                  />
                );
              }
            }}
          >
            <Tooltip title="Opções" destroyTooltipOnHide>
              <Button>
                <MenuOutlined />
              </Button>
            </Tooltip>
          </Dropdown>
        </div>
      </List.Item>

      <ModalCancelarTermo
        isOpen={isCancel}
        setIsOpen={setIsCancel}
        reloadFilter={async () => {}}
        apiName="termoApreensaoLiberacao"
        idTermo={termo.id}
        numTermo={termo.numero}
        onRemoveTermo={removerTermo}
        titleTermo={`Termo de ${tipoTermoFormatado}`}
      />

      <ModalVisualizarAnexos
        anexos={termo.anexos}
        isOpenModal={isOpenModalAnexos}
        setIsOpenModal={setIsOpenModalAnexos}
      />
    </>
  );
}
