import { createAction } from "@reduxjs/toolkit";
import { Destino } from "../../@types/Destino";
import { statusAssinatura } from "../../@types/StatusAssinatura";
import { ITermoColetaAmostras } from "../../interfaces/ITermoColetaAmostras";

interface IDadosEdit {
  statusAssinatura: statusAssinatura;
  destino: Destino;
  nomeAssinante: string;
  cpfAssinante: string;
  assinaturaFiscalizado: string;
  funcaoAssinante: string;
}

const addTermo = createAction(
  "TERMO_COLETA/addTermo",
  (termo: ITermoColetaAmostras) => ({
    payload: { termo },
  })
);

const updateAssinatura = createAction(
  "TERMO_COLETA/updateAssinatura",
  (
    dadosEdit: IDadosEdit,
    termoId: number,
    termoColeta: ITermoColetaAmostras
  ) => ({
    payload: { dadosEdit, termoId },
    meta: {
      offline: {
        effect: {
          //CHAMADA DA API
          url: `/vegetal/termos-coleta/${termoId}`,
          method: "PATCH",
          data: dadosEdit,
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "TERMO_COLETA/updateAssinaturaRollback",
          meta: { termoColeta },
        },
      },
    },
  })
);

const addTermoByFilter = createAction(
  "TERMO_COLETA/addTermoByFilter",
  (termo: ITermoColetaAmostras) => ({
    payload: { termo },
  })
);

const updateTermo = createAction(
  "TERMO_COLETA/updateTermo",
  (termo: ITermoColetaAmostras) => ({
    payload: { termo },
  })
);

const removeTermo = createAction(
  "TERMO_COLETA/removeTermo",
  (numTermo: string) => ({
    payload: { numTermo },
  })
);

const removeIdTermoColeta = createAction(
  "TERMO_COLETA/removeIdTermoColeta",
  (id: number) => ({
    payload: { id },
  })
);

const addTimePersistedAtItems = createAction(
  "TERMO_COLETA/addTimePersistedAtItems",
  (createdAt: number) => ({
    payload: { createdAt },
  })
);

const removeTimePersistedAtItems = createAction(
  "TERMO_COLETA/removeTimePersistedAtItems",
  () => ({
    payload: {},
  })
);

export const termoColetaAction = {
  addTermo,
  updateTermo,
  removeTermo,
  removeIdTermoColeta,
  addTermoByFilter,
  updateAssinatura,
  addTimePersistedAtItems,
  removeTimePersistedAtItems,
};
