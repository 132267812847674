import { IListagemTID } from "@interfaces/IListagemTID";
import { IDadosAssinaturaFiscalizadoTID, ITID } from "@interfaces/ITid";
import { api } from "@services/api";

export const httpTid = {
  listar: async (params: string) => {
    const { data } = await api.get<IListagemTID>(`/tids?${params}`);
    return data;
  },

  byId: async (termoId: number) => {
    const { data } = await api.get<ITID>(`/tids/${termoId}`);
    return data;
  },

  enviar: async (formData: FormData) => {
    const { data } = await api.post<ITID>("/tids", formData);
    return data;
  },

  remover: async (id: number, motivo: string) => {
    await api.post(`/tids/${id}/cancelar`, {
      motivo: motivo,
    });
    return;
  },
  getAnexo: async (nomeAnexo: string) => {
    const { data } = await api.get(`/tids/anexo/${nomeAnexo}`, {
      responseType: "blob",
    });
    return data;
  },
  update: async (dados: IDadosAssinaturaFiscalizadoTID, termoId: number) => {
    const { data } = await api.put<ITID>(`/tids/${termoId}`, dados);
    return data;
  },
};
