import {
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  MenuOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { ModalCancelarTermo } from "@components/ModalCancelarTermo";
import { openNotification } from "@components/Notifications";
import { usePermission } from "@hooks/usePermission";
import { IContentListagemTIF } from "@interfaces/IListagemTIF";
import { ITif } from "@interfaces/ITif";
import { apiService } from "@services/api";
import { Button, Dropdown, List, Menu, message, Tooltip } from "antd";
import { AxiosError } from "axios";
import { useTif } from "pages/tif/hooks/useTif";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../style.module.css";

const CODIGO_TERMO = "TIF";

export function ItemListaFiltroTIF({
  tif,
  pageControl,
}: {
  tif: IContentListagemTIF;
  pageControl: number;
}) {
  const navigate = useNavigate();
  const { isAllowed } = usePermission();
  const {
    adicionarTifByFiltro,
    listaTif,
    aplicarFiltro,
    listaTermoFiltro,
    removerTif,
    getAnexo,
  } = useTif();
  const [isTifCancel, setIsTifCancel] = useState(false);
  const [tifSelected, setTifSelected] = useState<ITif | null>(null);
  const [isDownload, setIsDownload] = useState(false);

  // IMPRIMIR TIF
  const handlePrinterTif = () => {
    navigate(`/TIF/listagem/impressao/${tif.id}`);
    localStorage.setItem("TIF:pageSelect", pageControl.toString());
    return;
  };

  // VISUALIZAR TIF
  const handleShowTif = () => {
    if (!tif.id) return;
    navigate(`/TIF/listagem/visualizar/${tif.id}`);
    localStorage.setItem("TIF:pageSelect", pageControl.toString());
  };

  //EDITAR POS ENVIO
  const handleEditTIFposEnvio = () => {
    if (!tif.id || !navigator.onLine) return;

    navigate(`/TIF/edit/atividades-assinatura/${tif.id}`);
    localStorage.setItem("TIF:pageSelect", pageControl.toString());
  };

  //CANCELAR TIF
  const handleCancelTIF = async () => {
    if (!tif.id) return;

    await apiService.tif
      .byId(tif.id)
      .then((data) => {
        setTifSelected(data);
        setIsTifCancel(true);
      })
      .catch((erro) => {
        console.log(erro);
        openNotification({
          type: "error",
          message: "Não foi possivel cancelar o TIF",
          description:
            erro.response.status === 401
              ? "Tempo de login expirado"
              : erro.response.data.detail,
        });
      });
  };

  //BAIXAR TIF DO FILTRO
  const handleDownloadTifInFilter = async () => {
    const tifInApp = listaTif.find((termo) => termo.id === tif.id);

    if (tifInApp) {
      openNotification({
        type: "info",
        message: "Não foi possivel baixar TIF",
        description: "Termo já adicionado no SISDEV-Moblie",
      });
      return;
    }

    message.loading({
      key: "download-tif",
      duration: 0,
      content: "Baixando TIF...",
    });
    setIsDownload(true);

    try {
      const responseTif = await apiService.tif.byId(tif.id);

      const dadosAnexos = responseTif.anexosTIF.map((anexo) => {
        return {
          nomeUpload: anexo.nomeUpload,
          nomeArquivo: anexo.nomeArquivo,
        };
      });

      if (dadosAnexos.length <= 0) {
        adicionarTifByFiltro({
          ...responseTif,
          anexosTIF: [],
        });
      } else {
        const anexos = await getAnexo(dadosAnexos);
        // console.log(anexos);

        adicionarTifByFiltro({
          ...responseTif,
          anexosTIF: anexos,
        });
      }

      openNotification({
        type: "success",
        message: `TIF ${responseTif.numero} foi baixado com sucesso!`,
      });
      setIsDownload(false);
      message.destroy("download-tif");
    } catch (error) {
      console.log(error);
      setIsDownload(false);
      message.destroy("download-tif");
      if (error instanceof AxiosError) {
        openNotification({
          type: "error",
          message: "Não foi possivel baixar TIF",
          description: `Erro: ${error.response?.status}: ${error.response?.data.title}.`,
        });
        return;
      }

      openNotification({
        type: "error",
        message: "Não foi possivel baixar TIF",
        description: `Erro desconhecido.`,
      });
    }
  };

  //ATUALIZA A LISTA DE TIFS
  const reloadFilter = async () => {
    const params = new URLSearchParams(listaTermoFiltro[0].params);

    params.append("page", (pageControl - 1).toString());

    await apiService.tif
      .listar(params.toString())
      .then((data) => {
        if (data.content.length > 0) {
          aplicarFiltro([
            {
              filtros: listaTermoFiltro[0].filtros,
              params: listaTermoFiltro[0].params.toString(),
              formularios: data.content,
              numberPages: data.totalPages,
            },
          ]);
          // window.location.reload();
        }
      })
      .catch((erro) => {
        console.log(erro);
        openNotification({
          type: "error",
          message: "Não foi possivel atualizar o filtro",
        });
      });

    localStorage.setItem("TIF:pageSelect", pageControl.toString());
  };

  return (
    <>
      <List.Item
        style={{
          border: isTifCancel ? "1px solid #ff4d4f" : "",
        }}
      >
        <List.Item.Meta
          title={
            <span
              className="titulo-card"
              style={{
                color: isTifCancel ? "#ff4d4f" : "#0D0D0D",
              }}
            >
              TIF {tif.numero} {tif.nomeFiscalizado}{" "}
              {tif.ativo ? "" : "(Cancelado)"}
            </span>
          }
          description={
            <span className="subtitulo-card">
              Data Fiscalização:
              {tif.dataFiscalizacao} ID:{tif.id ? tif.id : ""}
              <br />
              Propriedade: {tif.nomePropriedade}
            </span>
          }
        />

        <div className={styles["pc-menu-list"]}>
          <Tooltip title="Baixar para uso offline" destroyTooltipOnHide>
            <Button
              disabled={
                !navigator.onLine ||
                !tif.ativo ||
                !tif.statusAssinatura ||
                isDownload
              }
              onClick={() => handleDownloadTifInFilter()}
            >
              <DownloadOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Imprimir" destroyTooltipOnHide>
            <Button
              disabled={
                !navigator.onLine || !tif.statusAssinatura || isDownload
              }
              onClick={(e) => handlePrinterTif()}
            >
              <PrinterOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Editar" destroyTooltipOnHide>
            <Button
              disabled={
                !tif.ativo ||
                !navigator.onLine ||
                !isAllowed("editar", CODIGO_TERMO) ||
                !tif.statusAssinatura ||
                isDownload
              }
              onClick={(e) => handleEditTIFposEnvio()}
            >
              <EditOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Visualizar" destroyTooltipOnHide>
            <Button
              disabled={
                !tif.ativo ||
                !navigator.onLine ||
                !isAllowed("visualizar", CODIGO_TERMO) ||
                !tif.statusAssinatura ||
                isDownload
              }
              onClick={() => handleShowTif()}
            >
              <EyeOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Cancelar" destroyTooltipOnHide>
            <Button
              disabled={
                !tif.ativo ||
                !navigator.onLine ||
                !isAllowed("excluir", CODIGO_TERMO) ||
                !tif.statusAssinatura ||
                isDownload
              }
              danger
              onClick={(e) => handleCancelTIF()}
            >
              <CloseOutlined />
            </Button>
          </Tooltip>
        </div>

        <div className={styles["mobile-menu-list"]}>
          <Dropdown
            placement="bottom"
            trigger={["click"]}
            overlay={
              <Menu
                items={[
                  {
                    key: 0,
                    label: "Baixar",
                    disabled:
                      !navigator.onLine ||
                      !tif.ativo ||
                      !tif.statusAssinatura ||
                      isDownload,
                    icon: <DownloadOutlined />,
                    onClick: () => handleDownloadTifInFilter(),
                  },
                  {
                    key: 1,
                    label: "Imprimir",
                    disabled:
                      !tif.ativo ||
                      !navigator.onLine ||
                      !tif.statusAssinatura ||
                      isDownload,
                    icon: <PrinterOutlined />,
                    onClick: (e) => handlePrinterTif(),
                  },

                  {
                    key: 2,
                    label: "Editar",
                    icon: <EditOutlined />,
                    disabled:
                      !tif.ativo ||
                      !navigator.onLine ||
                      !isAllowed("editar", CODIGO_TERMO) ||
                      !tif.statusAssinatura ||
                      isDownload,
                    onClick: (e) => handleEditTIFposEnvio(),
                  },
                  {
                    key: 3,
                    label: "Visualizar",
                    icon: <EyeOutlined />,
                    onClick: (e) => handleShowTif(),
                    disabled:
                      !tif.ativo ||
                      !navigator.onLine ||
                      !isAllowed("visualizar", CODIGO_TERMO) ||
                      !tif.statusAssinatura ||
                      isDownload,
                  },
                  {
                    key: 4,
                    icon: <CloseOutlined />,
                    label: "Cancelar",
                    danger: true,
                    disabled:
                      !tif.ativo ||
                      !navigator.onLine ||
                      !isAllowed("excluir", CODIGO_TERMO) ||
                      !tif.statusAssinatura ||
                      isDownload,
                    onClick: (e) => handleCancelTIF(),
                  },
                ]}
              />
            }
          >
            <Tooltip title="Opções" destroyTooltipOnHide>
              <Button>
                <MenuOutlined />
              </Button>
            </Tooltip>
          </Dropdown>
        </div>
      </List.Item>

      {/**MODAL DE CANCELAMENTO DO TIF */}
      {tifSelected && (
        <ModalCancelarTermo
          isOpen={isTifCancel}
          setIsOpen={setIsTifCancel}
          isFilterApplied
          reloadFilter={reloadFilter}
          numTermo={tifSelected.numero}
          apiName="tif"
          idTermo={tifSelected.id}
          onRemoveTermo={removerTif}
          titleTermo="TIF"
        />
      )}
    </>
  );
}
