import { createAction } from "@reduxjs/toolkit";
import { IEstadioFenologico } from "../../interfaces/IEstadioFenologico";

const addEstadioFenologico = createAction(
  "ESTADIO_FENOLOGICO/addEstadioFenologico",
  (estadioFenologico: IEstadioFenologico[], dateTimeExpire: number) => ({
    payload: { estadioFenologico, dateTimeExpire },
  })
);

const removeEstadioFenologico = createAction(
  "ESTADIO_FENOLOGICO/removeEstadioFenologico",
  () => ({
    payload: {},
  })
);

export const estadioFenologicoAction = {
  addEstadioFenologico,
  removeEstadioFenologico,
};
