import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { IRespostaPerguntasTermoVistoria } from "@interfaces/ITermoVistoria";
import moment from "moment";

export const getRespostasVistoria = (
  perguntas: IPerguntaTermoVistoria[],
  values: any
) => {
  const respostas: IRespostaPerguntasTermoVistoria[] = perguntas.map(
    (pergunta) => {
      const { tipoResposta, id, alternativas, campos, formatoResposta, nome } =
        pergunta.pergunta;

      const isAlternativa = tipoResposta.includes("ALTERNATIVA");

      const alternativa = isAlternativa
        ? alternativas.filter(
            (alt) =>
              alt.id === values[`listaPerguntas.${id}.respostaAlternativa`]
          )
        : [];

      const isMultiplaEscolha = tipoResposta.includes("MULTIPLA_ESCOLHA");

      const multiplaEscolha = isMultiplaEscolha
        ? alternativas.filter((alt) =>
            values[`listaPerguntas.${id}.respostaMultiplaEscolha`].includes(
              alt.id
            )
          )
        : [];

      const descricao = tipoResposta.includes("DESCRITIVA")
        ? values[`listaPerguntas.${id}.descricao`]
        : "";

      const camposResposta = campos.map((campo, ind) => {
        if (campo.formatoResposta === "DATE") {
          return {
            campo,
            id: campo.id,
            resposta: moment(
              values[`listaPerguntas.${id}.campos.${ind}.resposta`]
            ).format(),
          };
        }

        return {
          campo,
          id: campo.id,
          resposta: values[`listaPerguntas.${id}.campos.${ind}.resposta`],
        };
      });

      return {
        pergunta: {
          id: id,
          tipoResposta: tipoResposta,
          formatoResposta: formatoResposta,
          nome: nome,
        },
        descricao:
          formatoResposta === "DATE" ? moment(descricao).format() : descricao,
        alternativas: isAlternativa
          ? alternativa.map((alt) => {
              return {
                alternativa: alt,
              };
            })
          : multiplaEscolha.map((alt) => {
              return {
                alternativa: alt,
              };
            }),
        campos: camposResposta,
        order: pergunta.order,
      };
    }
  );

  return respostas;
};
