import { IListagemTIF } from "@interfaces/IListagemTIF";
import { ITif } from "@interfaces/ITif";
import { api } from "@services/api";

export const httpTif = {
  byId: async (id: number) => {
    const { data } = await api.get<ITif>(`/vegetal/tifs/${id}`);
    return data;
  },
  listar: async (params: string) => {
    const { data } = await api.get<IListagemTIF>(`/vegetal/tifs?${params}`);
    return data;
  },
  prazoNotificacao: async (params: string) => {
    const tifs = await api.get(`/vegetal/tifs/prazo-notificacao?${params}`);
    return tifs;
  },
  getAnexo: async (nomeArquivo: string) => {
    const { data } = await api.get<Blob>(`/vegetal/tifs/anexo/${nomeArquivo}`, {
      responseType: "blob",
    });
    return { data };
  },

  getByNumero: async (params: string) => {
    const { data } = await api.get<ITif>(`/vegetal/tifs/buscar?${params}`);
    return data;
  },

  update: async (item: any, id: number) => {
    const { data } = await api.patch<ITif>(`/vegetal/tifs/${id}`, item);
    return data;
  },
  enviar: async (formData: FormData) => {
    const { data } = await api.post<ITif>("/vegetal/tifs", formData);
    return data;
  },
  remover: async (id: number, motivo: string) => {
    await api.post(`/vegetal/tifs/${id}/cancelar`, {
      motivo: motivo,
    });
    return;
  },

  enviarAnexos: async (id: number, anexos: FormData) => {
    const tif = await api.put(`/vegetal/tifs/${id}/anexo`, anexos, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return tif;
  },
};
