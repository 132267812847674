import { openNotification } from "@components/Notifications";
import { IArmadilha } from "@interfaces/IArmadilha";
import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { ITipoAtividade } from "@interfaces/ITipoAtividade";
import { atividadeAction } from "@store/actions/atividadeAction";
import {
  Button,
  Divider,
  Form,
  FormInstance,
  Modal,
  RadioChangeEvent,
  Typography,
} from "antd";
import moment from "moment";
import { MultiplaEscolha } from "pages/termoVistoria/components/PerguntasVistoria/components/MultiplaEscolha";
import { useDispatch } from "react-redux";
import { Alternativa } from "./components/Alternativa";
import { Campo } from "./components/Campo";
import { Descritiva } from "./components/Descritiva";
import styles from "./style.module.css";

interface IModalPerguntaAtividadeProps {
  isOpen: boolean;
  atividadeSelected: ITipoAtividade | null;
  perguntas: IPerguntaAtividade[];
  formPerguntas: FormInstance;
  handleClose: () => void;
  setPerguntas: React.Dispatch<React.SetStateAction<IPerguntaAtividade[]>>;
  armadilhaSelected: IArmadilha | null;
}

export function ModalPerguntaAtividade({
  isOpen,
  formPerguntas,
  atividadeSelected,
  perguntas,
  handleClose,
  setPerguntas,
  armadilhaSelected,
}: IModalPerguntaAtividadeProps) {
  const appDispatch = useDispatch();
  const { addRespostasAtividade } = atividadeAction;

  const handleChangeAnswerQuestion = (
    event: RadioChangeEvent,
    pergunta: IPerguntaAtividade
  ) => {
    const { value } = event.target;

    if (!atividadeSelected) return;

    // PEGAR AS PERGUNTAS DEPENDENTES QUE PODEM TER DE ACORDO COM A RESPOSTA DADA
    let perguntasDependentes = atividadeSelected?.questoes.filter(
      (perg) =>
        perg.pergunta.antecessora?.id === pergunta.pergunta.id &&
        perg.pergunta.respostaCondicional?.id === value
    );

    //verificando se pergunta ja foi mostrada
    perguntasDependentes = perguntasDependentes.filter((pergunta) => {
      return perguntas.findIndex((aux) => aux.id === pergunta.id) === -1;
    });

    let filterPerguntas = perguntas.filter((perg) => {
      if (
        perg.pergunta.antecessora?.id === pergunta.pergunta.id &&
        perg.pergunta.respostaCondicional?.id !== value
      ) {
        formPerguntas.setFieldValue(
          `listaPerguntas.${perg.pergunta.id}.respostaAlternativa`,
          ""
        );

        formPerguntas.setFieldValue(
          `listaPerguntas.${perg.pergunta.id}.respostaMultiplaEscolha`,
          ""
        );

        perg.pergunta.campos.forEach((_, ind) => {
          formPerguntas.setFieldValue(
            `listaPerguntas.${perg.pergunta.id}.campos.${ind}.resposta`,
            ""
          );
        });

        formPerguntas.setFieldValue(
          `listaPerguntas.${perg.pergunta.id}.descricao`,
          ""
        );

        return null;
      }

      return perg;
    });

    let perguntasListadas: IPerguntaAtividade[] = filterPerguntas;

    if (perguntasDependentes.length > 0) {
      const perguntasOrdenadas = perguntasDependentes.sort((a, b) =>
        a.order > b.order ? 1 : -1
      );

      perguntasListadas = filterPerguntas.concat(
        perguntasOrdenadas.map((perg, indice) => {
          return {
            ...perg,
            indPergunta: `${pergunta.indPergunta}.${indice + 1}`,
          };
        })
      );

      perguntasListadas.sort((a, b) => (a.order > b.order ? 1 : -1));
    }

    filterPerguntas = perguntasListadas.filter((perg, ind, todasPerguntas) => {
      if (perg.pergunta.antecessora) {
        if (
          todasPerguntas.findIndex(
            (aux) => aux.pergunta.id === perg.pergunta.antecessora?.id
          ) === -1
        ) {
          return null;
        }

        return perg;
      }

      return perg;
    });

    setPerguntas(filterPerguntas);
  };

  const handleSubmit = (values: any) => {
    if (!atividadeSelected) return;

    const resp = perguntas.map((perg) => {
      const {
        id,
        nome,
        tipoResposta,
        visivel,
        alternativas,
        campos,
        formatoResposta,
      } = perg.pergunta;

      const isAlternativa = tipoResposta.includes("ALTERNATIVA");

      const alternativa = isAlternativa
        ? alternativas.filter(
            (alt) =>
              alt.id === values[`listaPerguntas.${id}.respostaAlternativa`]
          )
        : [];

      const isMultiplaEscolha = tipoResposta.includes("MULTIPLA_ESCOLHA");

      const multiplaEscolha = isMultiplaEscolha
        ? alternativas.filter((alt) =>
            values[`listaPerguntas.${id}.respostaMultiplaEscolha`].includes(
              alt.id
            )
          )
        : [];

      const descricao = tipoResposta.includes("DESCRITIVA")
        ? values[`listaPerguntas.${id}.descricao`]
        : "";

      const camposResposta = campos.map((campo, ind) => {
        if (campo.formatoResposta === "DATE") {
          return {
            campo,
            id: campo.id,
            resposta: moment(
              values[`listaPerguntas.${id}.campos.${ind}.resposta`]
            ).format(),
          };
        }

        return {
          campo,
          id: campo.id,
          resposta: values[`listaPerguntas.${id}.campos.${ind}.resposta`],
        };
      });

      return {
        armadilha: armadilhaSelected,
        atividade: {
          nome: atividadeSelected?.nome,
          id: atividadeSelected?.id,
        },
        pergunta: {
          id,
          nome,
          tipoResposta,
          formatoResposta,
          visivel,
        },
        descricao:
          formatoResposta === "DATE" ? moment(descricao).format() : descricao,
        alternativas: isAlternativa
          ? alternativa.map((alt) => {
              return {
                alternativa: alt,
              };
            })
          : multiplaEscolha.map((alt) => {
              return {
                alternativa: alt,
              };
            }),
        campos: camposResposta,
      };
    });

    appDispatch(addRespostasAtividade(resp));

    openNotification({
      type: "success",
      message: "Respostas do questionário salvas com sucesso!",
    });

    handleClose();
  };

  const errorSubmit = () => {
    openNotification({
      type: "error",
      message: "Não foi possivel salvar respostas",
      description:
        "Termine de responder todas as perguntas pendentes no questionário.",
    });
  };

  return (
    <Form
      form={formPerguntas}
      id="perguntas-form"
      onFinish={handleSubmit}
      onFinishFailed={errorSubmit}
    >
      <Modal
        title={
          <h1 className={styles["titulo-modal"]}>
            QUESTIONÁRIO DE {atividadeSelected ? atividadeSelected.nome : ""}
          </h1>
        }
        width={1400}
        maskClosable={false}
        keyboard={true}
        open={isOpen}
        onCancel={handleClose}
        footer={[
          <Button key="cancelar" onClick={handleClose}>
            Cancelar
          </Button>,
          <Button
            key="salvar"
            type="primary"
            htmlType="submit"
            form="perguntas-form"
          >
            Salvar Respostas
          </Button>,
        ]}
      >
        <div className={styles["container-perguntas"]}>
          {perguntas.map((perg, ind) => {
            const identacaoEspaco = perg.indPergunta?.split(".").length || 0;

            return (
              <div
                key={ind}
                style={{
                  padding: 5,
                  paddingLeft: identacaoEspaco === 1 ? 0 : identacaoEspaco * 20,
                }}
              >
                <Typography.Title level={5}>
                  {perg.indPergunta}. {perg.pergunta.nome}
                </Typography.Title>

                {perg.pergunta.tipoResposta === "ALTERNATIVA" && (
                  <Alternativa
                    question={perg}
                    onChangeAnswer={handleChangeAnswerQuestion}
                  />
                )}
                {perg.pergunta.tipoResposta === "MULTIPLA_ESCOLHA" && (
                  <MultiplaEscolha question={perg} />
                )}
                {perg.pergunta.tipoResposta === "DESCRITIVA" && (
                  <Descritiva question={perg} form={formPerguntas} />
                )}

                {perg.pergunta.tipoResposta === "ALTERNATIVA_DESCRITIVA" && (
                  <>
                    <Alternativa
                      question={perg}
                      onChangeAnswer={handleChangeAnswerQuestion}
                    />
                    <Descritiva question={perg} form={formPerguntas} />
                  </>
                )}
                {perg.pergunta.tipoResposta ===
                  "MULTIPLA_ESCOLHA_DESCRITIVA" && (
                  <>
                    <MultiplaEscolha question={perg} />
                    <Descritiva question={perg} form={formPerguntas} />
                  </>
                )}

                <Campo question={perg} form={formPerguntas} />

                <Divider />
              </div>
            );
          })}
        </div>
      </Modal>
    </Form>
  );
}
