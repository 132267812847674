import { IListagemTermoApreensaoLiberacao } from "@interfaces/IListagemTermoApreensaoLiberacao";
import { ITermoApreensaoLiberacao } from "@interfaces/ITermoApreensaoLiberacao";
import { api } from "@services/api";

export const httpTermoApreensaoLiberacao = {
  enviar: async (form: FormData) => {
    const termo = await api.post("/vegetal/termos-apreensao-liberacao", form);

    return termo;
  },

  listar: async (params: string) => {
    const { data } = await api.get<IListagemTermoApreensaoLiberacao>(
      `/vegetal/termos-apreensao-liberacao?${params}`
    );

    return data;
  },

  byId: async (id: number) => {
    const { data } = await api.get<ITermoApreensaoLiberacao>(
      `/vegetal/termos-apreensao-liberacao/${id}`
    );

    return data;
  },

  remover: async (id: number, motivo: string) => {
    await api.post(`/vegetal/termos-apreensao-liberacao/${id}/cancelar`, {
      motivo: motivo,
    });

    return;
  },
  update: async (dados: any, termoId: number) => {
    const { data } = await api.put(
      `/vegetal/termos-apreensao-liberacao/${termoId}`,
      dados
    );

    return data;
  },

  getAnexo: async (nomeArquivo: string) => {
    const { data } = await api.get<Blob>(
      `/vegetal/termos-apreensao-liberacao/anexo/${nomeArquivo}`,
      {
        responseType: "blob",
      }
    );

    return { data };
  },
};
