import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { Checkbox, Form, FormInstance } from "antd";

export function MultiplaEscolha({
  pergunta,
  perguntasForm,
}: {
  pergunta: IPerguntaAtividade;
  perguntasForm: FormInstance;
}) {
  return (
    <>
      <Form.Item
        label="Multipla Escolha"
        labelCol={{
          span: 0,
          offset: 0,
        }}
        style={{ marginBottom: 0 }}
      >
        <Checkbox.Group
          disabled
          value={perguntasForm.getFieldValue(
            `listaPerguntas.${pergunta.pergunta.id}.respostaMultiplaEscolha`
          )}
        >
          {pergunta.pergunta.alternativas.map((option) => {
            if (option.ativo) {
              return (
                <Checkbox value={option.id} key={option.id}>
                  {option.nome}
                </Checkbox>
              );
            }

            return null;
          })}
        </Checkbox.Group>
      </Form.Item>
    </>
  );
}
