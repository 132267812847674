import { openNotification } from "@components/Notifications";
import { transformStringToNumber } from "@functions/transformStringToNumber";
import { useDadosTermoVistoria } from "@hooks/useDadosTermoVistoria";
import { useMunicipio } from "@hooks/useMunicipio";
import { useServidores } from "@hooks/useServidores";
import { IAssinante } from "@interfaces/IAssinante";
import { IFormValuesTermoVistoria } from "@interfaces/IFormValuesTermoVistoria";
import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import { apiService } from "@services/api";
import { Button, FormInstance } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useReactToPrint } from "react-to-print";
import { getPerguntasEnumeradas } from "../../functions/getPerguntasEnumeradas";
import { getRespostasVistoria } from "../../functions/getRespostasVistoria";
import { BodyTermoVistoriaImpressao } from "../../listagemTermoVistoria/ViewImpressaoTermoVistoria/components/BodyTermoVistoriaImpressao";
import { FooterTermoVistoriaImpressao } from "../../listagemTermoVistoria/ViewImpressaoTermoVistoria/components/FooterTermoVistoriaImpressao";
import { HeaderTermoVistoriaImpressao } from "../../listagemTermoVistoria/ViewImpressaoTermoVistoria/components/HeaderTermoVistoriaImpressao";

interface Props {
  form: FormInstance<IFormValuesTermoVistoria>;
  fiscalizado: IAssinante;
  perguntas: IPerguntaTermoVistoria[];
  setPerguntas: React.Dispatch<React.SetStateAction<IPerguntaTermoVistoria[]>>;
}

export function PreviaImpressaoVistoria({
  form,
  fiscalizado,
  perguntas,
  setPerguntas,
}: Props) {
  const vistoriaRef = useRef(null);
  const [termo, setTermo] = useState<ITermoVistoria | null>(null);
  const { getMunicipioPorId } = useMunicipio();
  const { getServidorPorMatricula } = useServidores();
  const { listaFormsPerguntaTermoVistoria } = useDadosTermoVistoria();

  const { data: listaPerguntas } = useQuery({
    initialData: listaFormsPerguntaTermoVistoria,
    queryFn: () => apiService.listagemFormularios(),
    queryKey: "FORMULARIO_PERGUNTAS_TERMO_VISTORIA",
  });

  const getFormSelected = (formId: number) => {
    const form = listaPerguntas?.filter(
      (pergunta) => pergunta.id === formId
    )[0];

    if (!form) return;

    return form;
  };

  const preencherTermo = () => {
    //VALIDACOES

    try {
      let formValues: ITermoVistoria = {
        cpfCnpjFiscalizado: form
          .getFieldValue("cnpjFiscalizado")
          .replace(/[^\d]+/g, ""),
        nomeFiscalizado: form.getFieldValue("nomeFiscalizado"),
        emailFiscalizado: form.getFieldValue("emailFiscalizado"),
        telefoneFiscalizado: form
          .getFieldValue("telefoneFiscalizado")
          .replace(/[^\d]+/g, ""),
        cepFiscalizado: form.getFieldValue("cepFiscalizado"),
        municipioFiscalizado: getMunicipioPorId(
          form.getFieldValue("municipioFiscalizado")
        ),
        formulario:
          getFormSelected(form.getFieldValue("tipoEstabelecimentoId")) || null,
        assinaturaFiscalizado: "",
        nomeAssinanteFiscalizado: fiscalizado.nomeAssinante,
        cpfAssinanteFiscalizado: fiscalizado.cpfAssinante.replace(
          /[^\d]+/g,
          ""
        ),
        funcaoAssinanteFiscalizado: fiscalizado.funcaoAssinante,
        assinaturaServidor: "",
        servidor:
          getServidorPorMatricula(form.getFieldValue("matriculaServidor")) ||
          null,
        numero: "",
        statusAssinaturaFiscalizado: "ASSINADO_FISICAMENTE",
        statusAssinaturaServidor: "ASSINADO_FISICAMENTE",
        enderecoFiscalizado: form.getFieldValue("enderecoFiscalizado"),
        latGrauFiscalizado:
          transformStringToNumber(
            form.getFieldValue("latGrauFiscalizado") || ""
          ) || 0,
        latMinFiscalizado:
          transformStringToNumber(
            form.getFieldValue("latMinFiscalizado") || ""
          ) || 0,
        latSegFiscalizado:
          transformStringToNumber(
            form.getFieldValue("latSegFiscalizado") || ""
          ) || 0,
        longGrauFiscalizado:
          transformStringToNumber(
            form.getFieldValue("longGrauFiscalizado") || ""
          ) || 0,
        longMinFiscalizado:
          transformStringToNumber(
            form.getFieldValue("longMinFiscalizado") || ""
          ) || 0,
        longSegFiscalizado:
          transformStringToNumber(
            form.getFieldValue("longSegFiscalizado") || ""
          ) || 0,
        dataCadastro: `${moment().format("YYYY-MM-DD[T]HH:mm:ss")}Z`,
        inscricaoEstadualFiscalizado: "",
        respostas: getRespostasVistoria(perguntas, form.getFieldsValue()),
      };

      setTermo(formValues);
    } catch (error) {
      openNotification({
        type: "error",
        message: "Não foi possivel gerar prévia do Termo de Vistoria",
        description:
          "Por favor, verifique se todas as informaçoes foram preenchidas corretamente.",
      });
    }
  };

  const handlePrint = useReactToPrint({
    contentRef: vistoriaRef,
    documentTitle: `Termo Vistoria minuta`,
    suppressErrors: true,
    onAfterPrint: () => {
      if (!termo) return;
      setPerguntas(getPerguntasEnumeradas(termo));
      setTermo(null);
    },
  });

  useEffect(() => {
    if (termo) {
      handlePrint();
    }
    //eslint-disable-next-line
  }, [termo]);

  return (
    <>
      <Button onClick={preencherTermo}>Gerar Prévia em PDF</Button>

      <div style={{ display: "none" }}>
        {termo && (
          <div ref={vistoriaRef} className="termo-vistoria-font">
            <div className="marcadagua">MINUTA</div>
            <table style={{ width: 780 }}>
              <HeaderTermoVistoriaImpressao numTermo={" "} />

              <BodyTermoVistoriaImpressao
                termo={termo}
                perguntas={getPerguntasEnumeradas(termo)}
              />

              <FooterTermoVistoriaImpressao termo={termo} />
            </table>
          </div>
        )}
      </div>
    </>
  );
}
