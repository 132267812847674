import { Checkbox, DatePicker, Form, FormInstance, Input, Table } from "antd";
import moment from "moment";
import React from "react";
import { ITabelaTesteLasgal } from "../../../../../interfaces/ITabelaTesteLasgal";
import { ITabelaTesteLasgalGerminacao } from "../../../../../interfaces/ITabelaTesteLasgalGerminacao";
import { EdicaoCelulaTabelaGerminacao } from "./components/EdicaoCelulaTabelaGerminacao";
import { EdicaoLinhaTabelaGerminacao } from "./components/EdicaoLinhaTabelaGerminacao";
import styles from "./style.module.css";

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

interface DataType {
  [key: string]: string;
}

interface IGerminacaoProps {
  setDataTable: React.Dispatch<React.SetStateAction<ITabelaTesteLasgal[]>>;
  dataTable: ITabelaTesteLasgal[];
  form: FormInstance<any>;
  dataSource: ITabelaTesteLasgalGerminacao[];
  setDataSource: React.Dispatch<
    React.SetStateAction<ITabelaTesteLasgalGerminacao[]>
  >;
  setRoloSelected: React.Dispatch<React.SetStateAction<string>>;
  roloSelected: string;
}

export function Germinacao({
  setDataTable,
  dataTable,
  form,
  dataSource,
  setDataSource,
  roloSelected,
  setRoloSelected,
}: IGerminacaoProps) {
  const handleSave = (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
      data: moment().format("DD/MM/YYYY"),
    });
    setDataSource(newData);
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      align: "center",
      title: "Data",
      dataIndex: "data",
      width: 100,
    },
    {
      align: "center",
      title: "Validade",
      dataIndex: "validade",
      width: 50,
    },
    {
      title: "Normais",
      dataIndex: "normais",
      align: "center",
      editable: true,
      width: 100,
    },
    {
      title: "Anormais",
      dataIndex: "anormais",
      align: "center",
      editable: true,
      width: 100,
    },
    {
      title: "Duras",
      dataIndex: "duras",
      align: "center",
      editable: true,
      width: 100,
    },
    {
      title: "Dormentes",
      dataIndex: "dormentes",
      align: "center",
      editable: true,
      width: 100,
    },
    {
      title: "Mortas",
      dataIndex: "mortas",
      align: "center",
      editable: true,
      width: 100,
    },
    {
      title: "2Rol",
      dataIndex: "rolo2",
      align: "center",
      width: 100,
      render: (value: string) => (
        <Checkbox
          value={roloSelected}
          checked={roloSelected === "rolo2"}
          onChange={(e) => {
            handleChangeRoloSelected("rolo2");
          }}
        />
      ),
    },
    {
      title: "4Rol",
      dataIndex: "rolo4",
      align: "center",
      width: 100,
      render: (value: string) => (
        <Checkbox
          value={roloSelected}
          checked={roloSelected === "rolo4"}
          onChange={(e) => {
            handleChangeRoloSelected("rolo4");
          }}
        />
      ),
    },
    {
      title: "8Rol",
      dataIndex: "rolo8",
      align: "center",
      width: 100,
      render: (value: string) => (
        <Checkbox
          value={roloSelected}
          checked={roloSelected === "rolo8"}
          onChange={(e) => {
            handleChangeRoloSelected("rolo8");
          }}
        />
      ),
    },
    {
      title: "16Rol",
      dataIndex: "rolo16",
      align: "center",
      width: 100,
      render: (value: string) => (
        <Checkbox
          value={roloSelected}
          checked={roloSelected === "rolo16"}
          onChange={(e) => {
            handleChangeRoloSelected("rolo16");
          }}
        />
      ),
    },

    {
      title: "Operador",
      dataIndex: "operador",
      align: "center",
      width: 100,
    },
  ];

  const components = {
    body: {
      row: EdicaoLinhaTabelaGerminacao,
      cell: EdicaoCelulaTabelaGerminacao,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        setDataTable,
        dataTable,
      }),
    };
  });

  const handleChangeRoloSelected = (
    roloName: "rolo2" | "rolo4" | "rolo8" | "rolo16"
  ) => {
    if (roloSelected === roloName) {
      setRoloSelected("");
      return;
    }

    setRoloSelected(roloName);
  };

  const calcularDuracaoTeste = () => {
    if (
      !form.getFieldValue("conclusaoTeste") ||
      !form.getFieldValue("inicioTeste")
    )
      return;

    const resultado = form
      .getFieldValue("conclusaoTeste")
      .diff(form.getFieldValue("inicioTeste"), "days");

    form.setFieldValue("duracaoTeste", `${resultado} Dia(s)`);
  };

  return (
    <div>
      <h1 className={styles.title}>GERMINAÇÃO</h1>

      <Table
        size="small"
        components={components}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        pagination={false}
        style={{ marginBottom: 50, overflowX: "auto" }}
      />

      <Form form={form} wrapperCol={{ flex: 1, span: 10 }} labelAlign="left">
        <Form.Item
          label="Trat. especial"
          name="tratamentoEspecial"
          labelCol={{ flex: "100px", span: 10 }}
        >
          <Input style={{ minWidth: 150 }} />
        </Form.Item>
        <div style={{ display: "flex", gap: 50 }}>
          <div>
            <Form.Item
              label="Data Entrada"
              name="dataEntrada"
              labelCol={{ flex: "120px", span: 12 }}
            >
              <DatePicker
                style={{ minWidth: 150 }}
                format={"DD/MM/YYYY"}
                disabled
              />
            </Form.Item>

            <Form.Item
              label="Duração Teste"
              name="duracaoTeste"
              labelCol={{ flex: "120px", span: 12 }}
            >
              <Input disabled style={{ minWidth: 150 }} />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Inicio Teste"
              name="inicioTeste"
              labelCol={{ flex: "150px", span: 12 }}
            >
              <DatePicker
                style={{ minWidth: 150 }}
                format={"DD/MM/YYYY"}
                onChange={(value) => {
                  calcularDuracaoTeste();
                  form.setFieldValue("conclusaoTeste", "");
                }}
              />
            </Form.Item>

            <Form.Item
              label="Conclusão Teste"
              name="conclusaoTeste"
              labelCol={{ flex: "150px", span: 12 }}
            >
              <DatePicker
                style={{ minWidth: 150 }}
                format={"DD/MM/YYYY"}
                onChange={(value) => calcularDuracaoTeste()}
                disabledDate={(date) => {
                  if (!form.getFieldValue("inicioTeste")) return true;

                  if (
                    date.valueOf() > form.getFieldValue("inicioTeste").valueOf()
                  ) {
                    return false;
                  }
                  return true;
                }}
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item
          label="Germinador"
          name="germinador"
          labelCol={{ flex: "100px", span: 10 }}
        >
          <Input style={{ minWidth: 150 }} />
        </Form.Item>

        <Form.Item
          label="Substrato"
          name="substrato"
          labelCol={{ flex: "100px", span: 10 }}
        >
          <Input style={{ minWidth: 150 }} disabled />
        </Form.Item>

        <Form.Item
          label="Temperatura"
          name="temperatura"
          labelCol={{ flex: "100px", span: 10 }}
        >
          <Input style={{ width: 100 }} disabled />
        </Form.Item>

        <Form.Item
          label="Observação"
          name="observacao"
          labelCol={{ flex: "100px", span: 10 }}
        >
          <Input.TextArea rows={4} style={{ minWidth: 150 }} />
        </Form.Item>
      </Form>
    </div>
  );
}
