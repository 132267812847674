import { IPropriedade } from "@interfaces/IPropriedade";
import { api } from "@services/api";

export const getPropriedades = {
  byMunicipio: async (idMunicipio: number) => {
    const { data } = await api.get<IPropriedade[]>(
      `/vegetal/propriedades/municipio/${idMunicipio}`
    );
    return data;
  },

  byCpfCnpj: async (cpfCnpj: string) => {
    const { data } = await api.get<IPropriedade[]>(
      `/vegetal/propriedades/produtor/${cpfCnpj}`
    );

    return data;
  },
  byId: async (id: number) => {
    const { data } = await api.get<IPropriedade>(`/propriedades/${id}`);
    return data;
  },
};
