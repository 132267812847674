import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { Form, FormInstance, Radio } from "antd";
import styles from "../../style.module.css";

export function Alternativa({
  pergunta,
  perguntasForm,
}: {
  pergunta: IPerguntaAtividade;
  perguntasForm: FormInstance<any>;
}) {
  return (
    <Form.Item
      label="Alternativa"
      labelCol={{
        span: 0,
        offset: 0,
        className: styles["label-alternativa"],
      }}
      style={{ marginBottom: 0 }}
    >
      <Radio.Group
        value={perguntasForm.getFieldValue(
          `listaPerguntas.${pergunta.pergunta.id}.respostaAlternativa`
        )}
      >
        {pergunta.pergunta.alternativas.map((alt) => {
          if (alt.ativo) {
            return (
              <Radio value={alt.id} key={alt.id}>
                {alt.nome}
              </Radio>
            );
          }

          return null;
        })}
      </Radio.Group>
    </Form.Item>
  );
}
