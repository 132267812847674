import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import { Form, Radio } from "antd";

export function Alternativa({
  perg,
  termo,
}: {
  perg: IPerguntaTermoVistoria;
  termo: ITermoVistoria;
}) {
  return (
    <>
      <Form.Item label="Alternativa" labelCol={{ flex: "100px", span: 20 }}>
        <Radio.Group
          value={
            termo.respostas.find((res) => res.pergunta.id === perg.pergunta.id)
              ?.alternativas[0]?.alternativa.id
          }
        >
          {perg.pergunta.alternativas.map((alt, i) => {
            if (alt.ativo) {
              return (
                <Radio value={alt.id} key={i}>
                  {alt.nome}
                </Radio>
              );
            }

            return null;
          })}
        </Radio.Group>
      </Form.Item>
    </>
  );
}
