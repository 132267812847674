import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";

export function MultiplaEscolha({
  pergunta,
  termo,
}: {
  pergunta: IPerguntaTermoVistoria;
  termo: ITermoVistoria;
}) {
  return (
    <>
      {" "}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: 50,
          paddingBottom: 10,
        }}
      >
        {pergunta.pergunta.alternativas.map((alternativa) => {
          const resposta = termo.respostas.find(
            (res) => res.pergunta.id === pergunta.pergunta.id
          );

          return (
            <div
              key={alternativa.id}
              style={{
                display: "flex",
                gap: 5,
              }}
            >
              <input
                type="checkbox"
                readOnly
                value={alternativa.id}
                checked={Boolean(
                  resposta?.alternativas.find(
                    (alt) => alt.alternativa.id === alternativa.id
                  )
                )}
              />
              <label>{alternativa.nome}</label>
            </div>
          );
        })}
      </div>
    </>
  );
}
