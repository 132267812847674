import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { DatePicker, Form, FormInstance, Input } from "antd";
import styles from "../../style.module.css";

export function Campo({
  question,
  form,
}: {
  question: IPerguntaAtividade;
  form: FormInstance;
}) {
  return (
    <>
      {question.pergunta.campos.map((campo, ind) => {
        const name = `listaPerguntas.${question.pergunta.id}.campos.${ind}.resposta`;
        return (
          <div
            key={ind}
            style={{
              display: "flex",
              gap: 5,
              flexDirection: "column",
            }}
          >
            <label className={styles["label-campo"]}>{campo.nome}</label>
            {campo.formatoResposta === "DATE" && (
              <Form.Item
                name={name}
                rules={[
                  { required: true, message: "Por favor, insira uma resposta" },
                ]}
                noStyle={false}
              >
                <DatePicker format={"DD/MM/YYYY"} />
              </Form.Item>
            )}

            {campo.formatoResposta === "NUMBER" && (
              <Form.Item
                name={name}
                rules={[
                  { required: true, message: "Por favor, insira uma resposta" },
                ]}
                noStyle={false}
              >
                <Input
                  style={{ maxWidth: 400 }}
                  onChange={(e) =>
                    form.setFieldValue(name, e.target.value.replace(/\D/g, ""))
                  }
                />
              </Form.Item>
            )}

            {campo.formatoResposta === "TEXTO" && (
              <Form.Item
                name={name}
                rules={[
                  { required: true, message: "Por favor, insira uma resposta" },
                ]}
                noStyle={false}
              >
                <Input style={{ maxWidth: 400 }} />
              </Form.Item>
            )}
          </div>
        );
      })}
    </>
  );
}
