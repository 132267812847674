import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { Form, Radio, RadioChangeEvent } from "antd";
import styles from "../../style.module.css";

export function Alternativa({
  question,
  onChangeAnswer,
}: {
  question: IPerguntaAtividade;
  onChangeAnswer: (
    value: RadioChangeEvent,
    question: IPerguntaAtividade
  ) => void;
}) {
  return (
    <Form.Item
      rules={[{ required: true, message: "Por favor, insira uma resposta" }]}
      style={{ marginBottom: 0 }}
      label="Alternativa"
      name={`listaPerguntas.${question.pergunta.id}.respostaAlternativa`}
      labelCol={{
        className: styles["label-alternativa"],
        span: 0,
        offset: 0,
      }}
    >
      <Radio.Group onChange={(e) => onChangeAnswer(e, question)}>
        {question.pergunta.alternativas.map((alt) => {
          if (alt.ativo) {
            return (
              <Radio value={alt.id} key={alt.id}>
                {alt.nome}
              </Radio>
            );
          }

          return null;
        })}
      </Radio.Group>
    </Form.Item>
  );
}
