import { LoadingOutlined } from "@ant-design/icons";
import { apiService } from "@services/api";
import { Checkbox, Divider, Form, Result, Spin, Typography } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { FiltroLASGAL } from "./components/FiltroLasgal";
import { ModalRecusaLASGAL } from "./components/ModalRecusaLasgal";
import { TabelaLASGAL } from "./components/TabelaLASGAL";
import styles from "./style.module.css";

export function ListagemLasgal() {
  const [formFiltro] = Form.useForm();

  const [pageControl, setPageControl] = useState(1);
  const [applyFilter, setApplyFilter] = useState<CheckboxValueType[]>([]);
  const [amostraSelected, setAmostraSelected] = useState<number>();

  const { data, status, mutate, variables } = useMutation({
    mutationKey: ["AMOSTRAS_SEMENTE"],
    mutationFn: (params: string) =>
      apiService.amostraSemente.buscarAmostra(params),
    onSuccess: (data, variables) => {
      const params = [];

      if (variables.includes("municipioColeta")) {
        params.push("municipioColeta");
      }

      if (variables.includes("numeroAmostraLasgal")) {
        params.push("numeroAmostraLasgal");
      }

      if (variables.includes("numeroTermoColeta")) {
        params.push("numeroTermoColeta");
      }
      setApplyFilter(params);
    },
  });

  const handleChangePagination = (page: number) => {
    setPageControl(page);

    const params = new URLSearchParams(variables);

    if (!params.get("size")) {
      params.append("size", "20");
    }

    if (!params.get("destinoAmostra")) {
      params.append("destinoAmostra", "0");
    }

    params.delete("page");
    params.append("page", (page - 1).toString());

    mutate(params.toString());
  };

  const handleChangeFilter = (value: CheckboxValueType[]) => {
    if (!variables) return;

    let params = variables.split("&");
    if (!value.includes("municipioColeta")) {
      params = params.filter((param) => !param.includes("municipioColeta"));
    }

    if (!value.includes("numeroAmostraLasgal")) {
      params = params.filter((param) => !param.includes("numeroAmostraLasgal"));
    }

    if (!value.includes("numeroTermoColeta")) {
      params = params.filter((param) => !param.includes("numeroTermoColeta"));
    }

    handleChangePagination(1);
    setApplyFilter(value);
    mutate(params.join("&"));
  };

  useEffect(() => {
    document.title = "Lista de amostras LASGAL | SISDEV-mobile";

    if (localStorage.getItem("LASGAL:params")) {
      const params = localStorage.getItem("LASGAL:params") || "";
      mutate(params);
      localStorage.removeItem("LASGAL:params");
      return;
    }

    if (localStorage.getItem("LASGAL:page")) {
      const page = parseInt(localStorage.getItem("LASGAL:page") || "");
      handleChangePagination(page);
      localStorage.removeItem("LASGAL:page");
      return;
    }

    handleChangePagination(1);

    // eslint-disable-next-line
  }, []);

  if (status === "error") {
    return (
      <>
        <Typography.Title level={2} style={{ textAlign: "center" }}>
          Lista de amostra(s) LASGAL
        </Typography.Title>
        <Divider />
        <Result
          status="error"
          title="Erro ao visualizar amostras LASGAL"
          subTitle="Por favor, verifique a conexão com a internet, se o erro persistir entre em contato com o suporte."
        />
      </>
    );
  } else if (status === "idle" || status === "loading") {
    return (
      <>
        <Typography.Title level={2} style={{ textAlign: "center" }}>
          Lista de amostra(s) LASGAL
        </Typography.Title>
        <Divider />
        <div className={styles["container-loading"]}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Typography.Title level={2} style={{ textAlign: "center" }}>
          Lista de amostra(s) LASGAL
        </Typography.Title>

        <FiltroLASGAL
          form={formFiltro}
          mutate={mutate}
          variables={variables}
          setPageControl={setPageControl}
        />

        <Divider />

        {applyFilter.length > 0 && (
          <div className={styles["container-filtro"]}>
            <Typography>Filtro Aplicado:</Typography>
            <Checkbox.Group value={applyFilter} onChange={handleChangeFilter}>
              {variables?.includes("municipioColeta") && (
                <Checkbox value="municipioColeta">Municipio</Checkbox>
              )}

              {variables?.includes("numeroAmostraLasgal") && (
                <Checkbox value="numeroAmostraLasgal">
                  Nº amostra LASGAL
                </Checkbox>
              )}

              {variables?.includes("numeroTermoColeta") && (
                <Checkbox value="numeroTermoColeta">
                  Nº Termo de coleta
                </Checkbox>
              )}
            </Checkbox.Group>
          </div>
        )}

        <TabelaLASGAL
          sampleList={data}
          amostraIdSelected={amostraSelected}
          pageControl={pageControl}
          variables={variables}
          handleRefuseSample={(sampleId: number | undefined) => {
            setAmostraSelected(sampleId);
          }}
          handleChangePagination={handleChangePagination}
        />

        <ModalRecusaLASGAL
          isOpen={!!amostraSelected}
          handleUpdateList={() => mutate(variables || "")}
          handleBack={() => setAmostraSelected(undefined)}
          amostraId={amostraSelected}
        />
      </>
    );
  }
}
