import { IPessoaAutorizadaAdquirirAgrotoxico } from "@interfaces/IPessoaAutorizadaAdquiriAgrotoxico";
import { createAction } from "@reduxjs/toolkit";

const syncPessoaAutorizadaAdquirirAgrotoxico = createAction(
  "PESSOA_AUTORIZADA_ADQUIRIR_AGROTOXICO/syncPessoaAutorizadaAdquirirAgrotoxico",
  (
    pessoaAutorizada: IPessoaAutorizadaAdquirirAgrotoxico[],
    persistedAt: number
  ) => ({
    payload: { pessoaAutorizada, persistedAt },
  })
);

const removeSyncPessoaAutorizadaAdquirirAgrotoxico = createAction(
  "PESSOA_AUTORIZADA_ADQUIRIR_AGROTOXICO/removeSyncPessoaAutorizadaAdquirirAgrotoxico",
  () => ({ payload: {} })
);

export const pessoaAutorizadaAdquirirAgrotoxicoAction = {
  syncPessoaAutorizadaAdquirirAgrotoxico,
  removeSyncPessoaAutorizadaAdquirirAgrotoxico,
};
