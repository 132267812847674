import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { IPerguntaImpressaoTIF } from "@interfaces/IPerguntaImpressaoTIF";

export function Alternativa({
  atv,
  pergunta,
}: {
  atv: IPerguntaImpressaoTIF;
  pergunta: IPerguntaAtividade;
}) {
  return (
    <>
      <div
        style={{
          borderBottom: " 1px solid rgba(0,0,0,0.5)",
          fontSize: 12,
        }}
      >
        Resposta:{" "}
        {
          atv.respostas.find(
            (resp) => resp.pergunta.id === pergunta.pergunta.id
          )?.alternativas[0]?.alternativa.nome
        }
      </div>
    </>
  );
}
