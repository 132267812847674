import { IAmostraDadosLasgal } from "@interfaces/IAmostraDadosLasgal";
import { IListagemAmostra } from "@interfaces/IListagemAmostra";
import { IRecebimentoAmostraLasgal } from "@interfaces/IRecebimentoAmostraLasgal";
import { api } from "@services/api";

export const httpAmostraSemente = {
  byId: async (id: number) => {
    const { data } = await api.get<IAmostraDadosLasgal>(
      `/amostras-sementes/${id}`
    );
    return data;
  },
  buscarAmostra: async (params: string) => {
    const { data } = await api.get<IListagemAmostra>(
      `/amostras-sementes?${params}`
    );
    return data;
  },

  receberAmostraLasgal: async (
    amostraId: number,
    amostra: IRecebimentoAmostraLasgal
  ) => {
    const { data } = await api.put(
      `/amostras-sementes/${amostraId}/recebimento-lasgal`,
      amostra
    );
    return data;
  },

  recusaAmostraLasgal: async (
    amostraId: number,
    motivo: {
      descricaoRecusa: string;
      motivoRecusaAmostra: { id: number; nome: string };
    }
  ) => {
    const { data } = await api.put(
      `/amostras-sementes/${amostraId}/recusa-lasgal`,
      motivo
    );
    return data;
  },

  motivosRecusaAmostraLasgal: async () => {
    const { data } = await api.get<{ id: number; nome: string }[]>(
      "/motivos-recusa-amostra"
    );
    return data;
  },
};
