import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import { DatePicker, Form, Input } from "antd";
import moment from "moment";
import styles from "../../style.module.css";

export function Descritiva({
  pergunta,
  termo,
}: {
  pergunta: IPerguntaTermoVistoria;
  termo: ITermoVistoria;
}) {
  return (
    <>
      <div
        style={{
          display: "flex",
          gap: 5,
          flexDirection: "column",
        }}
      >
        <label className={styles["label-campo-vistoria"]}>ESPECIFICAR:</label>
        {pergunta.pergunta.formatoResposta === "DATE" && (
          <Form.Item noStyle={true}>
            <DatePicker
              style={{ maxWidth: 400 }}
              value={moment(
                termo.respostas.find(
                  (res) => res.pergunta.id === pergunta.pergunta.id
                )?.descricao
              )}
            />
          </Form.Item>
        )}

        {(pergunta.pergunta.formatoResposta === "TEXTO" ||
          pergunta.pergunta.formatoResposta === "NUMBER") && (
          <Form.Item noStyle={true}>
            <Input
              style={{ maxWidth: 400 }}
              value={
                termo.respostas.find(
                  (res) => res.pergunta.id === pergunta.pergunta.id
                )?.descricao
              }
            />
          </Form.Item>
        )}
      </div>
    </>
  );
}
