import { IPerfil } from "@interfaces/IPerfil";
import { api } from "@services/api";

export const httpPerfil = {
  buscar: async () => {
    const { data } = await api.get<IPerfil[]>("/vegetal/perfis");
    return data;
  },

  adicionar: async (perfil: any) => {
    const { data } = await api.post<IPerfil>("/vegetal/perfis", perfil);

    return data;
  },

  editar: async (idPerfil: number, perfil: any) => {
    const { data } = await api.put<IPerfil>(
      `/vegetal/perfis/${idPerfil}`,
      perfil
    );

    return data;
  },
  inativar: async (perfilId: number) => {
    await api.patch(`/vegetal/perfis/${perfilId}/inativar`);
    return;
  },
};
