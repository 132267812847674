import { apiService } from "@services/api";
import { Input, message, Modal } from "antd";
import { AxiosError } from "axios";
import { useState } from "react";
import { openNotification } from "../Notifications";

interface IModalTermoVistoriaProps {
  isOpen: boolean;
  numTermo: string;
  titleTermo: string;
  idTermo: number | undefined;
  onRemoveTermo: (numero: string) => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reloadFilter: () => Promise<void>;
  isFilterApplied?: boolean;
  apiName:
    | "termoVistoria"
    | "tif"
    | "termoColeta"
    | "termoApreensaoLiberacao"
    | "tid";
}

export function ModalCancelarTermo({
  isOpen,
  setIsOpen,
  reloadFilter,
  isFilterApplied,
  idTermo,
  numTermo,
  onRemoveTermo,
  titleTermo,
  apiName,
}: IModalTermoVistoriaProps) {
  const [motivoCancelamento, setMotivoCancelamento] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleReset = () => {
    setIsOpen(false);
    setMotivoCancelamento("");
    // setTermoSelected(null);
  };

  const handleSubmit = async () => {
    const keyMessage = "cancel-termo";

    if (!idTermo) return;

    if (!motivoCancelamento) {
      openNotification({
        type: "info",
        message: `Não foi possivel cancelar ${titleTermo}.`,
        description: "Preencha o motivo do cancelamento.",
      });
      return;
    }

    const params = new URLSearchParams();
    params.append("motivoCancelamento", `${motivoCancelamento.trim()}`);

    message.loading({
      key: keyMessage,
      duration: 0,
      content: `Cancelando ${titleTermo}...`,
    });
    setIsLoading(true);

    try {
      await apiService[apiName].remover(idTermo, params.toString());

      //VERIFICA SE O FILTRO ESTA APLICADO
      if (isFilterApplied) {
        await reloadFilter();
      }
      onRemoveTermo(numTermo);

      message.destroy(keyMessage);
      setIsLoading(false);
      openNotification({
        type: "success",
        message: `${titleTermo} ${numTermo} cancelado com sucesso!`,
      });

      handleReset();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      message.destroy(keyMessage);

      if (error instanceof AxiosError) {
        openNotification({
          type: "error",
          message: `Não foi possivel cancelar ${titleTermo}`,
          description: `${error.response?.status}: ${error.response?.data.title}.`,
        });
        return;
      }

      openNotification({
        type: "error",
        message: `Não foi possivel cancelar ${titleTermo}.`,
        description: "Erro desconhecido.",
      });
    }
  };

  return (
    <>
      <Modal
        title={
          <h1
            style={{
              fontSize: 18,
              textAlign: "center",
              marginBottom: 0,
              padding: "0px 15px",
            }}
          >
            Tem certeza que deseja cancelar o {titleTermo} {numTermo}?
          </h1>
        }
        open={isOpen}
        onCancel={handleReset}
        afterClose={handleReset}
        onOk={handleSubmit}
        okText="Sim"
        cancelText="Não"
        okButtonProps={{
          disabled: isLoading,
        }}
        cancelButtonProps={{
          danger: true,
          disabled: isLoading,
        }}
      >
        <div>
          <span className="title-description-cancelamento">
            Descreva o motivo para o cancelamento do {titleTermo}.
          </span>
          <Input.TextArea
            maxLength={100}
            showCount
            value={motivoCancelamento}
            rows={4}
            onChange={(e) => {
              setMotivoCancelamento(e.target.value);
            }}
          />
        </div>
      </Modal>
    </>
  );
}
