import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { memo } from "react";
import { useQuery } from "react-query";
import { openNotification } from "../../../../../components/Notifications";
import { getPayloadUser } from "../../../../../functions/getPayloadUser";
import { ITabelaTesteLasgal } from "../../../../../interfaces/ITabelaTesteLasgal";
import { ITabelaTesteLasgalPureza } from "../../../../../interfaces/ITabelaTesteLasgalPureza";
import { apiService } from "../../../../../services/api";
import styles from "./style.module.css";

interface IPurezaProps {
  setDataTable: React.Dispatch<React.SetStateAction<ITabelaTesteLasgal[]>>;
  dataTable: ITabelaTesteLasgal[];
  setDataPureza: React.Dispatch<
    React.SetStateAction<ITabelaTesteLasgalPureza[]>
  >;
  setHasPureza: React.Dispatch<React.SetStateAction<boolean>>;
  dataPureza: ITabelaTesteLasgalPureza[];
  form: FormInstance<any>;
}

function Pureza({
  setDataTable,
  dataTable,
  setDataPureza,
  setHasPureza,
  dataPureza,
  form,
}: IPurezaProps) {
  const listaMaterialInerte = useQuery({
    queryKey: "LISTA_MATERIAL_INERTE",
    queryFn: () => apiService.materialInerteBoletim(),
  });

  const columns: ColumnsType<any> = [
    {
      title: "Data",
      dataIndex: "data",
      align: "center",
    },
    {
      title: "Validade",
      dataIndex: "validade",
      align: "center",
    },
    {
      title: "Pureza(%)",
      dataIndex: "pureza",
      align: "center",
    },
    {
      title: "M. Inerte(%)",
      dataIndex: "materialInerte",
      align: "center",
    },
    {
      title: "Outras sementes(%)",
      dataIndex: "outraSementes",
      align: "center",
    },
    {
      title: "Operador",
      dataIndex: "operador",
      align: "center",
    },
    {
      title: "Ações",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Remover">
            <Button danger onClick={() => removerAnalise()}>
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const removerAnalise = () => {
    form.resetFields();
    setDataPureza([]);
    setDataTable([
      dataTable[0],
      {
        ...dataTable[1],
        pureza: "",
        materialInerte: "",
        outraSementes: "",
      },
    ]);
  };

  const sumPesoFinal = () => {
    const values = {
      sementePura: parseFloat(
        form.getFieldValue("sementePura")?.replaceAll(",", ".") || 0
      ),
      outraSementes: parseFloat(
        form.getFieldValue("outraSementes")?.replaceAll(",", ".") || 0
      ),
      materialInerte: parseFloat(
        form.getFieldValue("materialInerte")?.replaceAll(",", ".") || 0
      ),
    };

    const result =
      values.materialInerte + values.outraSementes + values.sementePura;

    form.setFieldValue(
      "pesoFinal",
      result.toString().replace(".", ",").slice(0, 5)
    );
  };

  const sumImpurezas = () => {
    const values = {
      outraSementes: parseFloat(
        form.getFieldValue("outraSementes")?.replaceAll(",", ".") || 0
      ),
      materialInerte: parseFloat(
        form.getFieldValue("materialInerte")?.replaceAll(",", ".") || 0
      ),
    };

    const result = values.materialInerte + values.outraSementes;

    form.setFieldValue(
      "impurezas",
      result.toString().replace(".", ",").slice(0, 5)
    );
  };

  // SO PODE HAVER UMA CASA DECIMAL APOS A VIRGULA
  // (DETERMINACOES DO CONTRATANTE) = QUANDO TIVER UMA PORCENTAGEM ABAIXO DE 0,05. DEVE APARECER NA TABELA A PALAVRA "TRAÇO"
  const calcularPorcentagem = (value: string, total: number) => {
    const result = (parseFloat(value.replaceAll(",", ".")) * 100) / total;

    if (result < 10 && result > 0.1) {
      return result.toString().replace(".", ",").slice(0, 3);
    }

    if (result < 0.1 && result > 0.05) {
      return "1,0";
    }

    if (result < 0.05) {
      return "Traço";
    }

    return result.toString().replace(".", ",").slice(0, 4);
  };

  const handleSubmit = (values: any) => {
    const somaValores =
      parseFloat(values.sementePura.replaceAll(",", ".")) +
      parseFloat(values.materialInerte.replaceAll(",", ".")) +
      parseFloat(values.outraSementes.replaceAll(",", "."));

    const data = {
      key: "1",
      data: values.dataTeste.format("DD/MM/YYYY"),
      validade: "OK",
      pureza: calcularPorcentagem(values.sementePura, somaValores),
      materialInerte: calcularPorcentagem(values.materialInerte, somaValores),
      outraSementes: calcularPorcentagem(values.outraSementes, somaValores),
      operador:
        getPayloadUser(localStorage.getItem("access_token"))?.given_name || "",
      balancaUsada: parseFloat(values.balancaUsada || ""),
      listaMaterialInerte: values.listaMaterialInerte || [],
    };

    setDataPureza([data]);

    const auxDataTable = [
      dataTable[0],
      {
        ...dataTable[1],
        pureza: data.pureza,
        materialInerte: data.materialInerte,
        outraSementes: data.outraSementes,
      },
    ];

    setDataTable(auxDataTable);
    setHasPureza(true);

    openNotification({
      type: "success",
      message: `Teste de pureza ${
        dataPureza.length > 0 ? "editado" : "adicionado"
      } com sucesso!`,
    });
  };

  return (
    <>
      <h1 className={styles["title-module"]}>PUREZA</h1>

      <Form
        wrapperCol={{ flex: 1, span: 12 }}
        labelAlign="left"
        form={form}
        initialValues={{ dataTeste: moment() }}
        onFinish={handleSubmit}
      >
        <div className={styles["container-balanca"]}>
          <Form.Item
            label="Balança usada"
            name="balancaUsada"
            labelCol={{ flex: "150px", span: 10 }}
            rules={[{ required: true }]}
          >
            <Input style={{ minWidth: 150 }} placeholder="-N-" />
          </Form.Item>

          <Form.Item
            label="Data do teste"
            name="dataTeste"
            labelCol={{ flex: "150px", span: 10 }}
            rules={[{ required: true }]}
          >
            <DatePicker style={{ minWidth: 150 }} format={"DD/MM/YYYY"} />
          </Form.Item>
        </div>

        <Typography.Title level={4}>Amostra Trabalho</Typography.Title>

        <div className={styles["container-dados-amostra"]}>
          <div>
            <Form.Item
              label="Peso Inicial"
              name="pesoInicial"
              labelCol={{ flex: "100px", span: 10 }}
              rules={[{ required: true }]}
            >
              <Input
                maxLength={5}
                placeholder="-N-"
                style={{ minWidth: 150 }}
                onChange={(e) => {
                  const { value } = e.target;

                  form.setFieldValue(
                    "pesoInicial",
                    value.replace(/[^0-9,]/g, "")
                  );
                }}
              />
            </Form.Item>

            <Form.Item
              label="Peso Final"
              name="pesoFinal"
              labelCol={{ flex: "100px", span: 10 }}
              rules={[{ required: true }]}
            >
              <Input style={{ minWidth: 150 }} disabled placeholder="-N-" />
            </Form.Item>

            <Form.Item
              label="Impurezas"
              name="impurezas"
              labelCol={{ flex: "100px", span: 10 }}
              rules={[{ required: true }]}
            >
              <Input style={{ minWidth: 150 }} disabled placeholder="-N-" />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              label="Semente Pura"
              name="sementePura"
              labelCol={{ flex: "150px", span: 10 }}
              rules={[{ required: true }]}
            >
              <Input
                style={{ minWidth: 150 }}
                placeholder="-N-"
                maxLength={5}
                onChange={(e) => {
                  const { value } = e.target;

                  form.setFieldValue(
                    "sementePura",
                    value.replace(/[^0-9,]/g, "")
                  );

                  sumPesoFinal();
                }}
              />
            </Form.Item>

            <Form.Item
              label="Outras sementes"
              name="outraSementes"
              labelCol={{ flex: "150px", span: 10 }}
              rules={[{ required: true }]}
            >
              <Input
                style={{ minWidth: 150 }}
                placeholder="-N-"
                maxLength={5}
                onChange={(e) => {
                  const { value } = e.target;

                  form.setFieldValue(
                    "outraSementes",
                    value.replace(/[^0-9,]/g, "")
                  );

                  sumPesoFinal();
                  sumImpurezas();
                }}
              />
            </Form.Item>

            <Form.Item
              label="Material Inerte"
              name="materialInerte"
              labelCol={{ flex: "150px", span: 10 }}
              rules={[{ required: true }]}
            >
              <Input
                style={{ minWidth: 150 }}
                placeholder="-N-"
                onChange={(e) => {
                  const { value } = e.target;

                  form.setFieldValue(
                    "materialInerte",
                    value.replace(/[^0-9,]/g, "")
                  );

                  sumPesoFinal();
                  sumImpurezas();
                }}
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item
          label="Lista M. Inerte"
          name="listaMaterialInerte"
          labelCol={{ flex: "120px", span: 8 }}
        >
          <Select
            mode="multiple"
            options={listaMaterialInerte.data?.map((material) => {
              return {
                value: material.id,
                label: material.nome,
              };
            })}
          />
        </Form.Item>

        <div className={styles["container-button-add"]}>
          <Button type="primary" htmlType="submit">
            {dataPureza.length > 0 ? "Editar Teste" : "Adicionar Teste"}
          </Button>
        </div>
      </Form>

      <Table
        size="small"
        columns={columns}
        dataSource={dataPureza}
        pagination={false}
        bordered
        style={{ overflowX: "auto" }}
      />
    </>
  );
}

export default memo(Pureza);
