import { openNotification } from "@components/Notifications";
import { masks } from "@functions/mascaras";
import { useMunicipio } from "@hooks/useMunicipio";
import { IAssinante } from "@interfaces/IAssinante";
import { IFormValuesTermoApreensaoLiberacao } from "@interfaces/IFormValuesTermoApreensaoLiberacao";
import { DatePicker, Form, FormInstance, Input, Typography } from "antd";
import { AxiosError } from "axios";
import moment from "moment";
import { Fragment, useState } from "react";
import { useTif } from "../../../tif/hooks/useTif";

interface IFiscalizadoRegistroTermoApreensaoLiberacaoProps {
  form: FormInstance<IFormValuesTermoApreensaoLiberacao>;
  isTifExisist: boolean;
  setIsTifExisist: React.Dispatch<React.SetStateAction<boolean>>;
  setFiscalizadoSignature: React.Dispatch<React.SetStateAction<IAssinante>>;
  setTifSelected: React.Dispatch<any>;
  isLiberacaoFound: boolean;
}

export function FiscalizadoRegistroTermoApreensaoLiberacao({
  form,
  isTifExisist,
  setIsTifExisist,
  setFiscalizadoSignature,
  setTifSelected,
  isLiberacaoFound,
}: IFiscalizadoRegistroTermoApreensaoLiberacaoProps) {
  const { municipiosBrasil, listaUfs } = useMunicipio();
  const { getTifByNumero } = useTif();
  const { cepMask, cpfCnpjMask, telefoneMask } = masks;

  const [isLoading, setIsLoading] = useState(false);

  const handleSearchTIF = async (numTIF: string) => {
    if (!numTIF) {
      openNotification({
        type: "info",
        message: "Não foi possivel Buscar TIF",
        description: "Digite um número válido do TIF",
      });

      return;
    }

    if (!form.getFieldValue("termo")) {
      openNotification({
        type: "info",
        message: "Não foi possivel Buscar TIF",
        description:
          "Preencha se o termo é de Apreensão ou Liberação para prosseguir.",
      });

      return;
    }
    try {
      setIsLoading(true);
      const tif = await getTifByNumero(numTIF.trim());
      setIsLoading(false);

      if (!tif) {
        openNotification({
          type: "info",
          message: "Não foi possivel encontrar TIF",
          description:
            "TIF não foi encontrado na base de dados, favor verificar se o número foi preenchido corretamente.",
        });
        return;
      }

      if (!tif.statusAssinatura) {
        setIsTifExisist(false);
        openNotification({
          type: "info",
          message: "TIF encontrado porém...",
          description:
            "Não foi possivel utilizar esse TIF informado, pois ele possivelmente foi criado no antigo Sisdev-mobile",
        });
        return;
      }

      if (tif && !tif.ativo && navigator.onLine) {
        openNotification({
          type: "info",
          message: "TIF não está ativo",
        });
        return;
      }

      setIsTifExisist(true);
      setTifSelected(tif);
      setFiscalizadoSignature({
        assinatura: null,
        cpfAssinante: cpfCnpjMask(tif.cpfCnpjFiscalizado),
        funcaoAssinante: tif.funcaoAssinante,
        nomeAssinante: tif.nomeFiscalizado,
      });

      form.setFieldsValue({
        nomeFiscalizado: tif.nomeFiscalizado,
        cpfCnpjFiscalizado: cpfCnpjMask(tif.cpfCnpjFiscalizado),
        dataTIF: moment(tif.dataFiscalizacao),
        ufFiscalizadoId: tif.municipioFiscalizado?.uf.id,
        municipioFiscalizadoId: tif.municipioFiscalizado?.id,
        enderecoFiscalizado: tif.enderecoFiscalizado,
        emailFiscalizado: tif.emailFiscalizado,
        cepFiscalizado: cepMask(tif.cepFiscalizado),
        telefoneFiscalizado: telefoneMask(tif.telefoneFiscalizado),
        municipioFiscalizacaoId: tif.municipioFiscalizacao?.id,
        renasemFiscalizado: tif.renasemFiscalizado || "",
        rgFiscalizado: tif.inscricaoEstadualFiscalizado || "",
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      if (error instanceof AxiosError) {
        openNotification({
          type: "error",
          message: "Não foi possivel encontrar TIF",
          description: `Erro ${error.response?.status}: ${error.response?.data.title}.`,
        });
        return;
      }
      openNotification({
        type: "error",
        message: "Não foi possivel encontrar TIF",
        description: "Erro desconhecido.",
      });
    }
  };

  const handleChangeNumTIF = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isTifExisist) {
      setIsTifExisist(false);

      form.resetFields([
        "nomeFiscalizado",
        "cpfCnpjFiscalizado",
        "ufFiscalizadoId",
        "rgFiscalizado",
        "renasemFiscalizado",
        "municipioFiscalizadoId",
        "municipioFiscalizacaoId",
        "enderecoFiscalizado",
        "emailFiscalizado",
        "cepFiscalizado",
        "telefoneFiscalizado",
        "dataTIF",
        "providencias",
        "amparoLegal",
      ]);
    }
  };

  return (
    <Fragment>
      <Typography.Title level={3}>Fiscalizado</Typography.Title>
      <Form.Item label="N° do TIF" name="numTIF" rules={[{ required: true }]}>
        <Input.Search
          enterButton="Buscar"
          placeholder="Digite o N° do TIF"
          onSearch={handleSearchTIF}
          loading={isLoading}
          onChange={handleChangeNumTIF}
          disabled={isLiberacaoFound}
        />
      </Form.Item>

      <Form.Item label="Data do termo" name="dataTIF">
        <DatePicker disabled id="dataTIF" format={"DD/MM/YYYY"} />
      </Form.Item>

      <Form.Item label="Nome do Fiscalizado" name="nomeFiscalizado">
        <Input id="nomeFiscalizado" disabled />
      </Form.Item>

      <Form.Item label="CPF/CNPJ do Fiscalizado" name="cpfCnpjFiscalizado">
        <Input id="cpfCnpjFiscalizado" disabled />
      </Form.Item>

      <Form.Item label="UF do Fiscalizado">
        <Input
          disabled
          value={
            listaUfs.find(
              (uf) => uf.id === form.getFieldValue("ufFiscalizadoId")
            )?.sigla || ""
          }
        />
      </Form.Item>

      <Form.Item label="Municipio do Fiscalizado">
        <Input
          disabled
          value={
            municipiosBrasil.find(
              (mun) => mun.id === form.getFieldValue("municipioFiscalizadoId")
            )?.nome || ""
          }
        />
      </Form.Item>

      <Form.Item label="RG do Fiscalizado" name="rgFiscalizado">
        <Input id="rgFiscalizado" disabled />
      </Form.Item>

      <Form.Item label="RENASEM do Fiscalizado" name="renasemFiscalizado">
        <Input id="renasemFiscalizado" disabled />
      </Form.Item>

      <Form.Item label="Endereço do Fiscalizado" name="enderecoFiscalizado">
        <Input id="enderecoFiscalizado" disabled />
      </Form.Item>

      <Form.Item label="CEP do Fiscalizado" name="cepFiscalizado">
        <Input id="cepFiscalizado" disabled />
      </Form.Item>

      <Form.Item label="Telefone do Fiscalizado" name="telefoneFiscalizado">
        <Input id="telefoneFiscalizado" disabled />
      </Form.Item>

      <Form.Item label="Email do Fiscalizado" name="emailFiscalizado">
        <Input id="emailFiscalizado" disabled />
      </Form.Item>
    </Fragment>
  );
}
