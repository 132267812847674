import { api } from "@services/api";

interface ResponseGetMaterialInerte {
  ativo: boolean;
  id: number;
  nome: string;
}

export const getMateriaisInertes = async () => {
  const { data } =
    await api.get<ResponseGetMaterialInerte[]>("materiais-inertes");
  return data;
};
