import { IBoletimAmostra } from "@interfaces/IBoletimAmostra";
import { api } from "@services/api";

export const httpBoletimAmostra = {
  enviar: async (boletim: IBoletimAmostra) => {
    const { data } = await api.post("boletins-amostras", boletim);
    return data;
  },
  byAmostraId: async (amostraId: number) => {
    const { data } = await api.get<IBoletimAmostra>(
      `boletins-amostras/amostra/${amostraId}`
    );
    return data;
  },
  enviarAssinatura: async (assinatura: {
    usuario: string;
    senha: string;
    boletimAmostraId: number;
  }) => {
    const params = new URLSearchParams();
    params.append("usuario", assinatura.usuario);
    params.append("senha", assinatura.senha);

    const response = await api.post(
      `/sisdev-api-hom/boletins-amostras/${
        assinatura.boletimAmostraId
      }/assinatura?${params.toString()}`
    );

    return response;
  },
};
