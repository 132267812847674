import { IPessoaAutorizadaAdquirirAgrotoxico } from "@interfaces/IPessoaAutorizadaAdquiriAgrotoxico";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IPessoaAutorizadaAdquirirAgrotoxicoSlice {
  items: IPessoaAutorizadaAdquirirAgrotoxico[];
  persistedAt: number;
}

const initialState: IPessoaAutorizadaAdquirirAgrotoxicoSlice = {
  items: [],
  persistedAt: 0,
};

const pessoaAutorizadaAdquirirAgrotoxicoSlice = createSlice({
  name: "PESSOA_AUTORIZADA_ADQUIRIR_AGROTOXICO",
  initialState,
  reducers: {
    syncPessoaAutorizadaAdquirirAgrotoxico(state, action) {
      const { pessoaAutorizada, persistedAt } = action.payload;
      state.items = pessoaAutorizada;
      state.persistedAt = persistedAt;
    },
    removeSyncPessoaAutorizadaAdquirirAgrotoxico: (state, action) => {
      state.items = [];
      state.persistedAt = 0;
    },
  },
});

export default pessoaAutorizadaAdquirirAgrotoxicoSlice.reducer;

export const getPessoaAutorizadaAdquirirAgrotoxico = (state: RootState) =>
  state.pessoaAutorizadaAdquirirAgrotoxico.items;
