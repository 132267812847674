import { Form, Input, InputRef } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { ITabelaTesteLasgal } from "../../../../../../../interfaces/ITabelaTesteLasgal";
import { TabelaTesteLasgalContext } from "../TabelaTesteLasgalContext";
import styles from "./style.module.css";

interface EdicaoCelulaTabelaTesteLasgalProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof ITabelaTesteLasgal;
  record: ITabelaTesteLasgal;
  handleSave: (record: ITabelaTesteLasgal) => void;
}

export function EdicaoCelulaTabelaTesteLasgal({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}: EdicaoCelulaTabelaTesteLasgalProps) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(TabelaTesteLasgalContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();

      if (form.getFieldValue(dataIndex) === "-N-") {
        form.setFieldValue(dataIndex, "");
      }
    }

    //eslint-disable-next-line
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();

      if (!values[dataIndex]) {
        handleSave({ ...record, [dataIndex]: "-N-" });
        return;
      }

      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (record?.key === "0") {
    return <td {...restProps}>{children}</td>;
  }

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <Input
          style={{ minWidth: 50 }}
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          maxLength={5}
          placeholder={"%"}
          onChange={(e) => {
            const { value } = e.target;
            form.setFieldValue(dataIndex, value.replace(/[^0-9,]/g, ""));
          }}
        />
      </Form.Item>
    ) : (
      <div
        className={styles["editable-cell-value-wrap"]}
        onClick={toggleEdit}
        placeholder="-N-"
        contentEditable={true}
        suppressContentEditableWarning={true}
        style={{ minWidth: 50 }}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
}
