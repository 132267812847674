import { openNotification } from "@components/Notifications";
import { TIPO_IMAGENS } from "@constants/TIPO_IMAGENS";
import { getAnexosFormatadosBase64 } from "@functions/getAnexosFormatadosBase64";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { transformStringToNumber } from "@functions/transformStringToNumber";
import { useDadosTif } from "@hooks/useDadosTif";
import { useEstabelecimento } from "@hooks/useEstabelecimento";
import { useMunicipio } from "@hooks/useMunicipio";
import { usePessoaAutorizadaAdquirirAgrotoxico } from "@hooks/usePessoaAutorizadaAdquirirAgrotoxico";
import { usePropriedade } from "@hooks/usePropriedade";
import { useServidores } from "@hooks/useServidores";
import { useUle } from "@hooks/useUle";
import { IAnexos } from "@interfaces/IAnexos";
import { IAssinante } from "@interfaces/IAssinante";
import { IFormAtividades } from "@interfaces/IFormAtividades";
import { IFormValuesTif } from "@interfaces/IFormValuesTif";
import { IPerguntaImpressaoTIF } from "@interfaces/IPerguntaImpressaoTIF";
import { IRespostaAtividade } from "@interfaces/IRespostaAtividade";
import { ITif } from "@interfaces/ITif";
import { Button, FormInstance } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { estruturarPerguntasImpressaoTif } from "../../functions/estruturarPerguntasImpressaoTif";
import { ordenarPerguntasAtividade } from "../../functions/ordenarPerguntasAtividade";
import { organizarListaAtividade } from "../../functions/organizarListaAtividade";
import { BodyImpressaoTIF } from "../../listagemTIF/ViewImpressaoTIF/components/BodyImpressaoTIF";
import { FooterImpressaoTIF } from "../../listagemTIF/ViewImpressaoTIF/components/FooterImpressaoTIF";
import { HeaderImpressaoTIF } from "../../listagemTIF/ViewImpressaoTIF/components/HeaderImpressaoTIF";
import { ImagesImpressaoTIF } from "../../listagemTIF/ViewImpressaoTIF/components/ImagesImpressaoTIF";
import { QuestionarioImpressaoTIF } from "../../listagemTIF/ViewImpressaoTIF/components/QuestionarioImpressaoTIF";
import styles from "./style.module.css";

export function PreviaTIF({
  form,
  infoForm,
  motivoNaoAssinaturaFiscalizado,
  anexos,
  dadosAssinaturaFiscalizado,
  estabelecimentoId,
  pessoaAutorizadaId,
}: {
  form: FormInstance<IFormValuesTif>;
  infoForm: FormInstance<{ constatacao?: string; providencias?: string }>;
  motivoNaoAssinaturaFiscalizado: CheckboxValueType[];
  anexos: any[];
  dadosAssinaturaFiscalizado: IAssinante;
  estabelecimentoId?: number;
  pessoaAutorizadaId?: number;
}) {
  const [tifToPreview, setTifToPreview] = useState<ITif | null>(null);
  const [anexosImages, setAnexosImages] = useState<IAnexos[]>([]);
  const [perguntas, setPerguntas] = useState<IPerguntaImpressaoTIF[]>([]);

  const componentRef = useRef(null);
  const { getUle } = useUle();
  const { servidoresSelecionados } = useServidores();
  const { respostasAtividades, atividadesTif } = useDadosTif();
  const { getMunicipioPorId } = useMunicipio();
  const { buscarPropriedadePeloCpfCnpj } = usePropriedade();
  const { buscarEstabelecimentoPeloCnpj } = useEstabelecimento();
  const { getByCnpjPAAA } = usePessoaAutorizadaAdquirirAgrotoxico();

  const handlePreviewTIF = async () => {
    const valuesRegistroTIF = form.getFieldsValue();
    const valuesComplementoTIF = infoForm.getFieldsValue();

    try {
      const cpfCnpjFiscalizado = form
        .getFieldValue("cpfCnpjFiscalizado")
        .replace(/[^\d]+/g, "");

      const ule = getUle(valuesRegistroTIF.municipioFiscalizacaoId);

      const servidoresFormTif = servidoresSelecionados.map((serv) => {
        return {
          assinatura: "",
          servidor: {
            cpfCnpj: serv.servidor.cpfCnpj,
            id: serv.servidor.id,
            inscricaoEstadual: serv.servidor.inscricaoEstadual,
            matricula: serv.servidor.matricula,
            nome: serv.servidor.nome,
          },
        };
      });

      let tifPreview: ITif = {
        respostasAtividades: respostasAtividades,
        statusAssinatura: getStatusAssinatura(motivoNaoAssinaturaFiscalizado),
        amparoLegal: valuesComplementoTIF.providencias || "",
        assinatura: "",
        atividades: organizarListaAtividade(atividadesTif),
        caracteristicaFiscalizado: valuesRegistroTIF.atuado || "PROPRIEDADE",
        cepFiscalizado: valuesRegistroTIF.cepFiscalizado || "",
        cepPropriedade: valuesRegistroTIF.cepPropriedade || "",
        constatacao: valuesComplementoTIF.constatacao || "",
        cpfCnpjFiscalizado: cpfCnpjFiscalizado,
        dataFiscalizacao: moment(valuesRegistroTIF.dataFiscalizar)
          .locale("pt-br")
          .format()
          .slice(0, 10),
        emailFiscalizado: valuesRegistroTIF.emailFiscalizado || "",
        enderecoFiscalizado: valuesRegistroTIF.enderecoFiscalizado || "",
        enderecoPropriedade: valuesRegistroTIF.enderecoPropriedade || "",
        fiscalizacao: valuesRegistroTIF.termo.includes("FISCALIZACAO"),
        fiscalizacaoFronteira:
          valuesRegistroTIF.fiscalizacaoFronteira === "SIM" ? true : false,
        inscricaoEstadualFiscalizado:
          valuesRegistroTIF.inscricaoEstadualFiscalizado || "",
        inspecao: valuesRegistroTIF.termo.includes("INSPECAO"),
        latGrauPropriedade:
          transformStringToNumber(valuesRegistroTIF.latGrauPropriedade || "") ||
          0,
        latMinPropriedade:
          transformStringToNumber(valuesRegistroTIF.latMinPropriedade || "") ||
          0,
        latSegPropriedade:
          transformStringToNumber(valuesRegistroTIF.latSegPropriedade || "") ||
          0,
        longGrauPropriedade:
          transformStringToNumber(
            valuesRegistroTIF.longGrauPropriedade || ""
          ) || 0,
        longMinPropriedade:
          transformStringToNumber(valuesRegistroTIF.longMinPropriedade || "") ||
          0,
        longSegPropriedade:
          transformStringToNumber(valuesRegistroTIF.longSegPropriedade || "") ||
          0,
        municipioFiscalizado: getMunicipioPorId(
          valuesRegistroTIF.municipioFiscalizadoId
        ),
        municipioFiscalizacao: getMunicipioPorId(
          valuesRegistroTIF.municipioFiscalizacaoId
        ),
        nomeArquivo: "MINUTA_TIF",
        nomeFiscalizado: valuesRegistroTIF.nomeFiscalizado,
        nomePropriedade: valuesRegistroTIF.propEstab || "",
        nomeUpload: `MINUTA TIF`,
        notificacao: valuesRegistroTIF.termo.includes("NOTIFICACAO"),
        numero: "",
        orientacaoLatitudePropriedade: "S",
        orientacaoLongitudePropriedade: "O",
        produtor: null,
        propriedade: null,
        renasemFiscalizado: valuesRegistroTIF.renasemFiscalizado || "",
        estabelecimento: null,
        pessoaAutorizadaAdquirirAgrotoxico: null,
        servidores: servidoresFormTif,
        telefoneFiscalizado:
          valuesRegistroTIF.telefoneFiscalizado?.replace(/[^\d]+/g, "") || "",
        tipoRepresentante: valuesRegistroTIF.tipoRepresentante,
        ule: ule,
        anexosTIF: [],
        dataCadastro: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
        nomeAssinante: dadosAssinaturaFiscalizado.nomeAssinante,
        cpfAssinante: dadosAssinaturaFiscalizado.cpfAssinante,
        funcaoAssinante: dadosAssinaturaFiscalizado.funcaoAssinante,
      };

      organizarPerguntasAtividade();

      if (anexos.length > 0) {
        await getAnexosFormatadosBase64(anexos).then((res) => {
          tifPreview = { ...tifPreview, anexosTIF: res };
        });
      }

      if (valuesRegistroTIF.propriedadeId && !valuesRegistroTIF.propEstab) {
        const propriedade = (
          await buscarPropriedadePeloCpfCnpj(cpfCnpjFiscalizado)
        ).find((prop) => prop.id === valuesRegistroTIF.propriedadeId);

        if (propriedade) {
          tifPreview = {
            ...tifPreview,
            nomePropriedade: propriedade.nome,
            propriedade: propriedade,
            produtor: propriedade.produtores[0],
          };
        }
      }

      if (estabelecimentoId) {
        const estabelecimentoSelected = (
          await buscarEstabelecimentoPeloCnpj(cpfCnpjFiscalizado)
        ).find((estab) => estab.id === estabelecimentoId);

        if (estabelecimentoSelected) {
          tifPreview = {
            ...tifPreview,
            nomePropriedade: estabelecimentoSelected.nome,
            estabelecimento: estabelecimentoSelected,
          };
        }
      }

      if (pessoaAutorizadaId) {
        const pessoaAutorizada = await getByCnpjPAAA(cpfCnpjFiscalizado);

        if (pessoaAutorizada) {
          tifPreview = {
            ...tifPreview,
            nomePropriedade: pessoaAutorizada.nome,
            pessoaAutorizadaAdquirirAgrotoxico: pessoaAutorizada,
          };
        }
      }

      // console.log(tifPreview);
      const anexosTypeImage =
        tifPreview?.anexosTIF.filter((anexo) =>
          TIPO_IMAGENS.includes(
            anexo.nomeArquivo.split(".")[
              anexo.nomeArquivo.split(".").length - 1
            ]
          )
        ) || [];

      setAnexosImages(anexosTypeImage);

      setTifToPreview(tifPreview);
    } catch (error) {
      openNotification({
        type: "error",
        message: "Erro ao gerar prévia do TIF",
        description: "Verifique novamente as informações do TIF.",
      });
      console.log(error);
    }
  };

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    documentTitle: `TIF`,
    suppressErrors: true,
  });

  const organizarPerguntasAtividade = () => {
    const auxPerguntas: IPerguntaImpressaoTIF[] = [];

    atividadesTif.forEach((atv) => {
      //FILTRAR PERGUNTAS RESPONDIDAS COM NAO OBSERVADO
      const perguntasFiltradas = getPerguntasSemRespostaNaoObservado(
        respostasAtividades,
        atv
      );

      if (perguntasFiltradas.length === 0) return;

      const perguntasComNumercao = estruturarPerguntasImpressaoTif(
        atv,
        perguntasFiltradas
      );

      auxPerguntas.push({
        perguntas: ordenarPerguntasAtividade(perguntasComNumercao),
        respostas: perguntasFiltradas,
        atividade: {
          id: atv.atividade.id,
          nome: atv.atividade.nome,
          armadilha: atv.armadilha,
        },
      });
    });

    setPerguntas(auxPerguntas);
  };

  const getPerguntasSemRespostaNaoObservado = (
    respostas: IRespostaAtividade[],
    atividade: IFormAtividades
  ) => {
    return respostas.filter((resposta) => {
      if (atividade.armadilha) {
        if (
          resposta.alternativas[0]?.alternativa.nome !== "NÃO OBSERVADO" &&
          resposta.pergunta.visivel &&
          resposta.armadilha?.id === atividade.armadilha.id
        ) {
          return resposta;
        }

        return null;
      } else {
        if (
          resposta.alternativas[0]?.alternativa.nome !== "NÃO OBSERVADO" &&
          resposta.pergunta.visivel &&
          resposta.atividade.id === atividade.atividade.id
        ) {
          return resposta;
        }

        return null;
      }
    });
  };

  useEffect(() => {
    if (tifToPreview) {
      handlePrint();
    }
    //eslint-disable-next-line
  }, [tifToPreview]);

  return (
    <>
      <Button onClick={handlePreviewTIF}>Gerar Prévia em PDF</Button>

      {tifToPreview && (
        <div style={{ display: "none" }}>
          <div ref={componentRef}>
            <div className="marcadagua">MINUTA</div>
            <table className={`${styles["width-tif"]} tif-impressao-font`}>
              <HeaderImpressaoTIF numTIF={tifToPreview.numero} />

              <FooterImpressaoTIF tif={tifToPreview} />

              <BodyImpressaoTIF tif={tifToPreview} />
            </table>

            {perguntas.length > 0 && (
              <QuestionarioImpressaoTIF
                tif={tifToPreview}
                perguntas={perguntas}
              />
            )}

            {anexosImages.map((anexo, ind) => {
              return (
                <ImagesImpressaoTIF
                  tif={tifToPreview}
                  anexo={anexo}
                  key={ind}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
