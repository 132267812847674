import { IAtividadeInterdicao } from "@interfaces/IAtividadeInterdicao";
import { api } from "@services/api";

export const httpAtividadeInterdicao = {
  adicionar: async (atividade: IAtividadeInterdicao) => {
    const { data } = await api.post(`/areas-atividades-interdicoes`, atividade);

    return data;
  },
  remover: async (areaAtividadeInterdicaoId: number) => {
    await api.delete(
      `/areas-atividades-interdicoes/${areaAtividadeInterdicaoId}`
    );

    return;
  },
  listar: async () => {
    const { data } = await api.get<IAtividadeInterdicao[]>(
      `/areas-atividades-interdicoes`
    );
    return data;
  },
};
