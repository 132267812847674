import { IAmostraDadosLasgal } from "@interfaces/IAmostraDadosLasgal";
import { IBoletimAmostra } from "@interfaces/IBoletimAmostra";
import { BodyBoletimLasgal } from "./components/BodyBoletimLasgal";
import { FooterBoletimLasgal } from "./components/FooterBoletimLasgal";
import { HeaderBoletimLasgal } from "./components/HeaderBoletimLasgal";
import styles from "./style.module.css";

interface IImpressaoBoletimLasgalProps {
  boletim: IBoletimAmostra;
  amostra: IAmostraDadosLasgal;
}

export function ImpressaoBoletimLasgal({
  boletim,
  amostra,
}: IImpressaoBoletimLasgalProps) {
  return (
    <>
      <div className="marcadagua"></div>
      <table className={`${styles.width}`}>
        <HeaderBoletimLasgal boletim={boletim} />
        <BodyBoletimLasgal boletim={boletim} amostra={amostra} />
        <FooterBoletimLasgal assinatura={boletim?.assinaturaServidor || ""} />
      </table>
    </>
  );
}
