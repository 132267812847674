import { SyncOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { apiService } from "@services/api";
import { Button, message, Tooltip } from "antd";
import { useTif } from "pages/tif/hooks/useTif";
import { useState } from "react";

export function RenewCacheItensTIF() {
  const [isLoading, setIsLoading] = useState(false);
  const { atualizarTif, listaTif } = useTif();

  const tifsSubmitted = listaTif.filter((tif) => Boolean(tif.id));

  const handleSync = async () => {
    const keyMessage = "sync-termos";

    setIsLoading(true);
    message.loading({
      key: keyMessage,
      content: "Atualizando termos...",
    });

    try {
      await Promise.allSettled(
        tifsSubmitted.map((tif) => apiService.tif.byId(tif.id || 0))
      ).then((res) => {
        res.forEach((data) => {
          if (data.status === "fulfilled") {
            atualizarTif(data.value);
            return;
          }
          openNotification({
            type: "error",
            message: "Erro ao atualizar TIF",
            description: data.reason,
          });
        });
      });

      setIsLoading(false);
      message.destroy(keyMessage);
      openNotification({
        type: "success",
        message: "Termos atualizados com sucesso!",
      });
    } catch (error) {
      setIsLoading(false);
      message.destroy(keyMessage);
      openNotification({
        type: "error",
        message: "Não foi possivel atualizar os termos. Tente novamente.",
      });
    }
  };

  return (
    <>
      <Tooltip title="Atualizar termos sincronizados">
        <Button
          onClick={handleSync}
          disabled={
            isLoading || tifsSubmitted.length === 0 || !navigator.onLine
          }
          style={{ boxShadow: "5px 5px 5px rgba(0,0,0,0.2)" }}
        >
          <SyncOutlined spin={isLoading} />
        </Button>
      </Tooltip>
    </>
  );
}
