import { IPessoaAutorizadaAdquirirAgrotoxico } from "@interfaces/IPessoaAutorizadaAdquiriAgrotoxico";
import { api } from "@services/api";

export const getPessoaAutorizadaAdquirirAgrotoxico = async ({
  cpfCnpj,
  nome,
}: {
  cpfCnpj?: string;
  nome?: string;
}) => {
  const { data } = await api.get<IPessoaAutorizadaAdquirirAgrotoxico[]>(
    "pessoas-autorizadas-adquirir-agrotoxicos",
    {
      params: {
        cpfCnpj: cpfCnpj?.replace(/[^\d]+/g, ""),
        nome,
      },
    }
  );
  return data;
};
