import { configureStore } from "@reduxjs/toolkit";
import localforage from "localforage";
import { persistReducer, persistStore } from "redux-persist";

//CONFIG OFFLINE
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import { combineReducers, StoreEnhancer } from "redux";
import expireReducer, { ExpireReducerConfig } from "redux-persist-expire";

//REDUCERS
import amostrasSlice from "./feature/amostraSlice";
import armadilhaReducer from "./feature/armadilhaSlice";
import atividadeInterditadaReducer from "./feature/atividadeInterditadaSlice";
import atividadesReducer from "./feature/atividadeSlice";
import categoriasReducer from "./feature/categoriaSlice";
import downloadMunicipiosReducer from "./feature/downloadMunicipioSlice";
import embalagensReducer from "./feature/embalagemSlice";
import empresasAgrotoxicoReducer from "./feature/empresaAgrotoxicoSlice";
import especiesReducer from "./feature/especieSlice";
import estabelecimentosReducer from "./feature/estabelecimentoSlice";
import estadioFenologicoReducer from "./feature/estadioFenologicoSlice";
import filtroTermoApreensaoReducer from "./feature/filtroTermoApreensaoSlice";
import filtroTermoColetaReducer from "./feature/filtroTermoColetaSlice";
import filtroTermoVistoriaReducer from "./feature/filtroTermoVistoriaSlice";
import filtroTidReducer from "./feature/filtroTidSlice";
import filtroTifReducer from "./feature/filtroTifSlice";
import formularioPerguntasVistoriaReducer from "./feature/formularioPerguntasVistoriaSlice";
import gridServidoresReducer from "./feature/gridServidorSlice";
import itensTermoApreensaoReducer from "./feature/itensTermoApreensaoLiberacaoSlice";
import materialColetadoReducer from "./feature/materialColetadoSlice";
import menuReducer from "./feature/menuSlice";
import municipiosReducer from "./feature/municipiosSlice";
import perfilReducer from "./feature/perfilSlice";
import pessoaAutorizadaAdquirirAgrotoxicoReducer from "./feature/pessoaAutorizadaAdquirirAgrotoxicoSlice";
import produtosAgrotoxicosReducer from "./feature/produtoAgrotoxicoSlice";
import programasReducer from "./feature/programaSlice";
import propriedadesReducer from "./feature/propriedadeSlice";
import servidoresReducer from "./feature/servidorSlice";
import termoApreensaoLiberacaoReducer from "./feature/termoApreensaoLiberacaoSlice";
import termosColetaReducer from "./feature/termoColetaSlice";
import termoVistoriaReducer from "./feature/termoVistoriaSlice";
import tidReducer from "./feature/tidSlice";
import tifReducer from "./feature/tifSlice";
import ulesReducer from "./feature/uleSlice";
import unidadeMedidaReducer from "./feature/unidadeMedidaSlice";
import userControllerReducer from "./feature/userControllerSlice";

import { OfflineAction } from "@redux-offline/redux-offline/lib/types";
import axios, { AxiosRequestConfig } from "axios";
import { logger } from "./middlewares/logger";

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

const rootReducer = combineReducers({
  programas: programasReducer,
  estabelecimentos: estabelecimentosReducer,
  propriedades: propriedadesReducer,
  atividadesTIF: atividadesReducer,
  atividadesInterditadas: atividadeInterditadaReducer,
  downloadMunicipios: downloadMunicipiosReducer,
  menuSisdev: menuReducer,
  userController: userControllerReducer,
  tif: tifReducer,
  gridServidores: gridServidoresReducer,
  servidores: servidoresReducer,
  municipios: municipiosReducer,
  ules: ulesReducer,
  filtroTIF: filtroTifReducer,
  filtroTermoColeta: filtroTermoColetaReducer,
  termosColeta: termosColetaReducer,
  amostras: amostrasSlice,
  materialColetado: materialColetadoReducer,
  estadioFenologico: estadioFenologicoReducer,
  especies: especiesReducer,
  categorias: categoriasReducer,
  termosApreensaoLiberacao: termoApreensaoLiberacaoReducer,
  itensTermoApreensaoLiberacao: itensTermoApreensaoReducer,
  unidadeMedida: unidadeMedidaReducer,
  produtosAgrotoxicos: produtosAgrotoxicosReducer,
  embalagens: embalagensReducer,
  empresasAgrotoxicos: empresasAgrotoxicoReducer,
  filtroTermoApreensao: filtroTermoApreensaoReducer,
  termosVistoria: termoVistoriaReducer,
  filtroTermoVistoria: filtroTermoVistoriaReducer,
  formularioPerguntasVistoria: formularioPerguntasVistoriaReducer,
  perfis: perfilReducer,
  tid: tidReducer,
  filtroTID: filtroTidReducer,
  armadilha: armadilhaReducer,
  pessoaAutorizadaAdquirirAgrotoxico: pessoaAutorizadaAdquirirAgrotoxicoReducer,
});

const customMiddlewares: any = [logger];

const configExpireReducers: ExpireReducerConfig = {
  expireSeconds: 3 * 86400, //86400 === 1 dia em segundos
  persistedAtKey: "persistedAt",
  autoExpire: true,
  expiredState: {
    items: [],
  },
};

localforage.config({
  name: "storage",
  size: 10080736,
  driver: localforage.INDEXEDDB,
  storeName: "INDEA",
  version: 1.0,
  description: "Storage do SISDEV-mobile",
});

const persistConfig = {
  key: "root",
  storage: localforage,
  blacklist: [
    "atividadesTIF",
    "gridServidores",
    "amostras",
    "itensTermoApreensao",
  ],
  transforms: [
    expireReducer("downloadMunicipios", configExpireReducers),
    expireReducer("estabelecimentos", configExpireReducers),
    expireReducer("propriedades", configExpireReducers),
    expireReducer("armadilha", configExpireReducers),
    expireReducer("programas", configExpireReducers),
    expireReducer("pessoaAutorizadaAdquirirAgrotoxico", configExpireReducers),
    expireReducer("atividadesInterditadas", configExpireReducers),
    expireReducer("materialColetado", configExpireReducers),
    expireReducer("estadioFenologico", configExpireReducers),
    expireReducer("especies", configExpireReducers),
    expireReducer("categorias", configExpireReducers),
    expireReducer("unidadeMedida", configExpireReducers),
    expireReducer("formularioPerguntasVistoria", configExpireReducers),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

//CONFIGURAÇAO PARA CHAMADA DA API NO ENVIO DE DADOS OFFLINE
const effect = (effect: AxiosRequestConfig, _action: any) =>
  axios({
    ...effect,
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    // responseType: "json",
    // timeout: 800,
  });

// the custom enqueue action
function enqueue(outbox: Array<OfflineAction>, incomingAction: any) {
  return [...outbox, incomingAction];
}

export const store = configureStore({
  reducer: persistedReducer,
  enhancers: [
    offline({
      ...offlineConfig,
      effect,
      queue: { ...offlineConfig.queue, enqueue },
      persistOptions: {
        whitelist: ["offline"],
      },
      // persist: offlineConfig.persistAutoRehydrate,
    }) as StoreEnhancer,
  ],
  middleware: (getDefaultMiddlewares) => [
    ...getDefaultMiddlewares({
      serializableCheck: false,
      immutableCheck: { warnAfter: 128 },
    }),
    ...customMiddlewares,
  ],
});

export const persistor = persistStore(store);
