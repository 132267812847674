import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import moment from "moment";

export function Descritiva({
  termo,
  pergunta,
}: {
  termo: ITermoVistoria;
  pergunta: IPerguntaTermoVistoria;
}) {
  const resposta = termo.respostas.find(
    (res) => res.pergunta.id === pergunta.pergunta.id
  );

  return (
    <>
      <p
        style={{
          borderBottom: "1px solid black",
        }}
      >
        <strong>ESPECIFICAR:</strong>{" "}
        {resposta?.pergunta.formatoResposta === "DATE" && (
          <span>{moment(resposta?.descricao).format("DD/MM/YYYY")}</span>
        )}
        {(resposta?.pergunta.formatoResposta === "TEXTO" ||
          resposta?.pergunta.formatoResposta === "NUMBER") && (
          <span>{resposta?.descricao}</span>
        )}
      </p>
    </>
  );
}
