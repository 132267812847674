import { Form, Input, message, Modal } from "antd";
import { useState } from "react";
import { openNotification } from "../../../../../components/Notifications";
import { getPayloadUser } from "../../../../../functions/getPayloadUser";
import { apiService } from "../../../../../services/api";

export function ModalAssinaturaBoletimLASGAL({
  isOpen,
  onClose,
  boletimId,
}: {
  isOpen: boolean;
  boletimId?: number;
  onClose: () => void;
}) {
  const userLogged = getPayloadUser(localStorage.getItem("access_token"));
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const submitSignature = async (formvalues: {
    username: string;
    password: string;
  }) => {
    if (!boletimId) return;

    message.loading({
      key: "confirm-signature",
      duration: 0,
      content: "Confirmando assinatura...",
    });
    setIsLoading(true);

    await apiService.boletimAmostra
      .enviarAssinatura({
        boletimAmostraId: boletimId,
        senha: formvalues.password,
        usuario: formvalues.username,
      })
      .then((res) => {
        openNotification({
          type: "success",
          message: "Boletim criado e assinado com sucesso!",
        });
      })
      .catch((erro) => {
        openNotification({
          type: "info",
          message: "Não foi possivel assinar Boletim.",
          description: erro.response.data.detail,
        });
      });

    message.destroy("confirm-dignature");
    setIsLoading(false);
    onClose();
  };

  return (
    <>
      <Modal
        title={
          <h1 style={{ textAlign: "center", fontSize: 21 }}>Assinar Boletim</h1>
        }
        centered
        maskClosable={false}
        open={isOpen}
        keyboard={true}
        onCancel={onClose}
        okText="Confirmar"
        okButtonProps={{
          form: "form-signature",
          htmlType: "submit",
          disabled: isLoading,
        }}
        cancelButtonProps={{
          disabled: isLoading,
        }}
        closable={false}
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <Form
          id="form-signature"
          form={form}
          layout="vertical"
          initialValues={{ username: userLogged?.cpf }}
          onFinish={submitSignature}
        >
          <Form.Item
            label="Usuário"
            tooltip="Utilize o mesmo usuário do MT Login"
            name="username"
            rules={[{ required: true }]}
          >
            <Input placeholder="usuario..." />
          </Form.Item>
          <Form.Item
            label="Senha"
            tooltip="Utilize a mesma senha do MT Login"
            name="password"
            rules={[{ required: true }]}
          >
            <Input placeholder="*******" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
