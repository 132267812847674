import { readerBlobToFileReader } from "@functions/readerBlobToFileReader";
import { ITif } from "@interfaces/ITif";
import { apiService } from "@services/api";
import { filtroTifAction } from "@store/actions/filtroTifAction";
import { tifAction } from "@store/actions/tifAction";
import { getFiltroTif } from "@store/feature/filtroTifSlice";
import { getTIFs } from "@store/feature/tifSlice";
import { useDispatch, useSelector } from "react-redux";

export const useTif = () => {
  const listaTif = useSelector(getTIFs);
  const { addTermo, removeItem, updateItem, addItemByFilter } = tifAction;
  const { addFiltroTif, removeFiltroTif } = filtroTifAction;
  const appDispatch = useDispatch();
  const listaTermoFiltro = useSelector(getFiltroTif);

  const adicionarTif = (tif: ITif) => {
    appDispatch(addTermo(tif));
  };

  const removerTif = (numTif: string) => {
    appDispatch(removeItem(numTif));
  };

  const atualizarTif = (tif: ITif) => {
    appDispatch(updateItem(tif));
  };

  const adicionarTifByFiltro = (tif: ITif) => {
    appDispatch(addItemByFilter(tif));
  };

  const aplicarFiltro = (content: any) => {
    appDispatch(addFiltroTif(content));
  };

  const removerFiltro = () => {
    appDispatch(removeFiltroTif());
  };

  const getAnexo = async (
    dadosAnexos: {
      nomeUpload: string;
      nomeArquivo: string;
    }[]
  ) => {
    let dataUrls: Blob[] = [];
    let response: any[] = [];

    await Promise.allSettled(
      dadosAnexos.map((anexo) => apiService.tif.getAnexo(anexo.nomeUpload))
    ).then((data) => {
      data.forEach((infoAnexo) => {
        if (infoAnexo.status === "fulfilled") {
          dataUrls.push(infoAnexo.value.data);
        }
      });
    });

    await Promise.allSettled(
      dataUrls.map((data) => readerBlobToFileReader(data))
    ).then((res) => {
      res.forEach((infoAnexo, ind) => {
        if (infoAnexo.status === "fulfilled") {
          // console.log(infoAnexo.value.result);
          response.push({
            ...dadosAnexos[ind],
            arquivo: infoAnexo.value.result,
          });
        }
      });
    });

    return response;
  };

  const getTifByNumero = async (numero: string) => {
    if (navigator.onLine) {
      const tif = listaTif.find((tif) => tif.numero === numero);

      if (tif) {
        return tif;
      }

      const params = new URLSearchParams();
      params.append("numero", numero);

      try {
        const responseTif = await apiService.tif.getByNumero(params.toString());
        return responseTif ?? null;
      } catch (error) {
        return null;
      }
    } else {
      const tif = listaTif.find((tif) => tif.numero === numero);

      return tif ?? null;
    }
  };

  return {
    getAnexo,
    getTifByNumero,
    adicionarTif,
    removerTif,
    atualizarTif,
    adicionarTifByFiltro,
    aplicarFiltro,
    removerFiltro,
    listaTermoFiltro,
    listaTif,
  };
};
