import { Table } from "antd";
import { ITabelaTesteLasgal } from "../../../../../interfaces/ITabelaTesteLasgal";
import { EdicaoCelulaTabelaTesteLasgal } from "./components/EdicaoCelulaTabelaTesteLasgal";
import { EdicaoLinhaTabelaTesteLasgal } from "./components/EdicaoLinhaTabelaTesteLasgal";
import { TitleItemTabelaTesteLASGAL } from "./components/TitleItemTabelaTesteLASGAL";
import styles from "./style.module.css";

interface ITabelaTesteLasgalProps {
  hasPureza?: boolean;
  hasOutraCultivares?: boolean;
  hasOutraCultivadaSilvestre?: boolean;
  hasNocivas?: boolean;
  hasGerminacao?: boolean;
  dataSource: ITabelaTesteLasgal[];
  setDataSource: React.Dispatch<React.SetStateAction<ITabelaTesteLasgal[]>>;
}

export function TabelaTesteLasgal({
  dataSource,
  hasPureza = true,
  hasOutraCultivares = true,
  hasOutraCultivadaSilvestre = true,
  hasNocivas = true,
  hasGerminacao = true,
  setDataSource,
}: ITabelaTesteLasgalProps) {
  const defaultColumns = [
    {
      title: "PUREZA",
      children: [
        {
          title: (
            <TitleItemTabelaTesteLASGAL hasMakeTest={hasPureza}>
              Pureza
            </TitleItemTabelaTesteLASGAL>
          ),
          dataIndex: "pureza",
          align: "center",
          width: 75,
        },
        {
          title: (
            <TitleItemTabelaTesteLASGAL hasMakeTest={hasPureza}>
              M.Inerte
            </TitleItemTabelaTesteLASGAL>
          ),
          dataIndex: "materialInerte",
          align: "center",
          width: 75,
        },
        {
          title: (
            <TitleItemTabelaTesteLASGAL hasMakeTest={hasPureza}>
              Outras Sementes
            </TitleItemTabelaTesteLASGAL>
          ),
          dataIndex: "outraSementes",
          align: "center",
          width: 75,
        },
      ],
    },
    {
      title: (
        <TitleItemTabelaTesteLASGAL hasMakeTest={hasOutraCultivares}>
          Outras Cultivares
        </TitleItemTabelaTesteLASGAL>
      ),
      dataIndex: "outraCultivares",
      align: "center",
      width: 75,
    },
    {
      title: "GERMINAÇÃO",
      children: [
        {
          title: (
            <TitleItemTabelaTesteLASGAL hasMakeTest={hasGerminacao}>
              Normais
            </TitleItemTabelaTesteLASGAL>
          ),
          dataIndex: "normais",
          align: "center",
          width: 75,
        },
        {
          title: (
            <TitleItemTabelaTesteLASGAL hasMakeTest={hasGerminacao}>
              Anormais
            </TitleItemTabelaTesteLASGAL>
          ),
          dataIndex: "anormais",
          align: "center",
          width: 75,
        },
        {
          title: (
            <TitleItemTabelaTesteLASGAL hasMakeTest={hasGerminacao}>
              Duras
            </TitleItemTabelaTesteLASGAL>
          ),
          dataIndex: "duras",
          align: "center",
          width: 75,
        },
        {
          title: (
            <TitleItemTabelaTesteLASGAL hasMakeTest={hasGerminacao}>
              Dormentes
            </TitleItemTabelaTesteLASGAL>
          ),
          dataIndex: "dormentes",
          align: "center",
          width: 75,
        },
        {
          title: (
            <TitleItemTabelaTesteLASGAL hasMakeTest={hasGerminacao}>
              Mortas
            </TitleItemTabelaTesteLASGAL>
          ),
          dataIndex: "mortas",
          align: "center",
          width: 75,
        },
      ],
    },
    {
      title: "DOSN",
      children: [
        {
          title: (
            <TitleItemTabelaTesteLASGAL
              hasMakeTest={hasOutraCultivadaSilvestre}
            >
              Outras Cultivadas
            </TitleItemTabelaTesteLASGAL>
          ),
          dataIndex: "outraCultivadas",
          align: "center",
          width: 75,
        },
        {
          title: (
            <TitleItemTabelaTesteLASGAL
              hasMakeTest={hasOutraCultivadaSilvestre}
            >
              Silvestres
            </TitleItemTabelaTesteLASGAL>
          ),
          dataIndex: "silvestres",
          align: "center",
          width: 75,
        },
        {
          title: (
            <TitleItemTabelaTesteLASGAL hasMakeTest={hasNocivas}>
              Toleradas
            </TitleItemTabelaTesteLASGAL>
          ),
          dataIndex: "toleradas",
          align: "center",
          width: 75,
        },
        {
          title: (
            <TitleItemTabelaTesteLASGAL hasMakeTest={hasNocivas}>
              Proibidas
            </TitleItemTabelaTesteLASGAL>
          ),
          dataIndex: "proibidas",
          align: "center",
          width: 75,
        },
      ],
    },

    {
      title: "OUTRAS DETERMINAÇÕES",
      editable: true,
      children: [
        {
          title: "TZ(%)",
          dataIndex: "tetrazolio",
          align: "center",
          editable: true,
          width: 100,
        },

        {
          title: "PMS(%)",
          dataIndex: "pms",
          align: "center",
          editable: true,
          width: 100,
        },

        {
          title: "Vigna Unguiculata",
          dataIndex: "vigna",
          align: "center",
          editable: true,
          width: 100,
        },
        {
          title: "-O-",
          dataIndex: "vazio",
          align: "center",
          editable: true,
          width: 100,
        },
      ],
    },
  ];

  const components = {
    body: {
      row: EdicaoLinhaTabelaTesteLasgal,
      cell: EdicaoCelulaTabelaTesteLasgal,
    },
  };

  const mapColumns = (col: any) => {
    if (!col.editable) {
      return col;
    }
    const newCol = {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }

    return newCol;
  };

  const columns = defaultColumns.map(mapColumns);

  const handleSave = (row: ITabelaTesteLasgal) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  return (
    <Table
      rowClassName={() => styles["editable-row"]}
      size="small"
      components={components}
      bordered
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      style={{ marginBottom: 40, paddingTop: 20, overflowX: "auto" }}
    />
  );
}
