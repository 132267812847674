import { IListagemTermoColeta } from "@interfaces/IListagemTermoColeta";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import { api } from "@services/api";

export const httpTermoColeta = {
  enviar: async (formTermo: ITermoColetaAmostras) => {
    const termo = await api.post(`/vegetal/termos-coleta`, formTermo);
    return termo;
  },

  listar: async (params: string) => {
    const { data } = await api.get<IListagemTermoColeta>(
      `/vegetal/termos-coleta?${params}`
    );
    return data;
  },

  remover: async (id: number, motivo: string) => {
    await api.post(`/vegetal/termos-coleta/${id}/cancelar`, {
      motivo: motivo,
    });

    return;
  },

  byId: async (id: number) => {
    const { data } = await api.get<ITermoColetaAmostras>(
      `/vegetal/termos-coleta/${id}`
    );
    return data;
  },
  update: async (item: any, id: number) => {
    const { data } = await api.patch(`vegetal/termos-coleta/${id}`, item);

    return data;
  },
};
