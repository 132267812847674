import { apiService } from "@services/api";
import { Button, Divider, Typography } from "antd";
import { useRef } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { ImpressaoBoletimLasgal } from "../components/ImpressaoBoletimLasgal";
import styles from "./style.module.css";

export function ViewImpressaoBoletimLasgal() {
  const { amostraId } = useParams();
  const componentRef = useRef(null);
  const navigate = useNavigate();

  const { data: boletim } = useQuery({
    queryKey: ["BOLETIM"],
    queryFn: () =>
      apiService.boletimAmostra.byAmostraId(parseInt(amostraId || "")),
    onSuccess: (boletim) => {
      console.log(boletim);
    },
  });

  const { data: amostra } = useQuery({
    queryKey: ["AMOSTRA"],
    queryFn: () => apiService.amostraSemente.byId(parseInt(amostraId || "")),
    onSuccess: (amostra) => {
      console.log(amostra);
    },
  });

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    pageStyle: "@page { size: A4 !important;}",
    documentTitle: `BOLETIM`,
    suppressErrors: true,
  });

  return (
    <>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Imprimir Boletim
      </Typography.Title>
      <Divider />
      {boletim && amostra && (
        <div
          style={{
            display: "flex",
            gap: 20,
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
            <Button onClick={() => handlePrint()} type="primary">
              Imprimir
            </Button>

            <Button onClick={() => navigate("/LASGAL/listagem")}>Voltar</Button>
          </div>

          <div className={styles.container}>
            <div ref={componentRef}>
              <ImpressaoBoletimLasgal boletim={boletim} amostra={amostra} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
