import { openNotification } from "@components/Notifications";
import { masks } from "@functions/mascaras";
import { transformStringToNumber } from "@functions/transformStringToNumber";
import { useDadosTermoApreensaoLiberacao } from "@hooks/useDadosTermoApreensaoLiberacao";
import { useEmbalagem } from "@hooks/useEmbalagem";
import { useEmpresasAgrotoxico } from "@hooks/useEmpresasAgrotoxico";
import { useEspecie } from "@hooks/useEspecie";
import { useProdutosAgrotoxicos } from "@hooks/useProdutosAgrotoxicos";
import { useUnidadeMedida } from "@hooks/useUnidadeMedida";
import { ICultivares } from "@interfaces/ICultivares";
import { IItemTermoApreensao } from "@interfaces/IItemTermoApreensao";
import { apiService } from "@services/api";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputRef,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import moment from "moment";
import { ChangeEvent, Fragment, useEffect, useRef, useState } from "react";
import { useQueries } from "react-query";
import { ItemLiberacao } from "./components/ItemLiberacao";
import { TabelaItensTAL } from "./components/TabelaItensTAL";

const TIPOS_PRODUTOS = [
  { label: "AGROTÓXICO", value: "AGROTÓXICO" },
  { label: "SEMENTES", value: "SEMENTES" },
  { label: "MUDAS", value: "MUDAS" },
];

const unidadeId = 2;
const kiloId = 107;
const litroId = 68;

export interface ITabelaItens {
  key: number;
  tipoProduto: string;
  nomeProduto: string;
  fabricante: string;
  tipoEmbalagem: string;
  validade: string;
  lote: string;
  volume: string;
  unidadeMedida: string;
  quantidade: string;
  total: string;
  descricao: string;
  cultivar: string;
  renasem: string;
}

export function ItensTermoApreensaoLiberacao({
  isLiberacao,
}: {
  isLiberacao: boolean;
}) {
  const [formItens] = Form.useForm();
  const { listaEspecies } = useEspecie();
  const { listaUnidadeMedida } = useUnidadeMedida();
  const { listaProdutosAgrotoxicos } = useProdutosAgrotoxicos();
  const { listaEmbalagens } = useEmbalagem();
  const { listaEmpresasAgrotoxico } = useEmpresasAgrotoxico();
  const produtoInputRef = useRef<InputRef>(null);
  const fabricanteInputRef = useRef<InputRef>(null);
  const especieInputRef = useRef<InputRef>(null);
  const cultivarInputRef = useRef<InputRef>(null);
  const { adicionarItem, listaItens } = useDadosTermoApreensaoLiberacao();
  const { renasemMask } = masks;
  const [cultivaresOptions, setCultivaresOptions] = useState<ICultivares[]>([]);
  const [tipoProduto, setTipoProduto] = useState("");
  const [produtoAgrotoxico, setProdutoAgrotoxico] = useState<string | number>(
    ""
  );
  const [renasem, setRenasem] = useState("");
  const [fabricante, setFabricante] = useState<string | number>("");
  const [especie, setEspecie] = useState<string | number>("");
  const [cultivar, setCultivar] = useState<string | number>("");
  const [hasProdutoIdentificado, setHasProdutoIdentificado] = useState(true);
  const [hasFabricanteIdentificado, setHasFabricanteIdentificado] =
    useState(true);
  const [hasEspecieIdentificado, setHasEspecieIdentificado] = useState(true);
  const [hasCultivarIdentificado, setHasCultivarIdentificado] = useState(true);
  const [hasRenasemIdentificado, setHasRenasemIdentificado] = useState(true);
  const [dataTable, setDataTable] = useState<ITabelaItens[]>([]);

  const results = useQueries([
    {
      queryKey: [0, "UNIDADE_MEDIDA"],
      initialData: listaUnidadeMedida,
      queryFn: () => apiService.unidadeMedida(),
    },
    {
      queryKey: [1, "EMPRESAS_AGROTOXICOS"],
      initialData: listaEmpresasAgrotoxico,
      queryFn: () => apiService.empresasAgrotoxicos(),
    },
    {
      queryKey: [2, "PRODUTOS_AGROTOXICOS"],
      initialData: listaProdutosAgrotoxicos,
      queryFn: () => apiService.produtosAgrotoxico(),
    },
    {
      queryKey: [3, "EMBALAGENS"],
      initialData: listaEmbalagens,
      queryFn: () => apiService.embalagens(),
    },
    {
      queryKey: [4, "ESPECIE"],
      initialData: listaEspecies,
      queryFn: () => apiService.especie(),
    },
  ]);

  const handleSubmit = (values: any) => {
    if (!results[0].data) return;

    const unidade = results[0].data.filter(
      (unid) => unid.id === values.unidadeMedida
    )[0];
    const empresaSelected = results[1].data?.filter(
      (empresa) => empresa.id === fabricante
    )[0];
    const produtoSelected = results[2].data?.filter(
      (produto) => produto.id === produtoAgrotoxico
    )[0];
    const embalagemSelected = results[3].data?.filter(
      (embalagem) => embalagem.id === values.tipoEmbalagem
    )[0];

    const especieSelected = results[4].data?.filter(
      (esp) => esp.id === especie
    )[0];

    const cultivarSelected = cultivaresOptions.filter(
      (cult) => cult.id === cultivar
    )[0];

    if (
      tipoProduto === "AGROTÓXICO" &&
      !produtoSelected &&
      !produtoAgrotoxico.toString().trim()
    ) {
      openNotification({
        type: "info",
        message: "Informe o Produto agrotóxico.",
      });
      return;
    }

    if (
      tipoProduto === "AGROTÓXICO" &&
      !empresaSelected &&
      !fabricante.toString().trim()
    ) {
      openNotification({
        type: "info",
        message: "Informe o Fabricante.",
      });
      return;
    }

    if (
      ["SEMENTES", "MUDAS"].includes(tipoProduto) &&
      !especieSelected &&
      !especie.toString().trim()
    ) {
      openNotification({
        type: "info",
        message: "Informe a Espécie.",
      });
      return;
    }

    if (
      ["SEMENTES", "MUDAS"].includes(tipoProduto) &&
      !cultivarSelected &&
      !cultivar.toString().trim()
    ) {
      openNotification({
        type: "info",
        message: "Informe a Cultivar.",
      });
      return;
    }

    let formValues: IItemTermoApreensao = {
      key: Date.now(),
      tipoArea: tipoProduto,
      unidadeMedida: unidade,
      quantidade: parseFloat(values.quantidade) || 0,
      descricao: values.descricao || "",
      cultivar: cultivarSelected || null,
      lote: values.lote || "",
      fabricante: empresaSelected || null,
      embalagem: embalagemSelected || null,
      dataValidade: values.validade
        ? moment(values.validade).format("YYYY-MM-DD")
        : "",
      volume: transformStringToNumber(values.volume || ""),
      total: transformStringToNumber(values.total || ""),
      renasem: hasRenasemIdentificado ? renasem : "",
      nomeProduto: "",
      produtoAgrotoxico: produtoSelected || null,
      quantidadeLiberada: parseInt(values.quantidade) || 0,
      especie: especieSelected || null,
      nomeCultivar: "",
      nomeEspecie: "",
      nomeFabricante: "",
    };

    if (["SEMENTES", "MUDAS"].includes(tipoProduto)) {
      formValues = {
        ...formValues,
        nomeProduto: especieSelected
          ? especieSelected.nomeComum
          : especie.toString().trim(),
        nomeEspecie: especieSelected
          ? especieSelected.nomeComum
          : especie.toString().trim(),
        nomeCultivar: cultivarSelected
          ? cultivarSelected.nome
          : cultivar.toString().trim(),
      };
    } else if (tipoProduto === "AGROTÓXICO") {
      formValues = {
        ...formValues,
        nomeProduto: produtoSelected
          ? produtoSelected.nome
          : produtoAgrotoxico.toString().trim(),
        nomeFabricante: empresaSelected
          ? empresaSelected.nome
          : fabricante.toString().trim(),
      };
    }

    adicionarItem(formValues);

    openNotification({
      type: "success",
      message: "Item adicionado com sucesso!",
    });

    formItens.resetFields([
      "tipoEmbalagem",
      "lote",
      "validade",
      "volume",
      "unidadeMedida",
      "quantidade",
      "descricao",
      "total",
    ]);
    setProdutoAgrotoxico("");
    setFabricante("");
    setEspecie("");
    setCultivar("");
    setRenasem("");
    setHasProdutoIdentificado(true);
    setHasFabricanteIdentificado(true);
    setHasEspecieIdentificado(true);
    setHasCultivarIdentificado(true);
    setHasRenasemIdentificado(true);
  };

  const handleChangeInputNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    formItens.setFieldValue(id, value.replace(/[^0-9]/g, ""));
  };

  const sumTotalItens = () => {
    const qnt = formItens.getFieldValue("quantidade") || 0;
    const volume =
      transformStringToNumber(formItens.getFieldValue("volume")) || 0;

    formItens.setFieldValue(
      "total",
      (volume * qnt).toFixed(2).toString().replace(".", ",")
    );
  };

  const handleChangeTipoProduto = (value: string) => {
    formItens.setFieldsValue({
      especie: "",
      cultivar: "",
      renasem: "",
      validade: "",
      lote: "",
      quantidade: "",
      unidadeMedida: "",
      produto: "",
      fabricante: "",
      tipoEmbalagem: "",
      volume: "",
      total: "",
    });
    setProdutoAgrotoxico("");
    setFabricante("");
    setEspecie("");
    setCultivar("");
    setRenasem("");
    setHasProdutoIdentificado(true);
    setHasFabricanteIdentificado(true);
    setHasEspecieIdentificado(true);
    setHasCultivarIdentificado(true);
    setHasRenasemIdentificado(true);
    setTipoProduto(value);
  };

  const handleChangeEspecie = async (id: number | undefined) => {
    if (!id) {
      setCultivaresOptions([]);
      return;
    }
    const especieSelected = results[4].data?.filter(
      (especie) => especie.id === id
    );

    formItens.setFieldValue("cultivar", "");

    if (especieSelected?.length === 0 || !especieSelected) {
      await apiService.especie().then((data) => {
        const aux = data.filter((especie) => especie.id === id);
        setCultivaresOptions(aux[0].cultivares);
      });

      return;
    }

    setCultivaresOptions(especieSelected[0].cultivares);
  };

  const verificarProdutoAgrotoxico = () => {
    setHasProdutoIdentificado(!hasProdutoIdentificado);
    setProdutoAgrotoxico("");
  };

  const verificarFabricante = () => {
    setHasFabricanteIdentificado(!hasFabricanteIdentificado);
    setFabricante("");
  };

  const verificarEspecie = () => {
    setHasEspecieIdentificado(!hasEspecieIdentificado);
    setEspecie("");
  };
  const verificarCultivar = () => {
    setHasCultivarIdentificado(!hasCultivarIdentificado);
    setCultivar("");
  };
  const verificarRenasem = () => {
    if (hasRenasemIdentificado) {
      setRenasem("Não foi identificado");
    } else {
      setRenasem("");
    }

    setHasRenasemIdentificado(!hasRenasemIdentificado);
  };

  useEffect(() => {
    const itens: ITabelaItens[] = listaItens.map((item) => {
      return {
        key: item.key,
        tipoProduto: item.tipoArea,
        quantidade: item.quantidade.toString(),
        unidadeMedida: item.unidadeMedida.nome,
        descricao: item.descricao,
        cultivar: item.nomeCultivar,
        fabricante: item.nomeFabricante,
        lote: item.lote,
        nomeProduto: item.nomeProduto,
        renasem: item.renasem,
        tipoEmbalagem: item.embalagem?.nome || "",
        total: item.total.toString().replace(".", ","),
        validade: item.dataValidade
          ? moment(item.dataValidade).format("DD/MM/YYYY")
          : "",
        volume: item.volume.toString().replace(".", ","),
      };
    });

    setDataTable(itens);
  }, [listaItens]);

  return (
    <Fragment>
      <Divider />
      <Typography.Title level={3}>Itens</Typography.Title>

      {isLiberacao ? (
        <ItemLiberacao />
      ) : (
        <>
          <Form
            labelCol={{ span: 14, flex: "150px" }}
            wrapperCol={{ flex: 1, span: 14 }}
            labelWrap
            labelAlign="left"
            id="form-itens-apreensao"
            form={formItens}
            onFinish={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <Form.Item
              label="Tipo de Produto"
              name="tipoProduto"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string).includes(input) ||
                  (option!.children as unknown as string).includes(
                    input.toUpperCase()
                  )
                }
                onChange={handleChangeTipoProduto}
              >
                {TIPOS_PRODUTOS.map((tipo) => (
                  <Select.Option key={tipo.value} value={tipo.value}>
                    {tipo.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {tipoProduto === "AGROTÓXICO" && (
              <>
                <Form.Item label="Produto">
                  <div style={{ display: "flex", gap: 10 }}>
                    {hasProdutoIdentificado ? (
                      <Select
                        placeholder="Selecione o produto agrotóxico"
                        style={{ minWidth: "150px" }}
                        value={produtoAgrotoxico || undefined}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option!.children as unknown as string).includes(
                            input
                          ) ||
                          (option!.children as unknown as string).includes(
                            input.toUpperCase()
                          )
                        }
                        onChange={(value) => {
                          setProdutoAgrotoxico(value);
                        }}
                      >
                        {results[2].data?.map((produto, ind) => (
                          <Select.Option value={produto.id} key={ind}>
                            {produto.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        placeholder="Digite o nome do Produto"
                        ref={produtoInputRef}
                        value={produtoAgrotoxico}
                        onChange={(e) => setProdutoAgrotoxico(e.target.value)}
                      />
                    )}
                    <Button
                      onClick={verificarProdutoAgrotoxico}
                      type={hasProdutoIdentificado ? "primary" : "default"}
                    >
                      {hasProdutoIdentificado
                        ? "Não foi Identificado"
                        : "Foi identificado"}
                    </Button>
                  </div>
                </Form.Item>

                <Form.Item label="Fabricante">
                  <div style={{ display: "flex", gap: 10 }}>
                    {hasFabricanteIdentificado ? (
                      <Select
                        placeholder="Selecione o Fabricante"
                        style={{ minWidth: "150px" }}
                        value={fabricante || undefined}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option!.children as unknown as string).includes(
                            input
                          ) ||
                          (option!.children as unknown as string).includes(
                            input.toUpperCase()
                          )
                        }
                        onChange={(value) => {
                          setFabricante(value);
                        }}
                      >
                        {results[1].data?.map((empresa) => (
                          <Select.Option key={empresa.id} value={empresa.id}>
                            {empresa.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        placeholder="Digite o nome do Fabricante"
                        ref={fabricanteInputRef}
                        value={fabricante}
                        onChange={(e) => setFabricante(e.target.value)}
                      />
                    )}
                    <Button
                      onClick={verificarFabricante}
                      type={hasFabricanteIdentificado ? "primary" : "default"}
                    >
                      {hasFabricanteIdentificado
                        ? "Não foi Identificado"
                        : "Foi identificado"}
                    </Button>
                  </div>
                </Form.Item>

                <Form.Item
                  label="Tipo Embalagem"
                  name="tipoEmbalagem"
                  rules={[{ required: true }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option!.children as unknown as string).includes(input) ||
                      (option!.children as unknown as string).includes(
                        input.toUpperCase()
                      )
                    }
                  >
                    {results[3].data?.map((embalagem, ind) => (
                      <Select.Option value={embalagem.id} key={ind}>
                        {embalagem.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}

            {["SEMENTES", "MUDAS"].includes(tipoProduto) && (
              <>
                <Form.Item label="Especie">
                  <div style={{ display: "flex", gap: 10 }}>
                    {hasEspecieIdentificado ? (
                      <Select
                        placeholder="Selecione a Especie"
                        style={{ minWidth: "150px" }}
                        value={especie || undefined}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option!.children as unknown as string).includes(
                            input
                          ) ||
                          (option!.children as unknown as string).includes(
                            input.toUpperCase()
                          )
                        }
                        onChange={(value) => {
                          setEspecie(value);
                          setCultivar("");
                          if (typeof value === "number") {
                            handleChangeEspecie(value);
                          }
                        }}
                      >
                        {results[4].data?.map((esp) => (
                          <Select.Option key={esp.id} value={esp.id}>
                            {esp.nomeComum}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        placeholder="Digite o nome da especie"
                        ref={especieInputRef}
                        value={especie}
                        onChange={(e) => setEspecie(e.target.value)}
                      />
                    )}
                    <Button
                      onClick={verificarEspecie}
                      type={hasEspecieIdentificado ? "primary" : "default"}
                    >
                      {hasEspecieIdentificado
                        ? "Não foi Identificado"
                        : "Foi identificado"}
                    </Button>
                  </div>
                </Form.Item>

                <Form.Item label="Cultivar">
                  <div style={{ display: "flex", gap: 10 }}>
                    {hasCultivarIdentificado ? (
                      <Select
                        placeholder="Selecione a Cultivar"
                        style={{ minWidth: "150px" }}
                        value={cultivar || undefined}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option!.children as unknown as string).includes(
                            input
                          ) ||
                          (option!.children as unknown as string).includes(
                            input.toUpperCase()
                          )
                        }
                        onChange={(value) => {
                          setCultivar(value);
                        }}
                      >
                        {cultivaresOptions.map((cultivar) => (
                          <Select.Option key={cultivar.id} value={cultivar.id}>
                            {cultivar.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        placeholder="Digite o nome da Cultivar"
                        ref={cultivarInputRef}
                        value={cultivar}
                        onChange={(e) => setCultivar(e.target.value)}
                      />
                    )}
                    <Button
                      onClick={verificarCultivar}
                      type={hasCultivarIdentificado ? "primary" : "default"}
                    >
                      {hasCultivarIdentificado
                        ? "Não foi Identificado"
                        : "Foi identificado"}
                    </Button>
                  </div>
                </Form.Item>

                <Form.Item label="RENASEM">
                  <div style={{ display: "flex", gap: 10 }}>
                    <Input
                      disabled={!hasRenasemIdentificado}
                      value={renasem}
                      maxLength={13}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        const { value } = e.target;

                        setRenasem(renasemMask(value || ""));
                      }}
                    />

                    <Button
                      onClick={verificarRenasem}
                      type={hasRenasemIdentificado ? "primary" : "default"}
                    >
                      {hasRenasemIdentificado
                        ? "Não foi Identificado"
                        : "Foi identificado"}
                    </Button>
                  </div>
                </Form.Item>
              </>
            )}

            <>
              <Form.Item label="Lote" name="lote" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item label="Validade" name="validade">
                <DatePicker format={"DD/MM/YYYY"} />
              </Form.Item>

              {tipoProduto !== "MUDAS" && (
                <Form.Item
                  label="volume da embalagem"
                  name="volume"
                  rules={[{ required: true }]}
                >
                  <Input
                    onChange={(e) => {
                      const { value } = e.target;

                      formItens.setFieldValue(
                        "volume",
                        value.replace(/[^0-9,]/g, "")
                      );

                      sumTotalItens();
                    }}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              )}

              <Form.Item
                label="Unidade de Medida"
                name="unidadeMedida"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).includes(input) ||
                    (option!.children as unknown as string).includes(
                      input.toUpperCase()
                    )
                  }
                >
                  {tipoProduto === "SEMENTES" && (
                    <Select.Option value={kiloId}>KG</Select.Option>
                  )}
                  {tipoProduto === "MUDAS" && (
                    <Select.Option value={unidadeId}>Unidade</Select.Option>
                  )}

                  {tipoProduto === "AGROTÓXICO" && (
                    <>
                      <Select.Option value={litroId}>LITRO - L</Select.Option>
                      <Select.Option value={kiloId}>KG</Select.Option>
                    </>
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                label="Quantidade"
                name="quantidade"
                rules={[{ required: true }]}
              >
                <Input
                  onChange={(e) => {
                    handleChangeInputNumber(e);

                    if (tipoProduto === "MUDAS") {
                      formItens.setFieldValue(
                        "total",
                        formItens.getFieldValue("quantidade")
                      );
                      return;
                    }
                    sumTotalItens();
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item label="Total" name="total">
                <Input disabled />
              </Form.Item>
            </>

            <Form.Item label="Descrição" name="descricao">
              <Input.TextArea
                rows={3}
                maxLength={1000}
                showCount
                placeholder="Informe o produto e suas caracteristicas (fabricante/embalagem/volume embalagem/lote/validade) quando existir, ou informe ausência da caracteristica"
              />
            </Form.Item>
          </Form>

          <Row justify="center">
            <Space direction="vertical" size={10}>
              <Button
                type="primary"
                htmlType="submit"
                form="form-itens-apreensao"
              >
                Adicionar Item
              </Button>
              <Col />
            </Space>
          </Row>

          <TabelaItensTAL dataTable={dataTable} />
        </>
      )}
    </Fragment>
  );
}
