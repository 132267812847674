import { ModalAssinatura } from "@components/ModalAssinatura";
import { openNotification } from "@components/Notifications";
import { masks } from "@functions/mascaras";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { IAssinante } from "@interfaces/IAssinante";
import { apiService } from "@services/api";
import { AssinaturaContainer } from "@styles/AssinaturaContainer";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import { Button, Checkbox, Divider, Input, Typography } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { AxiosError } from "axios";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import ReactSignatureCanvas from "react-signature-canvas";
import { useActionsTID } from "../../hooks/useActionsTID";

export function EdicaoPosEnvioTID() {
  const { termoId } = useParams();
  const navigate = useNavigate();
  const { listaTIDs, atualizarAssinatura, atualizarTermo } = useActionsTID();
  const refFiscalizado = useRef(null);
  const { cpfCnpjMask } = masks;
  const [assinaturaFisica, setAssinaturaFisica] = useState<CheckboxValueType[]>(
    []
  );
  const [motivoNaoAssinatura, setMotivoNaoAssinatura] = useState<
    CheckboxValueType[]
  >([]);
  const [dadosFiscalizado, setDadosFiscalizado] = useState<IAssinante>({
    assinatura: null,
    cpfAssinante: "",
    funcaoAssinante: "",
    nomeAssinante: "",
  });
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { data: tid } = useQuery({
    queryKey: ["EDITAR_POS_ENVIO_TID"],
    queryFn: () => apiService.tid.byId(parseInt(termoId || "")),
    initialData: listaTIDs.find(
      (termo) => termo.id === parseInt(termoId || "")
    ),
  });

  const handleBack = () => {
    navigate("/TID/listagem");
  };

  const handleSubmit = async () => {
    if (!tid) return;
    let dadosAtualizados = {
      nomeAssinante: tid.nomeAssinanteFiscalizado,
      cpfAssinante: tid.cpfAssinanteFiscalizado,
      funcaoAssinante: tid.funcaoAssinanteFiscalizado,
      statusAssinatura: tid.statusAssinaturaFiscalizado,
      assinatura: tid.assinaturaFiscalizado,
    };

    if (tid?.statusAssinaturaFiscalizado === "AUSENTE") {
      if (
        (!dadosFiscalizado.nomeAssinante ||
          !dadosFiscalizado.cpfAssinante ||
          !dadosFiscalizado.funcaoAssinante) &&
        motivoNaoAssinatura.length === 0
      ) {
        openNotification({
          type: "error",
          message: "Erro ao salvar Termo de Interdição/Desinterdição.",
          description:
            "Preencha os dados do assinante Fiscalizado para salvar o Termo",
        });

        return;
      }

      if (
        dadosFiscalizado?.assinatura?.toData().length === 0 &&
        motivoNaoAssinatura.length === 0
      ) {
        openNotification({
          type: "error",
          message: "Erro ao salvar Termo de Interdição/Desinterdição.",
          description: "Preencha as assinaturas pendentes",
        });
        return;
      }

      dadosAtualizados = {
        nomeAssinante: dadosFiscalizado.nomeAssinante,
        cpfAssinante: dadosFiscalizado.cpfAssinante,
        funcaoAssinante: dadosFiscalizado.funcaoAssinante,
        statusAssinatura:
          assinaturaFisica.length > 0
            ? getStatusAssinatura(assinaturaFisica)
            : getStatusAssinatura(motivoNaoAssinatura),
        assinatura:
          motivoNaoAssinatura.length === 0
            ? dadosFiscalizado?.assinatura?.getTrimmedCanvas().toDataURL() || ""
            : "",
      };
    }

    const objDados = {
      assinaturaFiscalizado: dadosAtualizados.assinatura,
      cpfAssinanteFiscalizado: dadosAtualizados.cpfAssinante,
      funcaoAssinanteFiscalizado: dadosAtualizados.funcaoAssinante,
      nomeAssinanteFiscalizado: dadosAtualizados.nomeAssinante,
      statusAssinaturaFiscalizado: dadosAtualizados.statusAssinatura,
    };

    const tipoTermoFormatado =
      tid.tipoTermo === "INTERDICAO" ? "Interdição" : "Desinterdição";

    if (navigator.onLine) {
      await apiService.tid
        .update(objDados, parseInt(termoId || ""))
        .then((res) => {
          // console.log(res);
          atualizarTermo({
            ...res,
            ...objDados,
          });
          openNotification({
            type: "success",
            message: `Termo de ${tipoTermoFormatado} atualizado com sucesso!`,
          });

          handleBack();
        })
        .catch((error) => {
          console.log(error);
          if (error instanceof AxiosError) {
            openNotification({
              type: "error",
              message: `Não foi possivel atualizar Termo de ${tipoTermoFormatado}`,
              description: `Erro ${error.response?.status}: ${error.response?.data.title}`,
            });
            return;
          }

          openNotification({
            type: "error",
            message: `Não foi possivel atualizar Termo de ${tipoTermoFormatado}`,
            description: "Erro desconhecido.",
          });
        });
    } else {
      atualizarAssinatura(objDados, parseInt(termoId || ""), tid);
      openNotification({
        type: "success",
        message: `Termo de ${tipoTermoFormatado} atualizado com sucesso!`,
      });

      handleBack();
    }
  };

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = async () => {
    setIsOpenModal(false);
    setDadosFiscalizado({
      ...dadosFiscalizado,
      assinatura: refFiscalizado.current,
    });
  };

  const cleanSignatureFields = (
    signature: ReactSignatureCanvas | null | undefined
  ) => {
    if (signature !== null && signature !== undefined) {
      signature.clear();
    }
  };

  const handleChangeMotivoNaoAssinaturaFiscalizado = (
    checkedValue: CheckboxValueType[]
  ) => {
    if (checkedValue.length > 0) {
      setAssinaturaFisica([]);
    }

    if (checkedValue[0] === "AUSENTE") {
      setDadosFiscalizado({
        assinatura: null,
        nomeAssinante: "",
        cpfAssinante: "",
        funcaoAssinante: "",
      });
    }

    cleanSignatureFields(refFiscalizado.current);
    setMotivoNaoAssinatura(checkedValue);
  };

  if (!tid) {
    return <></>;
  }

  return (
    <>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Editar Termo de{" "}
        {tid.tipoTermo === "INTERDICAO" ? "Interdição" : "Desinterdição"}{" "}
        {tid.numero}
      </Typography.Title>

      <Divider />

      <div
        style={{
          display: "flex",
          padding: "50px 15px 15px 15px",
        }}
      >
        <Checkbox.Group
          style={{
            display: "flex",
            flexDirection: "row",
            width: 250,
          }}
          onChange={(checkValue) => {
            setAssinaturaFisica(checkValue);
            setMotivoNaoAssinatura([]);
          }}
          value={assinaturaFisica}
        >
          <Checkbox value="ASSINADO_FISICAMENTE">Assinatura Física</Checkbox>
        </Checkbox.Group>
      </div>
      <AssinaturaContainer>
        <div className="fiscalizado-signature-form">
          <div>
            <div className="container-img-assinatura">
              {dadosFiscalizado.assinatura && (
                <img
                  src={dadosFiscalizado.assinatura
                    .getTrimmedCanvas()
                    .toDataURL("image/png")}
                  alt="assinatura Fiscalizado"
                  style={{
                    width: "90%",
                    height: "90%",
                  }}
                />
              )}
            </div>

            <Typography style={{ textAlign: "center" }}>
              Assinatura Fiscalizado
            </Typography>
            <Button
              disabled={
                motivoNaoAssinatura.length > 0 || assinaturaFisica.length > 0
              }
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                handleOpenModal();
              }}
            >
              Clique aqui para assinar
            </Button>

            <div style={{ display: "flex", paddingTop: 10 }}>
              <Checkbox.Group
                onChange={(e) => handleChangeMotivoNaoAssinaturaFiscalizado(e)}
                value={motivoNaoAssinatura}
              >
                <Checkbox value="AUSENTE">Ausente</Checkbox>
              </Checkbox.Group>

              <Checkbox.Group
                value={motivoNaoAssinatura}
                onChange={(e) => handleChangeMotivoNaoAssinaturaFiscalizado(e)}
              >
                <Checkbox value="RECUSADO">Recusou Assinar</Checkbox>
              </Checkbox.Group>
            </div>

            <div
              className="dados-assinante-tif"
              style={{
                display: "flex",
                gap: 10,
                maxWidth: 600,
              }}
            >
              <div>
                <span>Nome:</span>
                <Input
                  disabled={
                    motivoNaoAssinatura.length > 0 &&
                    !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                      motivoNaoAssinatura[0].toString()
                    )
                  }
                  name="nome_assinante_fiscalizado"
                  value={dadosFiscalizado?.nomeAssinante}
                  onChange={(e) =>
                    setDadosFiscalizado({
                      ...dadosFiscalizado,
                      nomeAssinante: e.target.value,
                    })
                  }
                />
              </div>

              <div>
                <span>CPF:</span>
                <Input
                  disabled={
                    motivoNaoAssinatura.length > 0 &&
                    !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                      motivoNaoAssinatura[0].toString()
                    )
                  }
                  name="cpf_assinante_fiscalizado"
                  value={dadosFiscalizado?.cpfAssinante}
                  onChange={(e) => {
                    setDadosFiscalizado({
                      ...dadosFiscalizado,
                      cpfAssinante: cpfCnpjMask(e.target.value),
                    });
                  }}
                />
              </div>

              <div>
                <span>Função:</span>
                <Input
                  disabled={
                    motivoNaoAssinatura.length > 0 &&
                    !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                      motivoNaoAssinatura[0].toString()
                    )
                  }
                  name="funcao_assinante_fiscalizado"
                  value={dadosFiscalizado?.funcaoAssinante}
                  onChange={(e) =>
                    setDadosFiscalizado({
                      ...dadosFiscalizado,
                      funcaoAssinante: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </AssinaturaContainer>
      <ButtonFooterContainer style={{ marginTop: 40 }}>
        <Button onClick={handleBack}>Voltar</Button>
        <Button type="primary" onClick={handleSubmit}>
          Atualizar Termo
        </Button>
      </ButtonFooterContainer>
      <ModalAssinatura
        refAssinante={refFiscalizado}
        isOpen={isOpenModal}
        cleanSignature={cleanSignatureFields}
        handleClose={handleCloseModal}
        nomeAssinante="Fiscalizado"
      />
    </>
  );
}
