import { IListagemTermoVistoria } from "@interfaces/IListagemTermoVistoria";
import {
  IDadosAssinaturaTermoVistoria,
  ITermoVistoria,
} from "@interfaces/ITermoVistoria";
import { api } from "@services/api";

export const httpTermoVistoria = {
  enviar: async (termoVistoria: ITermoVistoria) => {
    const termo = await api.post("/vegetal/termos-vistoria", termoVistoria);
    return termo;
  },
  byId: async (id: number) => {
    const { data } = await api.get<ITermoVistoria>(
      `/vegetal/termos-vistoria/${id}`
    );
    return data;
  },
  remover: async (id: number, motivo: string) => {
    const termoVistoria = await api.post(
      `/vegetal/termos-vistoria/${id}/cancelar`,
      {
        motivo: motivo,
      }
    );
    return termoVistoria;
  },
  listar: async (params: string) => {
    const { data } = await api.get<IListagemTermoVistoria>(
      `/vegetal/termos-vistoria?${params}`
    );
    return data;
  },

  update: async (dados: IDadosAssinaturaTermoVistoria, termoId: number) => {
    const { data } = await api.put<ITermoVistoria>(
      `/vegetal/termos-vistoria/${termoId}`,
      dados
    );
    return data;
  },
};
