import axios from "axios";
import { getArmadilha } from "./http/getArmadilha";
import { getCategorias } from "./http/getCategorias";
import { getContaminantes } from "./http/getContaminantes";
import { getEmbalagens } from "./http/getEmbalagens";
import { getEmpresasAgrotoxicos } from "./http/getEmpresasAgrotoxicos";
import { getEspecies } from "./http/getEspecies";
import { getEstabelecimentos } from "./http/getEstabelecimentos";
import { getEstadiosFenologicos } from "./http/getEstadiosFenologicos";
import { getFormulariosPerguntas } from "./http/getFormulariosPerguntas";
import { getMateriaisColetados } from "./http/getMateriaisColetados";
import { getMateriaisInertes } from "./http/getMateriaisInertes";
import { getMenus } from "./http/getMenus";
import { getMunicipios } from "./http/getMunicipios";
import { getPessoaAutorizadaAdquirirAgrotoxico } from "./http/getPessoaAutorizadaAdquirirAgrotoxico";
import { getPessoas } from "./http/getPessoas";
import { getProdutosAgrotoxicos } from "./http/getProdutosAgrotoxicos";
import { getProgramas } from "./http/getProgramas";
import { getPropriedades } from "./http/getPropriedades";
import { getServidores } from "./http/getServidores";
import { getTestesAnalise } from "./http/getTestesAnalise";
import { getUles } from "./http/getUles";
import { getUnidadesMedidas } from "./http/getUnidadesMedidas";
import { getUserController } from "./http/getUserController";
import { httpAmostraSemente } from "./http/httpAmostraSemente";
import { httpAtividadeInterdicao } from "./http/httpAtividadeInterdicao";
import { httpBoletimAmostra } from "./http/httpBoletimAmostra";
import { httpPerfil } from "./http/httpPerfil";
import { httpTermoApreensaoLiberacao } from "./http/httpTermoApreensaoLiberacao";
import { httpTermoColeta } from "./http/httpTermoColeta";
import { httpTermoVistoria } from "./http/httpTermoVistoria";
import { httpTid } from "./http/httpTid";
import { httpTif } from "./http/httpTif";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  },
});

export const apiService = {
  pessoas: getPessoas,
  municipios: getMunicipios,
  programas: getProgramas,
  servidores: getServidores,
  propriedades: getPropriedades,
  estabelecimentos: getEstabelecimentos,
  ules: getUles,
  tif: httpTif,
  termoColeta: httpTermoColeta,
  termoApreensaoLiberacao: httpTermoApreensaoLiberacao,
  termoVistoria: httpTermoVistoria,
  tid: httpTid,
  especie: getEspecies,
  materialColetado: getMateriaisColetados,
  estadiosFenologicos: getEstadiosFenologicos,
  categorias: getCategorias,
  unidadeMedida: getUnidadesMedidas,
  empresasAgrotoxicos: getEmpresasAgrotoxicos,
  embalagens: getEmbalagens,
  produtosAgrotoxico: getProdutosAgrotoxicos,
  listagemFormularios: getFormulariosPerguntas,
  menu: getMenus,
  perfis: httpPerfil,
  userController: getUserController,
  amostraSemente: httpAmostraSemente,
  testesAnalise: getTestesAnalise,
  contaminantes: getContaminantes,
  materialInerteBoletim: getMateriaisInertes,
  boletimAmostra: httpBoletimAmostra,
  armadilha: getArmadilha,
  atividadesInterdicao: httpAtividadeInterdicao,
  pessoaAutorizadaAdquirirAgrotoxico: getPessoaAutorizadaAdquirirAgrotoxico,
};
