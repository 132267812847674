import {
  CloseOutlined,
  DownloadOutlined,
  EyeOutlined,
  MenuOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { ModalCancelarTermo } from "@components/ModalCancelarTermo";
import { openNotification } from "@components/Notifications";
import { usePermission } from "@hooks/usePermission";
import { IContentTermoVistoria } from "@interfaces/IListagemTermoVistoria";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import { apiService } from "@services/api";
import { Button, Dropdown, List, Menu, Tooltip } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useActionsTermoVistoria } from "../../../hooks/useActionsTermoVistoria";
import styles from "../../style.module.css";

const CODIGO_TERMO = "TermoVistoria";

export function ItemListaFiltroTV({
  item,
  pageControl,
}: {
  item: IContentTermoVistoria;
  pageControl: number;
}) {
  const navigate = useNavigate();
  const { isAllowed } = usePermission();
  const [termoSelected, setTermoSelected] = useState<ITermoVistoria | null>(
    null
  );
  const {
    listaTermos,
    adicionarTermoByFiltro,
    listaFiltroTermos,
    aplicarFiltro,
    removerTermo,
  } = useActionsTermoVistoria();
  const [isDownload, setIsDownload] = useState(false);

  //VISUALIZAR
  const handleViewTermo = () => {
    localStorage.setItem("termoVistoria:pageSelect", pageControl.toString());
    navigate(`/termo-vistoria/listagem/visualizar/${item.id}`);
  };

  //IMPRIMIR
  const handlePrintedTermo = () => {
    localStorage.setItem("termoVistoria:pageSelect", pageControl.toString());
    navigate(`/termo-vistoria/listagem/impressao/${item.id}`);
  };

  //BAIXAR TERMO DO FILTRO
  const handleDownloadTermo = async () => {
    if (listaTermos.some((termo) => termo.id === item.id)) {
      openNotification({
        type: "error",
        message: "Termo de Vistoria já adicionado no SISDEV-mobile.",
      });
      return;
    }

    setIsDownload(true);
    await apiService.termoVistoria.byId(item.id).then((data) => {
      adicionarTermoByFiltro(data);
      openNotification({
        type: "success",
        message: `Termo de Vistoria ${item.numero} baixado com sucesso!`,
      });
    });

    setIsDownload(false);
  };

  //CANCELAR TERMO
  const handleCancelTermo = async () => {
    await apiService.termoVistoria
      .byId(item.id)
      .then((data) => {
        setTermoSelected(data);
      })
      .catch((erro) => {
        console.log(erro);
        openNotification({
          type: "error",
          message: "Não foi possivel cancelar o TIF",
          description:
            erro.response.status === 401
              ? "Tempo de login expirado"
              : erro.response.data.detail,
        });
      });
  };

  const recarregarFiltro = async () => {
    const params = new URLSearchParams(listaFiltroTermos[0].params);

    params.append("page", (pageControl - 1).toString());

    await apiService.termoVistoria
      .listar(params.toString())
      .then((data) => {
        if (data.content.length > 0) {
          aplicarFiltro([
            {
              filtros: listaFiltroTermos[0].filtros,
              params: listaFiltroTermos[0].params.toString(),
              termos: data.content,
              numberPages: data.totalPages,
            },
          ]);
        }
      })
      .catch((erro) => {
        console.log(erro);
        openNotification({
          type: "error",
          message: "Não foi possivel atualizar o filtro",
        });
      });

    localStorage.setItem("termoVistoria:pageSelect", pageControl.toString());
  };

  return (
    <>
      <List.Item
        className="lista-item"
        style={{
          border: termoSelected ? "1px solid #ff4d4f" : "",
        }}
      >
        <List.Item.Meta
          title={
            <span
              className="titulo-card"
              style={{
                color: termoSelected ? "#ff4d4f" : "#0D0D0D",
              }}
            >
              Termo de Vistoria {item.numero} {item.ativo ? "" : "(Cancelado)"}
            </span>
          }
          description={
            <span className="subtitulo-card">ID:{item.id ? item.id : ""}</span>
          }
        />

        <div className={styles["pc-menu-lista-termos"]}>
          <Tooltip title="Baixar para uso offline" destroyTooltipOnHide>
            <Button
              disabled={
                !item.ativo ||
                !navigator.onLine ||
                isDownload ||
                !item.statusAssinaturaFiscalizado
              }
              onClick={handleDownloadTermo}
            >
              <DownloadOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Imprimir" destroyTooltipOnHide>
            <Button
              disabled={
                !navigator.onLine ||
                isDownload ||
                !item.statusAssinaturaFiscalizado
              }
              onClick={handlePrintedTermo}
            >
              <PrinterOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Visualizar">
            <Button
              disabled={
                !item.ativo ||
                !navigator.onLine ||
                isDownload ||
                !isAllowed("visualizar", CODIGO_TERMO) ||
                !item.statusAssinaturaFiscalizado
              }
              onClick={handleViewTermo}
            >
              <EyeOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Cancelar" destroyTooltipOnHide>
            <Button
              disabled={
                !item.ativo ||
                !navigator.onLine ||
                isDownload ||
                !isAllowed("excluir", CODIGO_TERMO) ||
                !item.statusAssinaturaFiscalizado
              }
              danger
              onClick={handleCancelTermo}
            >
              <CloseOutlined />
            </Button>
          </Tooltip>
        </div>

        <div className={styles["mobile-menu-lista-termos"]}>
          <Dropdown
            placement="bottom"
            trigger={["click"]}
            overlay={
              <Menu
                items={[
                  {
                    key: 0,
                    label: "Baixar para uso offline",
                    disabled:
                      !navigator.onLine ||
                      isDownload ||
                      !item.statusAssinaturaFiscalizado,
                    icon: <DownloadOutlined />,
                    onClick: (e) => {},
                  },
                  {
                    key: 1,
                    label: "Imprimir",
                    disabled:
                      !navigator.onLine ||
                      isDownload ||
                      !item.statusAssinaturaFiscalizado,
                    icon: <PrinterOutlined />,
                    onClick: (e) => handlePrintedTermo(),
                  },
                  {
                    key: 2,
                    label: "Visualizar",
                    disabled:
                      !navigator.onLine ||
                      isDownload ||
                      !isAllowed("visualizar", CODIGO_TERMO) ||
                      !item.statusAssinaturaFiscalizado,
                    icon: <EyeOutlined />,
                    onClick: (e) => handleViewTermo(),
                  },
                  {
                    key: 3,
                    label: "Cancelar",
                    disabled:
                      !navigator.onLine ||
                      isDownload ||
                      !isAllowed("excluir", CODIGO_TERMO) ||
                      !item.statusAssinaturaFiscalizado,
                    icon: <CloseOutlined />,
                    onClick: () => handleCancelTermo(),
                  },
                ]}
              />
            }
          >
            <Tooltip title="Opções" destroyTooltipOnHide>
              <Button>
                <MenuOutlined />
              </Button>
            </Tooltip>
          </Dropdown>
        </div>
      </List.Item>

      {termoSelected && (
        <ModalCancelarTermo
          isOpen={termoSelected ? true : false}
          setIsOpen={() => setTermoSelected(null)}
          numTermo={termoSelected.numero}
          reloadFilter={recarregarFiltro}
          idTermo={termoSelected.id}
          onRemoveTermo={removerTermo}
          titleTermo="Termo de Vistoria"
          apiName="termoVistoria"
          isFilterApplied
        />
      )}
    </>
  );
}
