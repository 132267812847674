import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import { Checkbox, Form } from "antd";

export function MultiplaEscolha({
  pergunta,
  termo,
}: {
  pergunta: IPerguntaTermoVistoria;
  termo: ITermoVistoria;
}) {
  return (
    <>
      <Form.Item
        label="Multipla Escolha"
        labelCol={{ flex: "100px", span: 20 }}
      >
        <Checkbox.Group
          value={termo.respostas
            .find((res) => res.pergunta.id === pergunta.pergunta.id)
            ?.alternativas.map((alt) => alt.alternativa.id)}
        >
          {pergunta.pergunta.alternativas.map((alt, i) => {
            if (alt.ativo) {
              return (
                <Checkbox value={alt.id} key={i}>
                  {alt.nome}
                </Checkbox>
              );
            }

            return null;
          })}
        </Checkbox.Group>
      </Form.Item>
    </>
  );
}
