import {
  CloseOutlined,
  DownloadOutlined,
  EyeOutlined,
  MenuOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { ModalCancelarTermo } from "@components/ModalCancelarTermo";
import { openNotification } from "@components/Notifications";
import { usePermission } from "@hooks/usePermission";
import { IContentListagemTID } from "@interfaces/IListagemTID";
import { ITID } from "@interfaces/ITid";
import { apiService } from "@services/api";
import { Button, Dropdown, List, Menu, message, Tooltip } from "antd";
import { AxiosError } from "axios";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useActionsTID } from "../../../hooks/useActionsTID";
import { useFunctionsTID } from "../../../hooks/useFunctionsTID";
import styles from "../../style.module.css";

const CODIGO_TERMO = "TID";

export function ItemListaFiltroTID({
  item,
  pageControl,
}: {
  item: IContentListagemTID;
  pageControl: number;
}) {
  const tipoTermoFormatado =
    item.tipoTermo === "INTERDICAO" ? "Interdição" : "Desinterdição";
  const navigate = useNavigate();
  const { isAllowed } = usePermission();
  const { getAnexo } = useFunctionsTID();
  const {
    listaFiltroTID,
    listaTIDs,
    adicionarTermoSubmited,
    aplicarFiltro,
    removerTermo,
  } = useActionsTID();

  const [termoSelected, setTermoSelected] = useState<ITID | null>(null);
  const [isDownload, setIsDownload] = useState(false);

  // VISUALIZAR TERMO
  const handleViewTermo = () => {
    if (!item.id) return;
    navigate(`/TID/listagem/visualizar/${item.id}`);
    localStorage.setItem("TID:pageSelect", pageControl.toString());
  };

  //CANCELAR TERMO
  const handleCancelTermo = async () => {
    if (!item.id) return;

    await apiService.tid.byId(item.id).then((data) => {
      setTermoSelected(data);
    });
  };

  //BAIXAR TERMO
  const handleDownloadTermo = async (termoId: number) => {
    const termo = listaTIDs.find((auxTermo) => auxTermo.id === termoId);
    const keyMessage = "download-tid";

    if (termo) {
      openNotification({
        type: "info",
        message: `Não foi possivel baixar Termo de ${tipoTermoFormatado}`,
        description: "Termo já adicionado no SISDEV-Moblie",
      });
      return;
    }

    message.loading({
      key: keyMessage,
      duration: 0,
      content: `Baixando Termo de ${tipoTermoFormatado}...`,
    });
    setIsDownload(true);

    try {
      const responseTid = await apiService.tid.byId(termoId);

      const dadosAnexos = responseTid.anexos.map((anexo) => {
        return {
          nomeUpload: anexo.nomeUpload,
          nomeArquivo: anexo.nomeArquivo,
        };
      });

      if (dadosAnexos.length <= 0) {
        adicionarTermoSubmited({
          ...responseTid,
          anexos: [],
        });
      } else {
        const anexos = await getAnexo(dadosAnexos);
        // console.log(anexos);

        adicionarTermoSubmited({
          ...responseTid,
          anexos: anexos,
        });
      }

      openNotification({
        type: "success",
        message: `Termo de ${tipoTermoFormatado} ${responseTid.numero} foi baixado com sucesso!`,
      });

      setIsDownload(false);
      message.destroy(keyMessage);
    } catch (error) {
      console.log(error);
      setIsDownload(false);
      message.destroy(keyMessage);

      if (error instanceof AxiosError) {
        openNotification({
          type: "error",
          message: `Não foi possivel baixar Termo de ${tipoTermoFormatado}`,
          description: `Erro ${error.response?.status}: ${error.response?.data.title}.`,
        });
        return;
      }

      openNotification({
        type: "error",
        message: `Não foi possivel baixar Termo de ${tipoTermoFormatado}`,
        description: "Erro desconhecido.",
      });
    }
  };

  const handlePrintedTermo = async () => {
    navigate(`/TID/listagem/impressao/${item.id}`);
    localStorage.setItem("TID:pageSelect", pageControl.toString());
    return;
  };

  const recarregarFiltro = async () => {
    const params = new URLSearchParams(listaFiltroTID[0].params);
    params.append("page", (pageControl - 1).toString());

    await apiService.tid
      .listar(params.toString())
      .then((data) => {
        if (data.content.length > 0) {
          aplicarFiltro([
            {
              filtros: listaFiltroTID[0].filtros,
              params: listaFiltroTID[0].params.toString(),
              termos: data.content,
              numberPages: data.totalPages,
            },
          ]);
        }
      })
      .catch((erro) => {
        console.log(erro);
        openNotification({
          type: "error",
          message: "Não foi possivel atualizar o filtro",
        });
      });

    localStorage.setItem("TID:pageSelect", pageControl.toString());
  };

  return (
    <>
      <List.Item
        className="lista-item"
        style={{
          border: termoSelected ? "1px solid#ff4d4f" : "",
        }}
      >
        <List.Item.Meta
          title={
            <span
              className="titulo-card"
              style={{
                color: termoSelected ? "#ff4d4f" : "#0D0D0D",
              }}
            >
              Termo de{" "}
              {item.tipoTermo === "INTERDICAO" ? "Interdição" : "Desinterdição"}{" "}
              {item.numero} {item.ativo ? "" : "(Cancelado)"}
            </span>
          }
          description={
            <span className="subtitulo-card">
              Data Fiscalização:
              {moment(item.dataFiscalizacao || "").format(
                "DD/MM/YYYY"
              )} ID: {item.id}
            </span>
          }
        />

        <div className={styles["pc-menu-lista-termos"]}>
          <Tooltip title="Baixar para uso offline" destroyTooltipOnHide>
            <Button
              disabled={
                !item.ativo ||
                !navigator.onLine ||
                !item.statusAssinaturaFiscalizado ||
                isDownload
              }
              onClick={() => handleDownloadTermo(item.id)}
            >
              <DownloadOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Imprimir" destroyTooltipOnHide>
            <Button
              disabled={
                !navigator.onLine ||
                !item.statusAssinaturaFiscalizado ||
                isDownload
              }
              onClick={(e) => handlePrintedTermo()}
            >
              <PrinterOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Visualizar">
            <Button
              disabled={
                !item.ativo ||
                !navigator.onLine ||
                !isAllowed("visualizar", CODIGO_TERMO) ||
                !item.statusAssinaturaFiscalizado ||
                isDownload
              }
              onClick={() => handleViewTermo()}
            >
              <EyeOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Cancelar" destroyTooltipOnHide>
            <Button
              disabled={
                !item.ativo ||
                !navigator.onLine ||
                !isAllowed("excluir", CODIGO_TERMO) ||
                !item.statusAssinaturaFiscalizado ||
                isDownload
              }
              danger
              onClick={() => handleCancelTermo()}
            >
              <CloseOutlined />
            </Button>
          </Tooltip>
        </div>

        <div className={styles["mobile-menu-lista-termos"]}>
          <Dropdown
            placement="bottom"
            trigger={["click"]}
            overlay={
              <Menu
                items={[
                  {
                    key: 0,
                    disabled:
                      !navigator.onLine ||
                      !item.statusAssinaturaFiscalizado ||
                      isDownload,
                    label: "Baixar",
                    icon: <DownloadOutlined />,
                    onClick: (e) => handleDownloadTermo(item.id),
                  },
                  {
                    key: 1,
                    label: "Imprimir",
                    disabled:
                      !navigator.onLine ||
                      !item.statusAssinaturaFiscalizado ||
                      isDownload,
                    icon: <PrinterOutlined />,
                    onClick: (e) => handlePrintedTermo(),
                  },
                  {
                    key: 2,
                    label: "Visualizar",
                    disabled:
                      !navigator.onLine ||
                      !isAllowed("visualizar", CODIGO_TERMO) ||
                      !item.statusAssinaturaFiscalizado ||
                      isDownload,
                    icon: <EyeOutlined />,
                    onClick: (e) => handleViewTermo(),
                  },
                  {
                    key: 3,
                    label: "Cancelar",
                    disabled:
                      !navigator.onLine ||
                      !isAllowed("excluir", CODIGO_TERMO) ||
                      !item.statusAssinaturaFiscalizado ||
                      isDownload,
                    icon: <CloseOutlined />,
                    onClick: () => handleCancelTermo(),
                  },
                ]}
              />
            }
          >
            <Tooltip title="Opções" destroyTooltipOnHide>
              <Button>
                <MenuOutlined />
              </Button>
            </Tooltip>
          </Dropdown>
        </div>
      </List.Item>

      {termoSelected && (
        <ModalCancelarTermo
          isOpen={!!termoSelected}
          setIsOpen={() => setTermoSelected(null)}
          numTermo={termoSelected?.numero}
          apiName="tid"
          idTermo={termoSelected.id}
          onRemoveTermo={removerTermo}
          titleTermo={`Termo de ${tipoTermoFormatado}`}
          reloadFilter={recarregarFiltro}
          isFilterApplied
        />
      )}
    </>
  );
}
