import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import moment from "moment";

export function Campo({
  pergunta,
  termo,
}: {
  pergunta: IPerguntaTermoVistoria;
  termo: ITermoVistoria;
}) {
  const resposta = termo.respostas.find(
    (res) => res.pergunta.id === pergunta.pergunta.id
  );

  return (
    <>
      {pergunta.pergunta.campos.map((campo, i) => {
        return (
          <p
            key={i}
            style={{
              borderBottom: "1px solid black",
            }}
          >
            <strong>{campo.nome}:</strong>{" "}
            {campo.formatoResposta === "DATE" && (
              <span>
                {moment(resposta?.campos[i].resposta).format("DD/MM/YYYY")}
              </span>
            )}
            {(campo.formatoResposta === "TEXTO" ||
              campo.formatoResposta === "NUMBER") && (
              <span>{resposta?.campos[i].resposta}</span>
            )}
          </p>
        );
      })}
    </>
  );
}
