import { IPessoaAutorizadaAdquirirAgrotoxico } from "@interfaces/IPessoaAutorizadaAdquiriAgrotoxico";
import { apiService } from "@services/api";
import { pessoaAutorizadaAdquirirAgrotoxicoAction } from "@store/actions/pessoaAutorizadaAdquirirAgrotoxicoAction";
import { getPessoaAutorizadaAdquirirAgrotoxico } from "@store/feature/pessoaAutorizadaAdquirirAgrotoxicoSlice";
import { useDispatch, useSelector } from "react-redux";

export const usePessoaAutorizadaAdquirirAgrotoxico = () => {
  const appDispatch = useDispatch();
  const {
    removeSyncPessoaAutorizadaAdquirirAgrotoxico,
    syncPessoaAutorizadaAdquirirAgrotoxico,
  } = pessoaAutorizadaAdquirirAgrotoxicoAction;
  const listaPessoasAutorizadas = useSelector(
    getPessoaAutorizadaAdquirirAgrotoxico
  );

  const syncPAAA = (
    pessoaAutorizada: IPessoaAutorizadaAdquirirAgrotoxico[],
    persistedAt: number
  ) => {
    appDispatch(
      syncPessoaAutorizadaAdquirirAgrotoxico(pessoaAutorizada, persistedAt)
    );
  };

  const removerPAAA = () => {
    appDispatch(removeSyncPessoaAutorizadaAdquirirAgrotoxico());
  };

  const getByCnpjPAAA = async (cnpj: string) => {
    if (navigator.onLine) {
      const data = await apiService.pessoaAutorizadaAdquirirAgrotoxico({
        cpfCnpj: cnpj,
      });
      return data[0];
    } else {
      return listaPessoasAutorizadas.find((pessoa) => pessoa.cpfCnpj === cnpj);
    }
  };

  return { syncPAAA, removerPAAA, listaPessoasAutorizadas, getByCnpjPAAA };
};
