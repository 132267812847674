import { LoadingOutlined } from "@ant-design/icons";
import { TIPO_IMAGENS } from "@constants/TIPO_IMAGENS";
import { IAnexos } from "@interfaces/IAnexos";
import { ITermoApreensaoLiberacao } from "@interfaces/ITermoApreensaoLiberacao";
import { apiService } from "@services/api";
import { Button, Spin, Typography } from "antd";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useReactToPrint } from "react-to-print";
import { useActionsTAL } from "../../hooks/useActionsTAL";
import { useTAL } from "../../hooks/useTAL";
import { BodyTermoApreensaoLiberacaoImpressao } from "./components/BodyTermoApreensaoLiberacaoImpressao";
import { FooterTermoApreensaoLiberacaoImpressao } from "./components/FooterTermoApreensaoLiberacaoImpressao";
import { HeaderTermoApreensaoLiberacaoImpressao } from "./components/HeaderTermoApreensaoLiberacaoImpressao";
import { ImagesTermoApreensaoLiberacaoImpressao } from "./components/ImagesTermoApreensaoLiberacaoImpressao";
import styles from "./style.module.css";

export function VisualizacaoImpressaoTermoApreensaoLiberacao() {
  const { termoId } = useParams();
  const navigate = useNavigate();
  const { getAnexo } = useTAL();
  const { listaTermos } = useActionsTAL();

  const componentRef = useRef<any>(null);
  const [anexosImages, setAnexosImages] = useState<IAnexos[]>([]);
  const [termoSelected, setTermoSelected] =
    useState<ITermoApreensaoLiberacao>();

  const [loading, setLoading] = useState(false);

  const procurarTermoPorId = async (id: number) => {
    setLoading(true);
    const termoApreensaoLiberacao = listaTermos.filter(
      (termo) => termo.id === id
    )[0];

    if (termoApreensaoLiberacao) {
      const anexosTypeImage =
        termoApreensaoLiberacao.anexos.filter((anexo) =>
          TIPO_IMAGENS.includes(
            anexo.nomeArquivo.split(".")[
              anexo.nomeArquivo.split(".").length - 1
            ]
          )
        ) || [];

      setTermoSelected(termoApreensaoLiberacao);
      setAnexosImages(anexosTypeImage);
    } else {
      const aux = await apiService.termoApreensaoLiberacao.byId(id);

      if (aux.anexos.length > 0) {
        const dadosAnexos = aux.anexos.map((anexo) => {
          return {
            nomeUpload: anexo.nomeUpload,
            nomeArquivo: anexo.nomeArquivo,
          };
        });

        const responseAnexos = await getAnexo(dadosAnexos);

        responseAnexos.forEach((anexo) => {
          if (
            TIPO_IMAGENS.includes(
              anexo.nomeArquivo.split(".")[
                anexo.nomeArquivo.split(".").length - 1
              ]
            )
          ) {
            anexosImages.push(anexo);
            setAnexosImages(anexosImages);
          }
        });
      }

      setTermoSelected(aux);
    }

    setLoading(false);
  };

  const procurarTermoPorNumero = (numTermo: string | undefined) => {
    setLoading(true);
    const termoApreensaoLiberacao = listaTermos.filter(
      (tif) => tif.numero === numTermo?.replaceAll("_", "/")
    );

    setTermoSelected(termoApreensaoLiberacao[0]);
    const anexosTypeImage =
      termoApreensaoLiberacao[0].anexos.filter((anexo) =>
        TIPO_IMAGENS.includes(
          anexo.nomeArquivo.split(".")[anexo.nomeArquivo.split(".").length - 1]
        )
      ) || [];

    setAnexosImages(anexosTypeImage);
    setLoading(false);
  };

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    pageStyle: "@page { size: A4 !important;}",
    documentTitle: `Termo ${termoSelected?.numero}`,
    suppressErrors: true,
    preserveAfterPrint: true,
  });

  useEffect(() => {
    const isID = parseInt(termoId || "");

    if (termoId?.match("_")) {
      procurarTermoPorNumero(termoId);
    } else {
      procurarTermoPorId(isID);
    }
    //eslint-disable-next-line
  }, []);

  if (loading && !termoSelected) {
    return (
      <>
        <div className={styles["container-loading"]}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      </>
    );
  }

  return (
    <Fragment>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Impressão Termo{" "}
        {termoSelected?.tipoTermo === "APREENSAO" ? "Apreensão" : "Liberação"}
      </Typography.Title>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: 20,
          paddingBottom: 20,
        }}
      >
        <Button type="primary" onClick={() => handlePrint()}>
          Imprimir
        </Button>

        <Button onClick={() => navigate("/termo-apreensao-liberacao/listagem")}>
          Voltar
        </Button>
      </div>

      <div className={styles["container-termo-apreensao-liberacao-impressao"]}>
        {termoSelected && (
          <div
            ref={componentRef}
            style={{ display: "flex", flexDirection: "column", gap: 20 }}
          >
            <div className="marcadagua"></div>
            <table
              style={{ width: 780 }}
              className="termo-apreensao-liberacao-impressao-font"
            >
              <HeaderTermoApreensaoLiberacaoImpressao
                numTermo={termoSelected?.numero || ""}
                tipoTermo={termoSelected?.tipoTermo || ""}
              />
              <BodyTermoApreensaoLiberacaoImpressao
                termo={termoSelected}
                propriedade={termoSelected.tif?.nomePropriedade || ""}
              />
              <FooterTermoApreensaoLiberacaoImpressao termo={termoSelected} />
            </table>

            {anexosImages.map((anexo, ind) => {
              return (
                <ImagesTermoApreensaoLiberacaoImpressao
                  key={ind}
                  termo={termoSelected}
                  anexo={anexo}
                />
              );
            })}
          </div>
        )}
      </div>
    </Fragment>
  );
}
