import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import { DatePicker, Form, Input } from "antd";
import moment from "moment";
import styles from "../../style.module.css";

export function Campo({
  pergunta,
  termo,
}: {
  pergunta: IPerguntaTermoVistoria;
  termo: ITermoVistoria;
}) {
  return (
    <>
      {pergunta.pergunta.campos.map((campo, i) => {
        return (
          <div
            style={{
              display: "flex",
              gap: 5,
              flexDirection: "column",
            }}
            key={i}
          >
            <label className={styles["label-campo-vistoria"]}>
              {campo.nome}
            </label>
            {campo.formatoResposta === "DATE" && (
              <Form.Item key={i} noStyle={false}>
                <DatePicker
                  style={{ maxWidth: 400 }}
                  value={moment(
                    termo.respostas.find(
                      (res) => res.pergunta.id === pergunta.pergunta.id
                    )?.campos[i].resposta
                  )}
                />
              </Form.Item>
            )}

            {(campo.formatoResposta === "TEXTO" ||
              campo.formatoResposta === "NUMBER") && (
              <Form.Item key={i} noStyle={false}>
                <Input
                  style={{ maxWidth: 400 }}
                  value={
                    termo.respostas.find(
                      (res) => res.pergunta.id === pergunta.pergunta.id
                    )?.campos[i].resposta
                  }
                />
              </Form.Item>
            )}
          </div>
        );
      })}
    </>
  );
}
