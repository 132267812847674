import { IArmadilha } from "@interfaces/IArmadilha";
import { api } from "@services/api";

export const getArmadilha = async (municipioId: number) => {
  const params = new URLSearchParams();
  params.append("codigoIbge", municipioId.toString());

  const { data } = await api.get<IArmadilha[]>(`/armadilhas?${params}`);
  return data;
};
