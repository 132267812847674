import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { DatePicker, Form, FormInstance, Input } from "antd";

export function Descritiva({
  pergunta,
  perguntasForm,
}: {
  pergunta: IPerguntaAtividade;
  perguntasForm: FormInstance;
}) {
  return (
    <div
      style={{
        display: "flex",
        gap: 5,
        flexDirection: "column",
      }}
    >
      <label>ESPECIFICAR:</label>
      {(pergunta.pergunta.formatoResposta === "TEXTO" ||
        pergunta.pergunta.formatoResposta === "NUMBER") && (
        <Form.Item noStyle={true}>
          <Input
            value={perguntasForm.getFieldValue(
              `listaPerguntas.${pergunta.pergunta.id}.descricao`
            )}
            style={{ maxWidth: 400 }}
          />
        </Form.Item>
      )}

      {pergunta.pergunta.formatoResposta === "DATE" && (
        <Form.Item noStyle={true}>
          <DatePicker
            value={perguntasForm.getFieldValue(
              `listaPerguntas.${pergunta.pergunta.id}.descricao`
            )}
          />
        </Form.Item>
      )}
    </div>
  );
}
