import { ExclamationCircleOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { TabelaServidoresForm } from "@components/TabelaServidoresForm";
import { UploadFilesForm } from "@components/UploadFilesForm";
import { ASSINATURA_FISICA } from "@constants/ASSINATURA_FISICA";
import { converterBase64ToFile } from "@functions/converterBase64ToFile";
import { getAnexosFormatadosBase64 } from "@functions/getAnexosFormatadosBase64";
import { masks } from "@functions/mascaras";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { transformStringToNumber } from "@functions/transformStringToNumber";
import { useDadosTif } from "@hooks/useDadosTif";
import { useEstabelecimento } from "@hooks/useEstabelecimento";
import { useForms } from "@hooks/useForms";
import { useMunicipio } from "@hooks/useMunicipio";
import { usePessoaAutorizadaAdquirirAgrotoxico } from "@hooks/usePessoaAutorizadaAdquirirAgrotoxico";
import { usePropriedade } from "@hooks/usePropriedade";
import { useServidores } from "@hooks/useServidores";
import { useUle } from "@hooks/useUle";
import { IAssinante } from "@interfaces/IAssinante";
import { IFormValuesTif } from "@interfaces/IFormValuesTif";
import { IServidorForm } from "@interfaces/IServidorForm";
import { ITif } from "@interfaces/ITif";
import { apiService } from "@services/api";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Result,
  Typography,
} from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import confirm from "antd/lib/modal/confirm";
import { AxiosError } from "axios";
import fs from "file-saver";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AssinaturasTIF } from "../components/AssinaturasTIF";
import { AtividadesTIF } from "../components/AtividadesTIF";
import { RegistroTIF } from "../components/RegistroTIF";
import { tifAssinaturaError } from "../errors/tifAssinaturaError";
import { tifAtividadeError } from "../errors/tifAtividadeError";
import { tifAtividadesEmptyError } from "../errors/tifAtividadesEmptyError";
import { tifDadosAssinanteError } from "../errors/tifDadosAssinanteError";
import { tifSemPropriedadeError } from "../errors/tifSemPropriedadeError";
import { tifServidorGridError } from "../errors/tifServidorGridError";
import { converterTIFtoFormData } from "../functions/converterTIFtoFormData";
import { organizarListaAtividade } from "../functions/organizarListaAtividade";
import { useTif } from "../hooks/useTif";

export function EditFormTif() {
  const { numTIF } = useParams();
  const navigate = useNavigate();
  const { messageSubmitFailed } = useForms();
  const { cepMask, cpfCnpjMask, coordMask, telefoneMask } = masks;

  const [formAtividades] = Form.useForm();
  const [formServidores] = Form.useForm();
  const [formRegistro] = Form.useForm<IFormValuesTif>();
  const [infoForm] = Form.useForm();
  const [modal, contextHolderModal] = Modal.useModal();
  const { buscarPropriedadePeloCpfCnpj } = usePropriedade();
  const { buscarEstabelecimentoPeloCnpj } = useEstabelecimento();
  const { getByCnpjPAAA } = usePessoaAutorizadaAdquirirAgrotoxico();
  const { getMunicipioPorId } = useMunicipio();
  const {
    servidoresSelecionados,
    selecionarServidor,
    removerTodosServidoresSelecionados,
  } = useServidores();
  const {
    respostasAtividades,
    atividadesTif,
    adicionarAtividade,
    removerTodasAtividades,
    adicionarRespostaAtividade,
  } = useDadosTif();
  const { getUle } = useUle();
  const { adicionarTif, removerTif, atualizarTif, listaTif } = useTif();

  const tifSelected = listaTif.find(
    (tif) => tif.numero === numTIF?.replaceAll("_", "/")
  );
  const [estabelecimentoId, setEstabelecimentoId] = useState<number>();
  const [pessoaAutorizadaId, setPessoaAutorizadaId] = useState<number>();
  const [anexos, setAnexos] = useState<any[]>([]);
  const [isSavingTif, setIsSavingTif] = useState(false);
  const [servidores, setServidores] = useState<IServidorForm[]>([]);
  const [motivoNaoAssinaturaFiscalizado, setMotivoNaoAssinaturaFiscalizado] =
    useState<CheckboxValueType[]>([]);

  const [fiscalizadoSignature, setFiscalizadoSignature] = useState<IAssinante>({
    assinatura: null,
    nomeAssinante: tifSelected?.nomeAssinante || "",
    cpfAssinante: tifSelected?.cpfAssinante || "",
    funcaoAssinante: tifSelected?.funcaoAssinante || "",
  });
  const [municipioFiscalizacaoId, setMunicipioFiscalizacaoId] =
    useState<number>();

  const termoInEdit = (form: ITif) => {
    const auxTermo: string[] = [];

    if (form.fiscalizacao) auxTermo.push("FISCALIZACAO");
    if (form.inspecao) auxTermo.push("INSPECAO");
    if (form.notificacao) auxTermo.push("NOTIFICACAO");

    return auxTermo;
  };

  const handleSubmit = async (values: IFormValuesTif) => {
    const keyMessage = "tif";
    if (!tifSelected) return;
    const cpfCnpjFiscalizado = values.cpfCnpjFiscalizado.replace(/[^\d]+/g, "");

    const atvQuestionsEmpty = atividadesTif.filter((atv) => {
      if (atv.atividade.questoes.length > 0) {
        const { id } = atv.atividade;

        if (atv.armadilha) {
          if (
            respostasAtividades.some(
              (res) => res.armadilha?.id === atv.armadilha?.id
            )
          ) {
            return null;
          }
          return atv;
        } else {
          if (respostasAtividades.some((res) => res.atividade.id === id)) {
            return null;
          }
          return atv;
        }
      }

      return null;
    });

    const dadosAssinante = Object.entries(fiscalizadoSignature).filter(
      (item) => item[0] !== "assinatura"
    );

    //VALIDACOES

    if (!estabelecimentoId && !values.propriedadeId && !values.propEstab) {
      tifSemPropriedadeError();
      return;
    }

    if (atividadesTif.length === 0) {
      tifAtividadesEmptyError();
      return;
    }

    if (servidoresSelecionados.length === 0) {
      tifServidorGridError();
      return;
    }

    if (!isSignaturesFilled()) {
      tifAssinaturaError();
      return;
    }

    if (
      dadosAssinante.some((assinante) => !assinante[1]) &&
      (motivoNaoAssinaturaFiscalizado.length === 0 ||
        motivoNaoAssinaturaFiscalizado[0] === ASSINATURA_FISICA)
    ) {
      tifDadosAssinanteError();
      return;
    }

    if (atvQuestionsEmpty.length > 0) {
      tifAtividadeError();
      return;
    }

    try {
      const servidoresFormTif = servidores.map((serv) => {
        return {
          assinatura:
            getStatusAssinatura(motivoNaoAssinaturaFiscalizado) ===
            ASSINATURA_FISICA
              ? ""
              : serv.assinatura,
          servidor: {
            cpfCnpj: serv.servidor.cpfCnpj,
            id: serv.servidor.id,
            inscricaoEstadual: serv.servidor.inscricaoEstadual,
            matricula: serv.servidor.matricula,
            nome: serv.servidor.nome,
          },
        };
      });

      let formValues: ITif = {
        statusAssinatura: getStatusAssinatura(motivoNaoAssinaturaFiscalizado),
        amparoLegal: infoForm.getFieldValue("providencias") || "",
        assinatura:
          motivoNaoAssinaturaFiscalizado.length === 0
            ? fiscalizadoSignature?.assinatura
                ?.getTrimmedCanvas()
                .toDataURL() || ""
            : "",
        atividades: organizarListaAtividade(atividadesTif),
        caracteristicaFiscalizado: values.atuado || "PROPRIEDADE",
        cepFiscalizado: values.cepFiscalizado || "",
        cepPropriedade: values.cepPropriedade || "",
        constatacao: infoForm.getFieldValue("constatacao") || "",
        cpfCnpjFiscalizado: cpfCnpjFiscalizado,
        dataFiscalizacao: moment(values.dataFiscalizar)
          .locale("pt-br")
          .format()
          .slice(0, 10),
        emailFiscalizado: values.emailFiscalizado || "",
        enderecoFiscalizado: values.enderecoFiscalizado || "",
        enderecoPropriedade: values.enderecoPropriedade || "",
        fiscalizacao: values.termo.includes("FISCALIZACAO"),
        fiscalizacaoFronteira: values.fiscalizacaoFronteira === "SIM",
        inscricaoEstadualFiscalizado: values.inscricaoEstadualFiscalizado || "",
        inspecao: values.termo.includes("INSPECAO"),
        latGrauPropriedade:
          transformStringToNumber(values.latGrauPropriedade || "") || 0,
        latMinPropriedade:
          transformStringToNumber(values.latMinPropriedade || "") || 0,
        latSegPropriedade:
          transformStringToNumber(values.latSegPropriedade || "") || 0,
        longGrauPropriedade:
          transformStringToNumber(values.longGrauPropriedade || "") || 0,
        longMinPropriedade:
          transformStringToNumber(values.longMinPropriedade || "") || 0,
        longSegPropriedade:
          transformStringToNumber(values.longSegPropriedade || "") || 0,
        municipioFiscalizado: getMunicipioPorId(
          values.municipioFiscalizadoId || undefined
        ),
        municipioFiscalizacao: getMunicipioPorId(
          values.municipioFiscalizacaoId || undefined
        ),
        nomeArquivo: tifSelected.nomeArquivo,
        nomeFiscalizado: values.nomeFiscalizado,
        nomePropriedade: values.propEstab || "",
        nomeUpload: `TIF ${tifSelected.numero}`,
        notificacao: values.termo.includes("NOTIFICACAO"),
        numero: tifSelected.numero,
        orientacaoLatitudePropriedade: "S",
        orientacaoLongitudePropriedade: "O",
        produtor: null,
        propriedade: null,
        renasemFiscalizado: values.renasemFiscalizado || "",
        estabelecimento: null,
        pessoaAutorizadaAdquirirAgrotoxico: null,
        servidores: servidoresFormTif,
        telefoneFiscalizado:
          values.telefoneFiscalizado?.replace(/[^\d]+/g, "") || "",
        tipoRepresentante: values.tipoRepresentante || "",
        ule: getUle(values.municipioFiscalizacaoId),
        anexosTIF: [],
        dataCadastro: tifSelected.dataCadastro,
        nomeAssinante: fiscalizadoSignature.nomeAssinante,
        cpfAssinante: fiscalizadoSignature.cpfAssinante.replace(/[^\d]+/g, ""),
        funcaoAssinante: fiscalizadoSignature.funcaoAssinante,
        respostasAtividades: respostasAtividades,
      };

      if (
        motivoNaoAssinaturaFiscalizado.length > 0 &&
        getStatusAssinatura(motivoNaoAssinaturaFiscalizado) !==
          ASSINATURA_FISICA
      ) {
        formValues = {
          ...formValues,
          nomeAssinante: "",
          funcaoAssinante: "",
          cpfAssinante: "",
        };
      }

      if (values.propriedadeId && !values.propEstab) {
        const propriedade = (
          await buscarPropriedadePeloCpfCnpj(cpfCnpjFiscalizado)
        ).find((prop) => prop.id === values.propriedadeId);

        if (propriedade) {
          formValues = {
            ...formValues,
            nomePropriedade: propriedade.nome,
            propriedade: propriedade,
            produtor: propriedade.produtores[0],
          };
        }
      }

      if (estabelecimentoId) {
        const estabelecimentoSelected = (
          await buscarEstabelecimentoPeloCnpj(cpfCnpjFiscalizado)
        ).find((estab) => estab.id === estabelecimentoId);

        if (estabelecimentoSelected) {
          formValues = {
            ...formValues,
            nomePropriedade: estabelecimentoSelected.nome,
            estabelecimento: estabelecimentoSelected,
          };
        }
      }

      if (pessoaAutorizadaId) {
        const pessoaAutorizada = await getByCnpjPAAA(cpfCnpjFiscalizado);

        if (pessoaAutorizada) {
          formValues = {
            ...formValues,
            nomePropriedade: pessoaAutorizada.nome,
            pessoaAutorizadaAdquirirAgrotoxico: pessoaAutorizada,
          };
        }
      }

      let anexosSubmitToTermo: (File | undefined)[] = [];

      if (anexos.length > 0) {
        await getAnexosFormatadosBase64(anexos).then((res) => {
          formValues = { ...formValues, anexosTIF: res };

          const aux = res.map((anexo) => {
            return converterBase64ToFile(anexo.arquivo, anexo.nomeArquivo);
          });

          anexosSubmitToTermo = aux.filter((anexo) => anexo !== undefined);
        });
      }

      const formData = converterTIFtoFormData(formValues, anexosSubmitToTermo);

      if (navigator.onLine) {
        setIsSavingTif(true);
        message.loading({
          key: keyMessage,
          duration: 0,
          content: "Salvando Termo...",
        });

        await apiService.tif.enviar(formData).then((data) => {
          atualizarTif({ ...formValues, id: data.id });
          message.destroy(keyMessage);
          setIsSavingTif(false);
          openNotification({
            type: "success",
            message: "TIF Atualizado com sucesso",
            description: "TIF salvo no banco de dados!",
          });

          navigate("/TIF/listagem");
        });
      } else {
        //MANDANDO O NUMERO ANTIGO DO TIF CASO TENHA MUDADO
        removerTif(tifSelected.numero);
        adicionarTif(formValues);

        modal.info({
          title: `Gostaria de baixar um backup do TIF?`,
          icon: <ExclamationCircleOutlined />,
          okText: "Sim",
          onOk: () => {
            let blob = new Blob([JSON.stringify(formValues)], {
              type: "text/plain;charset=utf-8",
            });
            fs.saveAs(blob, `TIF ${formValues.numero}.txt`, { autoBom: true });
            openNotification({
              type: "success",
              message: "TIF Atualizado com sucesso!",
              description:
                "Salvamento da edição do TIF pendente, para quando aplicativo houver conexão com a internet.",
            });

            //PODERIA SER NO FINAL E JUNTO COM A FUNCAO DENTRO DO CANCEL
            //POREM ESTAVA DANDO BUG NO EDITAR OFFLINE
            handleResetForm();
            navigate("/TIF/listagem");
          },
        });
      }
    } catch (error) {
      setIsSavingTif(false);
      message.destroy(keyMessage);

      if (error instanceof AxiosError) {
        openNotification({
          type: "error",
          message: "Não foi possivel editar TIF",
          description: `${error.response?.data.title}. Status: ${error.response?.status}`,
        });
        return;
      }

      openNotification({
        type: "error",
        message: "Não foi possivel editar TIF",
        description:
          "Por favor tente novamente. Caso erro persistir entre em contato com o suporte.",
      });
    }
  };

  const isSignaturesFilled = () => {
    if (motivoNaoAssinaturaFiscalizado[0] === ASSINATURA_FISICA) {
      return true;
    }

    if (
      fiscalizadoSignature?.assinatura?.toData().length === 0 &&
      motivoNaoAssinaturaFiscalizado.length === 0
    )
      return false;

    const isSignatureEmpty = servidores.some(
      (serv) => serv.assinatura.length === 0
    );

    if (isSignatureEmpty) {
      return false;
    }

    return true;
  };

  const formatAnexos = (anexos: any[]) => {
    const auxAnexos = anexos.map((anexo) => {
      return {
        uid: anexo.uid,
        name: anexo.nomeArquivo,
        status: "done",
        url: anexo.arquivo,
      };
    });

    return auxAnexos;
  };

  const handleResetForm = () => {
    fiscalizadoSignature?.assinatura?.clear();

    formRegistro.resetFields();
    formAtividades.resetFields();
    formServidores.resetFields();
    infoForm.resetFields();

    setAnexos([]);

    removerTodasAtividades();
    removerTodosServidoresSelecionados();
  };

  const modalConfirm = () => {
    confirm({
      title: "Tem certeza que deseja cancelar todas as alterações?",
      okText: "Sim",
      cancelText: "Não",
      okType: "danger",
      onOk: () => {
        // handleResetForm();
        navigate("/TIF/listagem");
      },
      icon: <ExclamationCircleOutlined />,
    });
  };

  useEffect(() => {
    if (!tifSelected) return;

    document.title = "Edição TIF | SISDEV-mobile";

    //ESTRUTURANDO NOVAMENTE O OBJETO DE ATIVIDADES
    const atividades = tifSelected.atividades.map((atv) => {
      const auxText: string[] = [];

      if (atv.coleta) {
        auxText.push("Coleta");
      }

      if (atv.denuncia) {
        auxText.push("Atendimento à denuncia");
      }

      if (atv.desinterdicao) {
        auxText.push("Desinterdição");
      }

      if (atv.interdicao) {
        auxText.push("Interdição");
      }

      if (atv.notificacao) {
        auxText.push("Notificação");
      }

      return {
        key: atv.id,
        programa: {
          id: atv.areaAtividade.id,
          label: atv.areaAtividade.nome,
          value: atv.areaAtividade.nome,
        },
        atividade: {
          id: atv.atividade.id,
          nome: atv.atividade.nome,
          questoes: atv.atividade.questoes,
        },
        prazo: atv.prazoAdequacao,
        especificacoes: atv.armadilha
          ? atv.armadilha.codigo
          : auxText.join(","),
        armadilha: atv.armadilha,
      };
    });

    removerTodasAtividades();
    removerTodosServidoresSelecionados();

    atividades.forEach((atividade) => {
      adicionarAtividade(atividade);
    });

    adicionarRespostaAtividade(tifSelected.respostasAtividades);

    tifSelected.servidores.forEach((servidor) => {
      selecionarServidor(servidor);
    });

    setAnexos(formatAnexos(tifSelected.anexosTIF));
    setMunicipioFiscalizacaoId(tifSelected.municipioFiscalizacao?.id);

    formRegistro.setFieldsValue({
      termo: termoInEdit(tifSelected),
      atuado: tifSelected.caracteristicaFiscalizado,
      tipoRepresentante: tifSelected.tipoRepresentante,
      fiscalizacaoFronteira: tifSelected.fiscalizacaoFronteira ? "SIM" : "NAO",
      cpfCnpjFiscalizado: cpfCnpjMask(tifSelected.cpfCnpjFiscalizado),
      nomeFiscalizado: tifSelected.nomeFiscalizado,
      inscricaoEstadualFiscalizado: tifSelected.inscricaoEstadualFiscalizado,
      renasemFiscalizado: tifSelected.renasemFiscalizado,
      enderecoFiscalizado: tifSelected.enderecoFiscalizado,
      ufFiscalizado: tifSelected.municipioFiscalizado?.uf.id,
      municipioFiscalizadoId: tifSelected.municipioFiscalizado?.id,
      emailFiscalizado: tifSelected.emailFiscalizado,
      telefoneFiscalizado: telefoneMask(tifSelected.telefoneFiscalizado || ""),
      cepFiscalizado: cepMask(tifSelected.cepFiscalizado),
      municipioFiscalizacaoId: tifSelected.municipioFiscalizacao
        ? tifSelected.municipioFiscalizacao.id
        : undefined,
      cepPropriedade: cepMask(tifSelected.cepPropriedade || ""),
      enderecoPropriedade: tifSelected.enderecoPropriedade,
      latGrauPropriedade: coordMask(
        tifSelected.latGrauPropriedade.toString() || ""
      ),
      latMinPropriedade: coordMask(
        tifSelected.latMinPropriedade.toString() || ""
      ),
      latSegPropriedade: coordMask(
        tifSelected.latSegPropriedade.toString() || ""
      ),
      longGrauPropriedade: coordMask(
        tifSelected.longGrauPropriedade.toString() || ""
      ),
      longMinPropriedade: coordMask(
        tifSelected.longMinPropriedade.toString() || ""
      ),
      longSegPropriedade: coordMask(
        tifSelected.longSegPropriedade.toString() || ""
      ),
      dataFiscalizar: moment(tifSelected.dataFiscalizacao),
    });

    // eslint-disable-next-line
  }, []);

  if (!tifSelected) {
    return (
      <>
        <Result
          status="error"
          title="Erro ao buscar TIF"
          subTitle="Por favor tente novamente, se o erro persistir entre em contato com o suporte."
        />
      </>
    );
  }

  return (
    <Fragment>
      {contextHolderModal}
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Termo de Inspeção, Fiscalização e Notificação (Edição)
      </Typography.Title>

      <Form
        form={formRegistro}
        id="form-register"
        labelWrap
        labelAlign="left"
        labelCol={{ flex: "150px", span: 20 }}
        wrapperCol={{ flex: 1, span: 14 }}
        onFinish={handleSubmit}
        onFinishFailed={messageSubmitFailed}
        onReset={modalConfirm}
      >
        <RegistroTIF
          form={formRegistro}
          fiscalizadoSignature={fiscalizadoSignature}
          setFiscalizadoSignature={setFiscalizadoSignature}
          tifEdit={tifSelected}
          setEstabelecimentoId={setEstabelecimentoId}
          estabelecimentoId={estabelecimentoId}
          setMunicipioFiscalizacaoId={setMunicipioFiscalizacaoId}
          pessoaAutorizadaId={pessoaAutorizadaId}
          setPessoaAutorizadaId={setPessoaAutorizadaId}
        />

        <UploadFilesForm setAnexos={setAnexos} anexos={anexos} />
      </Form>
      <Typography.Title level={3}>Atividades</Typography.Title>
      <AtividadesTIF
        atividadeForm={formAtividades}
        municipioFiscalizacaoId={municipioFiscalizacaoId}
      />

      <Form
        form={infoForm}
        initialValues={{
          constatacao: tifSelected.constatacao,
          providencias: tifSelected.amparoLegal,
        }}
        labelWrap
        labelAlign="left"
        labelCol={{ flex: "150px", span: 20 }}
        wrapperCol={{ flex: 1, span: 14 }}
        style={{ marginTop: 40 }}
      >
        <Form.Item label="Constatação" name="constatacao">
          <Input.TextArea rows={4} maxLength={10000} showCount />
        </Form.Item>

        <Form.Item label="Providências" name="providencias">
          <Input.TextArea rows={4} maxLength={10000} showCount />
        </Form.Item>
      </Form>

      <Divider />
      <TabelaServidoresForm form={formServidores} />

      <AssinaturasTIF
        fiscalizado={fiscalizadoSignature}
        setFiscalizado={setFiscalizadoSignature}
        servidores={servidores}
        setServidores={setServidores}
        setMotivoNaoAssinatura={setMotivoNaoAssinaturaFiscalizado}
        motivoNaoAssinatura={motivoNaoAssinaturaFiscalizado}
        form={formRegistro}
        ativarModalInformarNumTif={() => {}}
      />

      <ButtonFooterContainer>
        <Button form="form-register" htmlType="reset" disabled={isSavingTif}>
          Cancelar
        </Button>
        <Button
          type="primary"
          form="form-register"
          htmlType="submit"
          disabled={isSavingTif}
        >
          Salvar
        </Button>
      </ButtonFooterContainer>
    </Fragment>
  );
}
