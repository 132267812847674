import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { IPerguntaImpressaoTIF } from "@interfaces/IPerguntaImpressaoTIF";
import moment from "moment";

export function Descritiva({
  pergunta,
  atv,
}: {
  pergunta: IPerguntaAtividade;
  atv: IPerguntaImpressaoTIF;
}) {
  const resposta = atv.respostas.find(
    (resp) => resp.pergunta.id === pergunta.pergunta.id
  );

  return (
    <div
      style={{
        borderBottom: " 1px solid rgba(0,0,0,0.5)",
        fontSize: 12,
      }}
    >
      Especificar:{" "}
      {pergunta.pergunta.formatoResposta === "DATE"
        ? moment(resposta?.descricao).format("DD/MM/YYYY")
        : resposta?.descricao}
    </div>
  );
}
