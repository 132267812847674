import { Typography } from "antd";
import { Fragment, useEffect } from "react";
import { iconsLogo } from "../../icons";
import styles from "./style.module.css";

export function Home() {
  useEffect(() => {
    document.title = "Início | SISDEV-mobile";
  }, []);

  return (
    <Fragment>
      <div className={styles.container}>
        <Typography.Title level={2}>Inicio</Typography.Title>
        <img
          src={iconsLogo[4].image}
          alt={iconsLogo[4].name}
          className={styles["img-logo"]}
        />
      </div>
    </Fragment>
  );
}
