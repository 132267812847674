import { PlusOutlined } from "@ant-design/icons";
import { Form, Upload, UploadFile } from "antd";
import { RcFile } from "antd/lib/upload";
import { Fragment } from "react";
import Resizer from "react-image-file-resizer";
import { openNotification } from "../Notifications";

interface IUploadFileProps {
  anexos: any[];
  setAnexos: React.Dispatch<React.SetStateAction<any[]>>;
}

export function UploadFilesForm({ anexos, setAnexos }: IUploadFileProps) {
  const dummyRequest = async ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const resizeImage = (
    file: RcFile
  ): Promise<{ blob: Blob; base64: string }> => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800, // Largura máxima
        600, // Altura máxima
        "JPEG", // Formato
        60, // Qualidade
        0, // Rotação
        (uri) => {
          fetch(uri as string)
            .then((res) => res.blob())
            .then((blob) => resolve({ blob, base64: uri as string }));
        },
        "base64"
      );
    });
  };

  const isCapacityEnable = (lista: any[]) => {
    const tenMb = 10 * 1048576;
    const tamanhoArquivos = lista.reduce((acc, info) => {
      if (info instanceof File) {
        return acc + info.size;
      }

      return acc + info.originFileObj.size;
    }, 0);

    return tamanhoArquivos <= tenMb;
  };

  const handleBeforeUpload = async (file: RcFile) => {
    if (file.type?.includes("image")) {
      try {
        const { blob, base64 } = await resizeImage(file);
        const optimizedFile = new File([blob], file.name, {
          type: file.type,
        });

        const lista = [
          ...anexos,
          {
            uid: file.uid,
            name: optimizedFile.name,
            status: "done",
            url: base64, // URL para preview
            originFileObj: optimizedFile, // Arquivo otimizado
          },
        ];

        if (!isCapacityEnable(lista)) {
          openNotification({
            type: "info",
            message: "Capacidade para anexos ultrapassada",
            description: "Capacidade para anexos é de 10MB.",
          });
          return;
        }

        setAnexos(lista);
      } catch (error) {
        const lista = [...anexos, file];

        if (!isCapacityEnable(lista)) {
          openNotification({
            type: "info",
            message: "Capacidade para anexos ultrapassada",
            description: "Capacidade para anexos é de 10MB.",
          });
          return;
        }

        setAnexos(lista);
      }

      return Upload.LIST_IGNORE; // Evita que o Ant Design adicione o arquivo original
    }

    const lista = [...anexos, file];

    if (!isCapacityEnable(lista)) {
      openNotification({
        type: "info",
        message: "Capacidade para anexos ultrapassada",
        description: "Capacidade para anexos é de 10MB.",
      });
      return;
    }

    setAnexos(lista);

    return Upload.LIST_IGNORE;
  };

  const onRemove = (file: UploadFile<any>) => {
    setAnexos((anexos) => anexos.filter((item) => item.uid !== file.uid));
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <Fragment>
      <Form.Item label="Anexo" valuePropName="anexo">
        <Upload
          fileList={anexos}
          customRequest={dummyRequest}
          onPreview={onPreview}
          beforeUpload={handleBeforeUpload}
          onRemove={onRemove}
          listType="picture-card"
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
      </Form.Item>
    </Fragment>
  );
}
